export const DEFAULT_STATE = {
  registerData: {},
  loginData: {},
  forgotPasswordData: {},
  resetPasswordData: {},
  manageStaffAccessData: {},
  verifyEmailData: {},
  logoutData: {},
  emailVerificationData: {},
  signatureData: {},
  linkexpireData: {},
  verifyUserData: {},
  otpVerificationData:{},
  otpResendData:{}
};
