import { ADD_NEW_FAQ, NEW_FAQ_LIST, DELETE_FAQ, TAB_CLOSE_LOGOUT } from "../routing/route";

import { request } from "../request/axios.request";

export async function addNewFaqApi(data) {
  let addNewFaqData = data.data;
  return request({ url: ADD_NEW_FAQ, method: "post", data: addNewFaqData });
}

export async function newFaqListApi() {
  return request({ url: NEW_FAQ_LIST, method: "get" });
}

export async function deleteFaqApi(data) {
  let deleteFaqData = data.data;
  return request({ url: DELETE_FAQ, params: deleteFaqData, method: "delete" });
}

export async function tabCloseLogoutApi(data) {
  let userLogout = data.data;
  return request({ url: TAB_CLOSE_LOGOUT, method: "post", data: userLogout });
}