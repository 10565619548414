// Registration
export const REGISTRATION = "REGISTRATION";
export const registration = (data) => ({ type: REGISTRATION, data });
export const SUCCESS_REGISTRATION = "SUCCESS_REGISTRATION";
export const ERROR_REGISTRATION = "ERROR_REGISTRATION";
export const registrationResponse = (type, data) => ({ type, data });

//COPY SHORTCODE
export const COPY_SHORTCODE = "COPY_SHORTCODE";
export const copyShortCode = (data) => ({ type: COPY_SHORTCODE, data });
export const SUCCESS_COPY_SHORTCODE = "SUCCESS_COPY_SHORTCODE";
export const ERROR_COPY_SHORTCODE = "ERROR_COPY_SHORTCODE";
export const copyShortCodeResponse = (type, data) => ({ type, data });

//Add New Template
export const ADD_NEW_TEMPLATE = "ADD_NEW_TEMPLATE";
export const addNewTemplate = (data) => ({ type: ADD_NEW_TEMPLATE, data });
export const SUCCESS_ADD_NEW_TEMPLATE = "SUCCESS_ADD_NEW_TEMPLATE";
export const ERROR_ADD_NEW_TEMPLATE = "ERROR_ADD_NEW_TEMPLATE";
export const addNewTemplateResponse = (type, data) => ({ type, data });

//Email Template list
export const TEMPLATE_LIST = "TEMPLATE_LIST";
export const templateList = (data) => ({ type: TEMPLATE_LIST, data });
export const SUCCESS_TEMPLATE_LIST = "SUCCESS_TEMPLATE_LIST";
export const ERROR_TEMPLATE_LIST = "ERROR_TEMPLATE_LIST";
export const templateListResponse = (type, data) => ({ type, data });

//Edit Email Template
export const EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
export const editEmailTemplate = (data) => ({
  type: EDIT_EMAIL_TEMPLATE,
  data,
});
export const SUCCESS_EDIT_EMAIL_TEMPLATE = "SUCCESS_EDIT_EMAIL_TEMPLATE";
export const ERROR_EDIT_EMAIL_TEMPLATE = "ERROR_EDIT_EMAIL_TEMPLATE";
export const editEmailTemplateResponse = (type, data) => ({ type, data });

//Delete Email Template
export const DELETE_EMAIL_TEMPLATE = "DELETE_EMAIL_TEMPLATE";
export const deleteEmailTemplate = (data) => ({
  type: DELETE_EMAIL_TEMPLATE,
  data,
});
export const SUCCESS_DELETE_EMAIL_TEMPLATE = "SUCCESS_DELETE_EMAIL_TEMPLATE";
export const ERROR_DELETE_EMAIL_TEMPLATE = "ERROR_DELETE_EMAIL_TEMPLATE";
export const deleteEmailTemplateResponse = (type, data) => ({ type, data });

//Send Email
export const SEND_EMAIL = "SEND_EMAIL";
export const sendEmail = (data) => ({ type: SEND_EMAIL, data });
export const SUCCESS_SEND_EMAIL = "SUCCESS_SEND_EMAIL";
export const ERROR_SEND_EMAIL = "ERROR_SEND_EMAIL";
export const sendEmailResponse = (type, data) => ({ type, data });

// Select file
export const SEND_SELECTED_FILE = "SEND_SELECTED_FILE ";
export const sendSelectedFile = (data) => ({ type: SEND_SELECTED_FILE, data });
export const SUCCESS_FILE_SEND = "SUCCESS_FILE_SEND";
export const ERROR_FILE_SEND = "ERROR_FILE_SEND ";
export const sendSelectedFileResponse = (type, data) => ({ type, data });

//Check template name
export const CHECK_TEMPLATE_NAME = "CHECK_TEMPLATE_NAME";
export const checkTemplateName = (data) => ({
  type: CHECK_TEMPLATE_NAME,
  data,
});
export const SUCCESS_CHECK_TEMPLATE_NAME = "SUCCESS_CHECK_TEMPLATE_NAME";
export const ERROR_CHECK_TEMPLATE_NAME = "ERROR_CHECK_TEMPLATE_NAME";
export const checkTemplateNameResponse = (type, data) => ({ type, data });

//my emails list
export const MY_EMAILS_LIST = "MY_EMAILS_LIST";
export const myEmailsList = (data) => ({ type: MY_EMAILS_LIST, data });
export const SUCCESS_MY_EMAILS_LIST = "SUCCESS_MY_EMAILS_LIST";
export const ERROR_MY_EMAILS_LIST = "ERROR_MY_EMAILS_LIST";
export const myEmailsListResponse = (type, data) => ({ type, data });
//my support emails list
export const MY_SUPPORT_EMAILS_LIST = "MY_SUPPORT_EMAILS_LIST";
export const mySupportEmailsList = (data) => ({ type: MY_SUPPORT_EMAILS_LIST, data });
export const SUCCESS_MY_SUPPORT_EMAILS_LIST = "SUCCESS_MY_SUPPORT_EMAILS_LIST";
export const ERROR_MY_SUPPORT_EMAILS_LIST = "ERROR_MY_SUPPORT_EMAILS_LIST";
export const mySupportEmailsListResponse = (type, data) => ({ type, data });
//email stats
export const EMAIL_STATS = "EMAIL_STATS";
export const emailStats = () => ({ type: EMAIL_STATS });
export const SUCCESS_EMAIL_STATS = "SUCCESS_EMAIL_STATS";
export const ERROR_EMAIL_STATS = "ERROR_EMAIL_STATS";
export const emailStatsResponse = (type, data) => ({ type, data });

//my Download emails list
export const MY_DOWNLOAD_EMAILS_LIST = "MY_DOWNLOAD_EMAILS_LIST";
export const myDownloadEmailsList = (data) => ({ type: MY_DOWNLOAD_EMAILS_LIST, data });
export const SUCCESS_MY_DOWNLOAD_EMAILS_LIST = "SUCCESS_MY_DOWNLOAD_EMAILS_LIST";
export const ERROR_MY_DOWNLOAD_EMAILS_LIST = "ERROR_MY_DOWNLOAD_EMAILS_LIST";
export const myDownloadEmailsListResponse = (type, data) => ({ type, data });

//my Unsubscribe emails list
export const MY_UNSUBSCRIBE_EMAILS_LIST = "MY_UNSUBSCRIBE_EMAILS_LIST";
export const myUnsubscribeEmailsList = (data) => ({ type: MY_UNSUBSCRIBE_EMAILS_LIST, data });
export const SUCCESS_MY_UNSUBSCRIBE_EMAILS_LIST = "SUCCESS_MY_UNSUBSCRIBE_EMAILS_LIST";
export const ERROR_MY_UNSUBSCRIBE_EMAILS_LIST = "ERROR_MY_UNSUBSCRIBE_EMAILS_LIST";
export const myUnsubscribeEmailsListResponse = (type, data) => ({ type, data });

// Delete Unsubscribe emails
export const DELETE_UNSUBSCRIBE_EMAILS = "DELETE_UNSUBSCRIBE_EMAILS";
export const deleteUnsubscribeEmails = (data) => ({ type: DELETE_UNSUBSCRIBE_EMAILS, data });
export const SUCCESS_DELETE_UNSUBSCRIBE_EMAILS = "SUCCESS_DELETE_UNSUBSCRIBE_EMAILS";
export const ERROR_DELETE_UNSUBSCRIBE_EMAILS = "ERROR_DELETE_UNSUBSCRIBE_EMAILS";
export const deleteUnsubscribeEmailsResponse = (type, data) => ({ type, data });