//Change password
export const CHANGE_PASSWORD_SETTINGS = "CHANGE_PASSWORD_SETTINGS";
export const changePasswordSettings = (data) => ({
  type: CHANGE_PASSWORD_SETTINGS,
  data,
});
export const SUCCESS_CHANGE_PASSWORD_SETTINGS =
  "SUCCESS_CHANGE_PASSWORD_SETTINGS";
export const ERROR_CHANGE_PASSWORD_SETTINGS = "ERROR_CHANGE_PASSWORD_SETTINGS";
export const changePasswordSettingsResponse = (type, data) => ({ type, data });

//Notification List
export const NOTIFICATION_LIST = "NOTIFICATION_LIST";
export const notificationList = (data) => ({ type: NOTIFICATION_LIST, data });
export const SUCCESS_NOTIFICATION_LIST = "SUCCESS_NOTIFICATION_LIST";
export const ERROR_NOTIFICATION_LIST = "ERROR_NOTIFICATION_LIST";
export const notificationListResponse = (type, data) => ({ type, data });

//Change Notification status
export const CHANGED_NOTIFICATION_STATUS = "CHANGED_NOTIFICATION_STATUS";
export const changedNotificationStatus = (data) => ({
  type: CHANGED_NOTIFICATION_STATUS,
  data,
});
export const SUCCESS_CHANGED_NOTIFICATION_STATUS =
  "SUCCESS_CHANGED_NOTIFICATION_STATUS";
export const ERROR_CHANGED_NOTIFICATION_STATUS =
  "ERROR_CHANGED_NOTIFICATION_STATUS";
export const changedNotificationStatusResponse = (type, data) => ({
  type,
  data,
});

//destinations
export const DESTINATION_LIST = "DESTINATION_LIST";
export const destinationList = (data) => ({ type: DESTINATION_LIST, data });
export const SUCCESS_DESTINATION_LIST = "SUCCESS_DESTINATION_LIST";
export const ERROR_DESTINATION_LIST = "ERROR_DESTINATION_LIST";
export const destinationListResponse = (type, data) => ({ type, data });

//originsListData
export const ORIGINS_LIST = "ORIGINS_LIST";
export const originsList = (data) => ({ type: ORIGINS_LIST, data });
export const SUCCESS_ORIGINS_LIST = "SUCCESS_ORIGINS_LIST";
export const ERROR_ORIGINS_LIST = "ERROR_ORIGINS_LIST";
export const originsListResponse = (type, data) => ({ type, data });

//createNotificationAlert
export const CREATE_NOTIFICATION_ALERT = "CREATE_NOTIFICATION_ALERT";
export const createNotificationAlert = (data) => ({
  type: CREATE_NOTIFICATION_ALERT,
  data,
});
export const SUCCESS_CREATE_NOTIFICATION_ALERT =
  "SUCCESS_CREATE_NOTIFICATION_ALERT";
export const ERROR_CREATE_NOTIFICATION_ALERT =
  "ERROR_CREATE_NOTIFICATION_ALERT";
export const createNotificationAlertResponse = (type, data) => ({ type, data });

//savePreference
export const SAVE_PREFERENCE = "SAVE_PREFERENCE";
export const SavePreference = (data) => ({ type: SAVE_PREFERENCE, data });
export const SUCCESS_SAVE_PREFERENCE = "SUCCESS_SAVE_PREFERENCE";
export const ERROR_SAVE_PREFERENCE = "ERROR_SAVE_PREFERENCE";
export const SavePreferenceResponse = (type, data) => ({ type, data });

// delete notification
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const deleteNotification = (data) => ({
  type: DELETE_NOTIFICATION,
  data,
});
export const SUCCESS_DELETE_NOTIFICATION = "SUCCESS_DELETE_NOTIFICATION";
export const ERROR_DELETE_NOTIFICATION = "ERROR_DELETE_NOTIFICATION";
export const deleteNotificationResponse = (type, data) => ({ type, data });

//edit Notification
export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
export const editNotification = (data) => ({ type: EDIT_NOTIFICATION, data });
export const SUCCESS_EDIT_NOTIFICATION = "SUCCESS_EDIT_NOTIFICATION";
export const ERROR_EDIT_NOTIFICATION = "ERROR_EDIT_NOTIFICATION";
export const editNotificationResponse = (type, data) => ({ type, data });

//origin Destination get data
export const ORIGIN_DESTINATION_DATA = "ORIGIN_DESTINATION_DATA";
export const originDestination = (data) => ({
  type: ORIGIN_DESTINATION_DATA,
  data,
});
export const SUCCESS_ORIGIN_DESTINATION_DATA =
  "SUCCESS_ORIGIN_DESTINATION_DATA";
export const ERROR_ORIGIN_DESTINATION_DATA = "ERROR_ORIGIN_DESTINATION_DATA";
export const originDestinationDataResponse = (type, data) => ({ type, data });

//get sync-data
export const SYNC_NOTIFICATION_DATA = "SYNC_NOTIFICATION_DATA";
export const syncNotification = (data) => ({
  type: SYNC_NOTIFICATION_DATA,
  data,
});
export const SUCCESS_SYNC_NOTIFICATION_DATA = "SUCCESS_SYNC_NOTIFICATION_DATA";
export const ERROR_SYNC_NOTIFICATION_DATA = "ERROR_SYNC_NOTIFICATION_DATA";
export const syncNotificationDataResponse = (type, data) => ({ type, data });

//Add Signature
export const INSERT_SIGNATURE = "INSERT_SIGNATURE";
export const addSignature = (data) => ({ type: INSERT_SIGNATURE, data });
export const SUCCESS_INSERT_SIGNATURE = "SUCCESS_INSERT_SIGNATURE";
export const ERROR_INSERT_SIGNATURE = "ERROR_INSERT_SIGNATURE";
export const addSignatureResponse = (type, data) => ({ type, data });

//Get Signature
export const GET_SIGNATURE = "GET_SIGNATURE";
export const getSignature = (data) => ({ type: GET_SIGNATURE, data });
export const SUCCESS_GET_SIGNATURE = "SUCCESS_GET_SIGNATURE";
export const ERROR_GET_SIGNATURE = "ERROR_GET_SIGNATURE";
export const getSignatureResponse = (type, data) => ({ type, data });

//Change_Profile_Pic
export const Change_Profile_Pic = "Change_Profile_Pic";
export const changeProfilePic = (data) => ({ type: Change_Profile_Pic, data });
export const SUCCESS_Change_Profile_Pic = "SUCCESS_Change_Profile_Pic";
export const ERROR_Change_Profile_Pic = "ERROR_Change_Profile_Pic";
export const changeProfilePicResponse = (type, data) => ({ type, data });

// equipment type
export const SETTING_EQUIPMENT_TYPE_LIST = "SETTING_EQUIPMENT_TYPE_LIST"
export const settingEquipmentType = (data) => ({ type: SETTING_EQUIPMENT_TYPE_LIST, data })
export const SUCCESS_SETTING_EQUIPMENT_TYPE_LIST = "SUCCESS_SETTING_EQUIPMENT_TYPE_LIST"
export const ERROR_SETTING_EQUIPMENT_TYPE_LIST = "ERROR_SETTING_EQUIPMENT_TYPE_LIST"
export const settingEquipmentTypeResponse = (type, data) => ({ type, data })

// dblimit
export const SETTING_DB_LIMIT = "SETTING_DB_LIMIT"
export const dbLimit = (data) => ({ type: SETTING_DB_LIMIT, data })
export const SUCCESS_SETTING_DB_LIMIT = "SUCCESS_SETTING_DB_LIMIT"
export const ERROR_SETTING_DB_LIMIT = "ERROR_SETTING_DB_LIMIT"
export const settingDbLimitResponse = (type, data) => ({ type, data })

//EDIT Signature
export const EDIT_SIGNATURE = "EDIT_SIGNATURE";
export const editSignature = (data) => ({ type: EDIT_SIGNATURE, data });
export const SUCCESS_EDIT_SIGNATURE = "SUCCESS_EDIT_SIGNATURE";
export const ERROR_EDIT_SIGNATURE = "ERROR_EDIT_SIGNATURE";
export const editSignatureResponse = (type, data) => ({ type, data });

//Delete Signature
export const DELETE_SIGNATURE = "DELETE_SIGNATURE";
export const deleteSignature = (data) => ({ type: DELETE_SIGNATURE, data });
export const SUCCESS_DELETE_SIGNATURE = "SUCCESS_DELETE_SIGNATURE";
export const ERROR_DELETE_SIGNATURE = "ERROR_DELETE_SIGNATURE";
export const deleteSignatureResponse = (type, data) => ({ type, data });

//List Signature
export const LIST_SIGNATURE = "LIST_SIGNATURE";
export const listSignature = (data) => ({ type: LIST_SIGNATURE, data });
export const SUCCESS_LIST_SIGNATURE = "SUCCESS_LIST_SIGNATURE";
export const ERROR_LIST_SIGNATURE = "ERROR_LIST_SIGNATURE";
export const listSignatureResponse = (type, data) => ({ type, data });

//ALL_LIST Signature
export const ALL_LIST_SIGNATURE = "ALL_LIST_SIGNATURE";
export const allListSignature = (data) => ({ type: ALL_LIST_SIGNATURE, data });
export const SUCCESS_ALL_LIST_SIGNATURE = "SUCCESS_ALL_LIST_SIGNATURE";
export const ERROR_ALL_LIST_SIGNATURE = "ERROR_ALL_LIST_SIGNATURE";
export const allListSignatureResponse = (type, data) => ({ type, data });

//CHECK_SIGNATURE_NAME
export const CHECK_SIGNATURE_NAME = "CHECK_SIGNATURE_NAME";
export const checkSignatureName = (data) => ({
  type: CHECK_SIGNATURE_NAME,
  data,
});
export const SUCCESS_CHECK_SIGNATURE_NAME = "SUCCESS_CHECK_SIGNATURE_NAME";
export const ERROR_CHECK_SIGNATURE_NAME = "ERROR_CHECK_SIGNATURE_NAME";
export const checkSignatureNameResponse = (type, data) => ({ type, data });

//Setting settingRfpAlertUpdateApi
export const SETTING_RFP_ALERT_UPDATE = "SETTING_RFP_ALERT_UPDATE";
export const settingRfpAlertUpdate = (data) => ({
  type: SETTING_RFP_ALERT_UPDATE,
  data,
});
export const SUCCESS_SETTING_RFP_ALERT_UPDATE = "SUCCESS_SETTING_RFP_ALERT_UPDATE";
export const ERROR_SETTING_RFP_ALERT_UPDATE= "ERROR_SETTING_RFP_ALERT_UPDATE";
export const settingRfpAlertUpdateResponse = (type, data) => ({ type, data });

//CHECK_settingRfpStatuseApi
export const SETTING_RFP_ALERT_STATUS = "SETTING_RFP_ALERT_STATUS";
export const settingRfpAlertStatus = (data) => ({
  type: SETTING_RFP_ALERT_STATUS,
  data,
});
export const SUCCESS_SETTING_RFP_ALERT_STATUS = "SUCCESS_SETTING_RFP_ALERT_STATUS";
export const ERROR_SETTING_RFP_ALERT_STATUS = "ERROR_SETTING_RFP_ALERT_STATUS";
export const settingRfpAlertStatusResponse = (type, data) => ({ type, data });