import {
  newUserManageStaff,
  resetPasswordManageStaff,
  validateInputs,
  editUserManageStaff
} from "./index";
import { checkPassword } from "../../../common/commonPasswordValidation";

//Add New User Data using  Modal
export const HandleSubmit = (addNewUser, setAddNewUser, props, dispatch) => {
  let success = "";
  let error = addNewUser.wrongInput;
  let username = addNewUser.username,
    usernameCls = success,
    usernameErr = "",
    mobile = addNewUser.mobile,
    mobileCls = success,
    mobileErr = "",
    userRole = addNewUser.userRole,
    userRoleCls= success,
    userRoleErr = "",
    email = addNewUser.email,
    emailCls = success,
    emailErr = "",
    password = addNewUser.password,
    passwordCls = success,
    passwordErr = "",
    getError = false;
  if (validateInputs("required", userRole) === "empty") {
      userRoleErr = "Select User Role.";
      userRoleCls = error;
      getError = true;
  }
  if (validateInputs("required", username) === "empty") {
    usernameErr = "Enter username.";
    usernameCls = error;
    getError = true;
  } else if (validateInputs("spaces", username) === true) {
    usernameErr = "Enter username.";
    usernameCls = error;
    getError = true;
  } else if (validateInputs("consecsutivespace", username) === true) {
    usernameErr = "Not more than 2 space allowed.";
    usernameCls = error;
    getError = true;
  }
  if (validateInputs("symbolWithOneAlphanumeric", username) === false) {
    usernameErr = "Enter symbol with atleast one alphanumeric characters.";
    usernameCls = error;
    getError = true;
  }else if (username && username.length < 3) {
    usernameErr = "Enter atleast 3 characters.";
    usernameCls = error;
    getError = true;
  } else if (username && username.length > 20) {
    usernameErr = "Enter less than 20 character.";
    usernameCls = error;
    getError = true;
  }
  if (validateInputs("email", email) === "empty") {
    emailErr = "Enter email.";
    emailCls = error;
    getError = true;
  } else if (validateInputs("email", email) === false) {
    emailErr = "Enter valid email.";
    emailCls = error;
    getError = true;
  } else if (validateInputs("consecsutivespace", email) === true) {
    emailErr = "Not more than 2 space allowed.";
    emailCls = error;
    getError = true;
  }
  if (validateInputs("spaces", mobile) === true) {
    mobileErr = "Enter mobile.";
    mobileCls = error;
    getError = true;
  } else if (validateInputs("phoneNumberHyphon", mobile) === "empty") {
    mobileErr = "Enter mobile.";
    mobileCls = error;
    getError = true;
  } else if (validateInputs("phoneNumberHyphon", mobile) === false) {
    mobileErr = "Enter valid mobile.";
    mobileCls = error;
    getError = true;
  } else if (
    (mobile && mobile.length > 1 && mobile.length > 15) ||
    mobile.length < 10
  ) {
    mobileErr = "Enter atleast 10 and atmost 15 digits.";
    mobileCls = error;
    getError = true;
  } else if (validateInputs("consecsutivespace", mobile) === true) {
    mobileErr = "Not more than 2 space allowed.";
    mobileCls = error;
    getError = true;
  }
  if (validateInputs("spaces", password) === true) {
    passwordErr = "Enter password.";
    passwordCls = error;
    getError = true;
  } else if (validateInputs("password", password) === "empty") {
    passwordErr = "Enter password.";
    passwordCls = error;
    getError = true;
  } else if (validateInputs("password", password) === false) {
    let passwordValidation = checkPassword(password, error);
    passwordErr = passwordValidation.errormsg;
    passwordCls = passwordValidation.errorCls;
    getError = passwordValidation.isError;
  } else if (validateInputs("consecsutivespace", password) === true) {
    passwordErr = "Not more than 2 space allowed.";
    passwordCls = error;
    getError = true;
  }
  setAddNewUser({
    ...addNewUser,
    usernameErr,
    mobileCls,
    mobileErr,
    usernameCls,
    emailCls,
    emailErr,
    passwordCls,
    passwordErr,
    userRoleCls,
    userRoleErr,
  });

  if (
    getError === false &&
    usernameErr === "" &&
    mobileErr === "" &&
    emailErr === "" &&
    passwordErr === ""&&
    userRoleErr===""
  ) {
    const manageStaffAccessData = {
      mobile: addNewUser.mobile,
      username: addNewUser.username,
      email: addNewUser.email,
      password: addNewUser.password,
      role: addNewUser.userRole,
    };
    props.loader(true);
    dispatch(newUserManageStaff(manageStaffAccessData));
  }
  // dispatch(manageStaffAccess(manageStaffAccessData));
};

export const handleResetPasswordSubmit = (
  resetPassword,
  setResetPassword,
  props,
  dispatch
) => {
  let success = "";
  let error = resetPassword.wrongInput;
  let password = resetPassword.password,
    passwordCls = success,
    passwordErr = "",
    confirmPassword = resetPassword.confirmPassword,
    confirmPasswordCls = success,
    confirmPasswordErr = "",
    getError = false;

  if (validateInputs("password", password) === "empty") {
    passwordErr = "Enter password.";
    passwordCls = error;
    getError = true;
  } else if (validateInputs("password", password) === false) {
    let commonValidationforPassword = checkPassword(password, error);
    passwordErr = commonValidationforPassword.errormsg;
    passwordCls = commonValidationforPassword.errorCls;
    getError = commonValidationforPassword.isError;
  } else if (validateInputs("consecsutivespace", password) === true) {
    passwordErr = "Not more than 2 space allowed.";
    passwordCls = error;
    getError = true;
  }
  if (validateInputs("password", confirmPassword) === "empty") {
    confirmPasswordErr = "Enter confirm password.";
    confirmPasswordCls = error;
    getError = true;
  } else if (validateInputs("password", confirmPassword) === false) {
    let commonValidationforPassword = checkPassword(confirmPassword, error);
    confirmPasswordErr = commonValidationforPassword.errormsg;
    confirmPasswordCls = commonValidationforPassword.errorCls;
    getError = commonValidationforPassword.isError;
  } else if (password !== confirmPassword) {
    confirmPasswordErr = "Password and confirm password does not match.";
    confirmPasswordCls = error;
    getError = true;
  } else if (validateInputs("consecsutivespace", confirmPassword) === true) {
    confirmPasswordErr = "Not more than 2 space allowed.";
    confirmPasswordCls = error;
    getError = true;
  }

  setResetPassword({
    ...resetPassword,
    passwordCls,
    passwordErr,
    confirmPasswordErr,
    confirmPasswordCls,
  });
  if (getError === false && passwordErr === "" && confirmPasswordErr === "") {
    const id_data = props.idResetPassword;
    const resetPasswordData = {
      user_id: id_data,
      password: resetPassword.password,
      password_confirmation: resetPassword.confirmPassword,
    };
    props.loader(true);
    dispatch(resetPasswordManageStaff(resetPasswordData));
  }
};

//Add New User Data using  Modal
export const HandleUpdateSubmit = (editNewUser, setEditNewUser, props, dispatch,propsUserData) => {
  let success = "";
  let error = editNewUser.wrongInput;
  let username = editNewUser.username,
    usernameCls = success,
    usernameErr = "",
    mobile = editNewUser.mobile,
    mobileCls = success,
    mobileErr = "",
    userRole = editNewUser.userRole,
    userRoleCls= success,
    userRoleErr = "",
    getError = false;
  if (validateInputs("required", userRole) === "empty") {
    userRoleErr = "Enter user role.";
    userRoleCls = error;
    getError = true;
  }
  if (validateInputs("required", username) === "empty") {
    usernameErr = "Enter username.";
    usernameCls = error;
    getError = true;
  } else if (validateInputs("spaces", username) === true) {
    usernameErr = "Enter username.";
    usernameCls = error;
    getError = true;
  } else if (validateInputs("consecsutivespace", username) === true) {
    usernameErr = "Not more than 2 space allowed.";
    usernameCls = error;
    getError = true;
  }
  if (validateInputs("symbolWithOneAlphanumeric", username) === false) {
    usernameErr = "Enter symbol with atleast one alphanumeric characters.";
    usernameCls = error;
    getError = true;
  }else if (username && username.length < 3) {
    usernameErr = "Enter atleast 3 characters.";
    usernameCls = error;
    getError = true;
  } else if (username && username.length > 20) {
    usernameErr = "Enter less than 20 character.";
    usernameCls = error;
    getError = true;
  }
  if (validateInputs("required", mobile) === "empty") {
    mobileErr = "Enter mobile.";
    mobileCls = error;
    getError = true; 
  }else if (validateInputs("spaces", mobile) === true) {
    console.log("me");
    mobileErr = "Enter mobile.";
    mobileCls = error;
    getError = true;
  } else if (validateInputs("phoneNumberHyphon", mobile) === "empty") {
    console.log("me");
    mobileErr = "Enter mobile.";
    mobileCls = error;
    getError = true;
  } else if (validateInputs("phoneNumberHyphon", mobile) === false) {
    mobileErr = "Enter valid mobile.";
    mobileCls = error;
    getError = true;
  } else if (
    (mobile && mobile.length > 1 && mobile.length > 15) ||
    mobile.length < 10
  ) {
    mobileErr = "Enter atleast 10 and atmost 15 digits.";
    mobileCls = error;
    getError = true;
  } else if (validateInputs("consecsutivespace", mobile) === true) {
    mobileErr = "Not more than 2 space allowed.";
    mobileCls = error;
    getError = true;
  }
  setEditNewUser({
    ...editNewUser,
    usernameErr,
    mobileCls,
    mobileErr,
    usernameCls,
    userRoleCls,
    userRoleErr,
  });
 

  if (
    getError === false &&
    usernameErr === "" &&
    mobileErr === "" &&
    userRoleErr===""
  ) {
    const manageStaffAccessData = {
      id: propsUserData.id,
      username: editNewUser.username,
      mobile: editNewUser.mobile,
      role: editNewUser.userRole,
    };
    props.loader(true);
    dispatch(editUserManageStaff(manageStaffAccessData));
  }
};
