import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import {
  DASHBOARD_CARRIER_GRAPH,
  SUCCESS_DASHBOARD_CARRIER_GRAPH,
  ERROR_DASHBOARD_CARRIER_GRAPH,
  dashboardCarrierGraphResponse,
  CARRIER_RATE_GRAPH,
  SUCCESS_CARRIER_RATE_GRAPH,
  ERROR_CARRIER_RATE_GRAPH,
  carrierRateGraphResponse,
  EQUIPMENT_TYPE_GRAPH,
  SUCCESS_EQUIPMENT_TYPE_GRAPH,
  ERROR_EQUIPMENT_TYPE_GRAPH,
  equipmentTypeGraphResponse,
  CARRIER_COUNT_GRAPH,
  SUCCESS_CARRIER_COUNT_GRAPH,
  ERROR_CARRIER_COUNT_GRAPH,
  carrierCountGraphResponse,
} from "./graphs.action";
import {
  dashboardCarrierGraphApi,
  carrierRateGraphApi,
  equipmentTypeGraphApi,
  carrierCountGraphApi,
} from "../../../api/sdk/dashboardGraphs";

function* dashboardCarrierGraphRequest(data) {
  let getData = yield dashboardCarrierGraphApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      dashboardCarrierGraphResponse(
        SUCCESS_DASHBOARD_CARRIER_GRAPH,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      dashboardCarrierGraphResponse(ERROR_DASHBOARD_CARRIER_GRAPH, getData.data)
    );
    //message.error(getData.data.message)
  }
}
export function* dashboardCarrierGraphWatcher() {
  yield takeLatest(DASHBOARD_CARRIER_GRAPH, dashboardCarrierGraphRequest);
}

function* carrierRateGraphRequest(data) {
  let getData = yield carrierRateGraphApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      carrierRateGraphResponse(SUCCESS_CARRIER_RATE_GRAPH, getData.data)
    );
    // message.success(getData.data.message)
  } else {
    yield put(carrierRateGraphResponse(ERROR_CARRIER_RATE_GRAPH, getData.data));
    //message.error(getData.data.message)
  }
}
export function* carrierRateGraphWatcher() {
  yield takeLatest(CARRIER_RATE_GRAPH, carrierRateGraphRequest);
}
function* equipmentTypeGraphRequest(data) {
  let getData = yield equipmentTypeGraphApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      equipmentTypeGraphResponse(SUCCESS_EQUIPMENT_TYPE_GRAPH, getData.data)
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      equipmentTypeGraphResponse(ERROR_EQUIPMENT_TYPE_GRAPH, getData.data)
    );
    //message.error(getData.data.message)
  }
}
export function* equipmentTypeGraphWatcher() {
  yield takeLatest(EQUIPMENT_TYPE_GRAPH, equipmentTypeGraphRequest);
}
function* carrierCountGraphRequest(data) {
  let getData = yield carrierCountGraphApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      carrierCountGraphResponse(SUCCESS_CARRIER_COUNT_GRAPH, getData.data)
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      carrierCountGraphResponse(ERROR_CARRIER_COUNT_GRAPH, getData.data)
    );
    //message.error(getData.data.message)
  }
}
export function* carrierCountGraphWatcher() {
  yield takeLatest(CARRIER_COUNT_GRAPH, carrierCountGraphRequest);
}
