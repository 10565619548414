// Custom Notification List
export const CUSTOM_NOTIFICATION_LIST = "CUSTOM_NOTIFICATION_LIST";
export const customNotificationList = (data) => ({
  type: CUSTOM_NOTIFICATION_LIST,
  data,
});
export const SUCCESS_CUSTOM_NOTIFICATION_LIST =
  "SUCCESS_CUSTOM_NOTIFICATION_LIST";
export const ERROR_CUSTOM_NOTIFICATION_LIST = "ERROR_CUSTOM_NOTIFICATION_LIST";
export const customNotificationListResponse = (type, data) => ({ type, data });

// // Latest Notification List
// export const LATEST_NOTIFICATION_LIST = 'LATEST_NOTIFICATION_LIST';
// export const latestNotificationList = (data) => ({ type: LATEST_NOTIFICATION_LIST, data });
// export const SUCCESS_LATEST_NOTIFICATION_LIST = 'SUCCESS_LATEST_NOTIFICATION_LIST';
// export const ERROR_LATEST_NOTIFICATION_LIST = 'ERROR_LATEST_NOTIFICATION_LIST';
// export const latestNotificationListResponse = (type, data) => ({ type, data });
