//Add New FAQ
export const ADD_NEW_FAQ = "ADD_NEW_FAQ";
export const addNewFaqs = (data) => ({ type: ADD_NEW_FAQ, data });
export const SUCCESS_ADD_NEW_FAQ = "SUCCESS_ADD_NEW_FAQ";
export const ERROR_ADD_NEW_FAQ = "ERROR_ADD_NEW_FAQ";
export const addNewFaqResponse = (type, data) => ({ type, data });

// FAQ List
export const NEW_FAQ_LIST = "NEW_FAQ_LIST";
export const newFaqsList = () => ({ type: NEW_FAQ_LIST });
export const SUCCESS_NEW_FAQ_LIST = "SUCCESS_NEW_FAQ_LIST";
export const ERROR_NEW_FAQ_LIST = "ERROR_NEW_FAQ_LIST";
export const newFaqListResponse = (type, data) => ({ type, data });

// DELETE FAQ
export const DELETE_FAQ = "DELETE_FAQ";
export const deleteFaq = (data) => ({ type: DELETE_FAQ, data });
export const SUCCESS_DELETE_FAQ = "SUCCESS_DELETE_FAQ";
export const ERROR_DELETE_FAQ = "ERROR_DELETE_FAQ";
export const deleteFaqResponse = (type, data) => ({ type, data });

// LOGOUT ON TAB CLOSE 
export const LOGOUT_TAB_CLOSE = "LOGOUT_TAB_CLOSE";
export const logoutTabclose = (data) => ({ type: LOGOUT_TAB_CLOSE, data });
export const SUCCESS_LOGOUT_TAB_CLOSE = "SUCCESS_LOGOUT_TAB_CLOSE";
export const ERROR_LOGOUT_TAB_CLOSE = "ERROR_LOGOUT_TAB_CLOSE";
export const logoutTabcloseResponse = (type, data) => ({ type, data })