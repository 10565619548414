import {
  deleteUser,
  listManageStaff,
  sendUserCredentials,
  STAFFPERMISSION,
  StaffUpdateDetails,
  staffUpdateRfpAlert,
  Swal,
  staffRestore
} from "../index";
export const sortTheData = (
  field,
  sortColumn,
  setSortColumn,
  setPage,
  page,
  setSortingField,
  setSortingOrder,
  setLoader,
  dispatch,
  search
) => {
  let sort = "";
  let sortOrder = "";
  if (field === "userName") {
    sort = "userName";
    sortOrder = !sortColumn.userName ? "ASC" : "DESC";
    setSortColumn({
      ...sortColumn,
      userName: !sortColumn.userName,
      mobile: true,
      email: true,
      status: true,
    });
  }
  if (field === "mobile") {
    sort = "mobile";
    sortOrder = !sortColumn.mobile ? "ASC" : "DESC";
    setSortColumn({
      ...sortColumn,
      mobile: !sortColumn.mobile,
      email: true,
      status: true,
    });
  }
  if (field === "email") {
    sort = "email";
    sortOrder = !sortColumn.email ? "ASC" : "DESC";
    setSortColumn({
      ...sortColumn,
      email: !sortColumn.email,
      mobile: true,
      status: true,
    });
  }
  if (field === "status") {
    sort = "status";
    sortOrder = !sortColumn.status ? "ASC" : "DESC";
    setSortColumn({
      ...sortColumn,
      status: !sortColumn.status,
      mobile: true,
      email: true,
    });
  }
  setPage(page);
  setSortingField(sort);
  setSortingOrder(sortOrder);
  setLoader(true);
  dispatch(
    listManageStaff({ page, order: sortOrder, column: field, search: search })
  );
};

//Acive Inactive functionality
export const setActiveInactive = (e, id, setLoader, dispatch) => {
  const statusValue = e.target.checked ? "1" : "0";

  let statusdata = {
    user_id: id,
    value: statusValue,
    reference: "status",
  };
  setLoader(true);
  dispatch(StaffUpdateDetails(statusdata));
};

//Update Role functionality
export const setUpdateRole = (e, id, setLoader, dispatch) => {
  const statusValue = e.key;
  let updateRoledata = {
    user_id: id,
    value: statusValue,
    reference: "user_role_id",
  };
  setLoader(true);
  dispatch(StaffUpdateDetails(updateRoledata));
};

//RFP Alert functionality
export const setStaffRfpAlert = (e, id, setLoader, dispatch) => {
  const statusValue = e.target.checked ? "1" : "0";
  let statusdata = {
    user_id: id,
    status: statusValue,
  };
  setLoader(true);
  dispatch(staffUpdateRfpAlert(statusdata));
};

export const handleSendUserCredentials = (e, id, setLoader, dispatch) => {
  const userCredentialsData = {
    user_id: id,
  };
  Swal.fire({
    title: "Send Credentials",
    text: "Users login credentials will be sent on user's registered email.",
    confirmButtonText: "OK",
    allowOutsideClick: false,
    allowEscapeKey: false,
    showCloseButton: true,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      setLoader(true);
      dispatch(sendUserCredentials(userCredentialsData));
    }
  });
};

export const showResetPasswordModal = (
  e,
  id,
  setIdResetPassword,
  setResetPasswordModalShow
) => {
  setIdResetPassword(id);
  setResetPasswordModalShow(true);
};

export const handleDeleteUser = (e, id, setLoader, dispatch) => {
  Swal.fire({
    title: "Permission Before Delete",
    text: "Are you sure about deleting this user?",
    confirmButtonText: "OK",
    allowOutsideClick: false,
    allowEscapeKey: false,
    showCloseButton: true,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      const deleteUserData = {
        user_id: id,
      };
      setLoader(true);
      dispatch(deleteUser(deleteUserData));
    }
  });
};

export const handleRestoreUser = (user_id,email, setLoader, dispatch, password, user_role) => {
  Swal.fire({
    title: "Permission Before Restore",
    text: " Are you sure? you want to restore the staff email: "+email,
    confirmButtonText: "OK",
    allowOutsideClick: false,
    allowEscapeKey: false,
    showCloseButton: true,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      const user = {
        user_id: user_id,
        password:password,
        user_role:user_role
      };
      setLoader(true);
      dispatch(staffRestore(user));
    }
  });
};
// set permission
export const handlesetpermissionbutton = (e, id, history) => {
  history.push(`${STAFFPERMISSION + id}`);
};
// User Get Data  By Pagination
export const getPageData = (
  page,
  setPage,
  setLoader,
  dispatch,
  sortingField,
  search,
  sortingOrder
) => {
  setPage(page);
  setLoader(true);
  dispatch(
    listManageStaff({
      page,
      column: sortingField,
      search: search,
      order: sortingOrder,
    })
  );
};

export const showEditProfileModal = (
  e,
  id,
  setIdEditProfile,
  setEditProfileModalShow
) => {
  setIdEditProfile(id);
  setEditProfileModalShow(true);
};
