import {
  SUCCESS_ADD_NEW_FAQ,
  ERROR_ADD_NEW_FAQ,
  SUCCESS_NEW_FAQ_LIST,
  ERROR_NEW_FAQ_LIST,
  SUCCESS_DELETE_FAQ,
  ERROR_DELETE_FAQ,
  SUCCESS_LOGOUT_TAB_CLOSE,
  ERROR_LOGOUT_TAB_CLOSE,
} from "./help.actions";
import { DEFAULT_STATE } from "./help.state";

export const helpReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_ADD_NEW_FAQ:
      const addNewFaqData = action.data;
      return { ...state, addNewFaqData };
    case ERROR_ADD_NEW_FAQ:
      const errorAddNewFaqData = action.data;
      return { ...state, addNewFaqData: errorAddNewFaqData };

    case SUCCESS_NEW_FAQ_LIST:
      const faqListData = action.data;
      return { ...state, faqListData };
    case ERROR_NEW_FAQ_LIST:
      const errorNewFaqListData = action.data;
      return { ...state, faqListData: errorNewFaqListData };

    case SUCCESS_DELETE_FAQ:
      const deleteFaqData = action.data;
      return { ...state, deleteFaqData };
    case ERROR_DELETE_FAQ:
      const errorDeleteFaqData = action.data;
      return { ...state, deleteFaqData: errorDeleteFaqData };

    case SUCCESS_LOGOUT_TAB_CLOSE:
      let logoutTabCloseData = action.data
      return { ...state, logoutTabCloseData }
    case ERROR_LOGOUT_TAB_CLOSE:
      let errorLogoutTabCloseData = action.data
      return { ...state, logoutTabCloseData: errorLogoutTabCloseData }

    default:
      return state;
  }
};
