import { message } from "antd";
import { put, takeLatest } from "redux-saga/effects";

import {
  ADD_NEW_FAQ,
  SUCCESS_ADD_NEW_FAQ,
  ERROR_ADD_NEW_FAQ,
  addNewFaqResponse,
  newFaqListResponse,
  SUCCESS_NEW_FAQ_LIST,
  ERROR_NEW_FAQ_LIST,
  NEW_FAQ_LIST,
  SUCCESS_DELETE_FAQ,
  ERROR_DELETE_FAQ,
  deleteFaqResponse,
  DELETE_FAQ,
  logoutTabcloseResponse,
  SUCCESS_LOGOUT_TAB_CLOSE,
  ERROR_LOGOUT_TAB_CLOSE,
  LOGOUT_TAB_CLOSE
} from "./help.actions";

import _ from "lodash";
import {
  addNewFaqApi,
  newFaqListApi,
  deleteFaqApi,
  tabCloseLogoutApi,
} from "../../../api/sdk/help";

function* addNewFaqRequest(data) {
  let getData = yield addNewFaqApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(addNewFaqResponse(SUCCESS_ADD_NEW_FAQ, getData.data));
    message.success(getData.data.message);
  } else {
    yield put(addNewFaqResponse(ERROR_ADD_NEW_FAQ, getData.data));
    //message.error(getData.data.message)
  }
}
export function* addNewFaqWatcher() {
  yield takeLatest(ADD_NEW_FAQ, addNewFaqRequest);
}

function* newFaqListRequest() {
  let getData = yield newFaqListApi();
  if (getData.success && _.has(getData, "data.data")) {
    yield put(newFaqListResponse(SUCCESS_NEW_FAQ_LIST, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(newFaqListResponse(ERROR_NEW_FAQ_LIST, getData.data));
    //message.error(getData.data.message)
  }
}
export function* newFaqListWatcher() {
  yield takeLatest(NEW_FAQ_LIST, newFaqListRequest);
}

function* deleteFaqRequest(data) {
  let getData = yield deleteFaqApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(deleteFaqResponse(SUCCESS_DELETE_FAQ, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(deleteFaqResponse(ERROR_DELETE_FAQ, getData.data));
    //message.error(getData.data.message)
  }
}
export function* deleteFaqWatcher() {
  yield takeLatest(DELETE_FAQ, deleteFaqRequest);
}

function* logoutTabClose(data) {
  let getData = yield tabCloseLogoutApi(data)
  if (getData.success && _.has(getData, "data,data")) {
    yield put(logoutTabcloseResponse(SUCCESS_LOGOUT_TAB_CLOSE, getData.data))
  } else {
    yield put(logoutTabcloseResponse(ERROR_LOGOUT_TAB_CLOSE, getData.data))
  }
}
export function* logoutTabCloseWatcher() { yield takeLatest(LOGOUT_TAB_CLOSE, logoutTabClose) }