import { request } from "../request/axios.request";
import {
  CHANGED_NOTIFICATION_STATUS,
  CHANGE_PASSWORD,
  CREATE_NOTIFICATION_ALERT,
  DESTINATION_LIST,
  NOTIFICATION_LIST,
  ORIGINS_LIST,
  SAVE_PREFERENCE,
  DELETE_NOTIFICATION_ALERT,
  EDIT_NOTIFICATION_ALERT,
  ORIGIN_DESTINATION_DATA,
  SYNC_NOTIFICATION_DATA,
  ADD_SIGNATURE,
  GET_SIGNATURE,
  Change_Profile_Pic,
  SETTING_EQUIPMENT_TYPE,
  SETTING_DB_LIMIT,
  EDIT_SIGNATURE,
  DELETE_SIGNATURE,
  LIST_SIGNATURE,
  ALL_LIST_SIGNATURE,
  CHECK_SIGNATURE_NAME,
  SETTING_RFP_ALERT_STATUS,
  SETTING_RFP_ALERT_UPDATE
} from "../routing/route";
import { Tokens } from "../../app/storage/index";

//change password
export async function changePassworSettingsdApi(data) {
  let passData = data.data;
  return request({
    url: CHANGE_PASSWORD,
    method: "post",
    data: passData,
  });
}

//Notification list
export async function NotificationListApi(data) {
  //let notificationData = data.data;
  return request({
    url: NOTIFICATION_LIST,
    method: "get",
  });
}

//Change Notification status
export async function NotificationStatusApi(data) {
  let notificationStatusData = data.data;
  return request({
    url: CHANGED_NOTIFICATION_STATUS,
    method: "post",
    data: notificationStatusData,
  });
}

//DESTINATION_LIST
export async function DestinationListApi(data) {
  let DestinationListData = data.data;
  return request({
    url: DESTINATION_LIST,
    method: "get",
    params: DestinationListData,
  });
}
//ORIGINS_LIST
export async function OriginsListApi(data) {
  let OriginsListData = data.data;
  return request({
    url: ORIGINS_LIST,
    method: "get",
    params: OriginsListData,
  });
}

//CREATE_NOTIFICATION_ALERT
export async function createNotificationAlertApi(data) {
  let createNotificationAlertData = data.data;
  return request({
    url: CREATE_NOTIFICATION_ALERT,
    method: "post",
    data: createNotificationAlertData,
  });
}
//SAVE_PREFERENCE
export async function savePreferenceApi(data) {
  let savepreference = data.data;
  return request({
    url: SAVE_PREFERENCE,
    method: "get",
    params: savepreference,
  });
}

export async function deleteNotificationApi(data) {
  let deleteNotificationData = data.data;
  return request({
    url: DELETE_NOTIFICATION_ALERT,
    method: "get",
    params: deleteNotificationData,
  });
}

export async function editNotificationApi(data) {
  let editNotificationData = data.data;
  return request({
    url: EDIT_NOTIFICATION_ALERT,
    method: "post",
    data: editNotificationData,
  });
}
//originDestination
export async function originDestinationDataApi(data) {
  let originDestinationData = data.data;
  return request({
    url: ORIGIN_DESTINATION_DATA,
    method: "get",
    params: originDestinationData,
  });
}

//sync Notification
export async function syncNotificationDataApi(data) {
  //let syncNotificationData = data.data;
  return request({
    url: SYNC_NOTIFICATION_DATA,
    method: "get",
  });
}

//add Signature
export async function addSignatureApi(data) {
  let addSignatureData = data.data;
  return request({
    url: ADD_SIGNATURE,
    method: "post",
    data: addSignatureData,
  });
}

//GET SIGNATURE
export async function getSignatureApi(data) {
  let getSignatureData = data.data;
  return request({
    url: GET_SIGNATURE,
    method: "get",
    params: getSignatureData,
  });
}

//edit SIGNATURE
export async function editSignatureApi(data) {
  let signatureData = data.data;
  return request({
    url: EDIT_SIGNATURE,
    method: "post",
    data: signatureData,
  });
}

//delete SIGNATURE
export async function deleteSignatureApi(data) {
  let signatureData = data.data;
  return request({
    url: DELETE_SIGNATURE,
    method: "post",
    data: signatureData,
  });
}

//LIST SIGNATURE
export async function listSignatureApi(data) {
  let signatureData = data.data;
  return request({
    url: LIST_SIGNATURE,
    method: "get",
    params: signatureData,
  });
}

//ALL LIST SIGNATURE
export async function allListSignatureApi(data) {
  let signatureData = data.data;
  return request({
    url: ALL_LIST_SIGNATURE,
    method: "get",
    params: signatureData,
  });
}

//CHECK_SIGNATURE
export async function checkSignatureNameApi(data) {
  let signatureData = data.data;
  return request({
    url: CHECK_SIGNATURE_NAME,
    method: "post",
    data: signatureData,
  });
}


//Change_Profile_Pic
export async function changeProfilePicApi(data) {
  let changeProfilePicData = data.data;
  let formData = new FormData();
  formData.append("profile_pic", changeProfilePicData.profile_pic);
  return request({
    url: Change_Profile_Pic,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
        }`,
    },
  });
}

export async function settingEquipmentTypeApi(data) {
  let settingEquipmentType = data.data
  return request({
    url: SETTING_EQUIPMENT_TYPE,
    method: "get",
    params: settingEquipmentType
  })
}

export async function settingDbLimitApi(data) {
  let settingDbLimit = data.data
  return request({
    url: SETTING_DB_LIMIT,
    method: "post",
    data: settingDbLimit
  })
}

export async function settingRfpAlertUpdateApi(data) {
  let rfp = data.data
  return request({
    url: SETTING_RFP_ALERT_UPDATE,
    method: "post",
    data: rfp
  })
}

export async function settingRfpAlertStatusApi() {
  return request({
    url: SETTING_RFP_ALERT_STATUS,
    method: "get"
  })
}


  