export const DEFAULT_STATE = {
  changePasswordSettingsData: {},
  notificationListData: {},
  changedNotificationStatusData: {},
  destinationsListData: {},
  originsListData: {},
  createNotificationAlertData: {},
  savePreferenceData: {},
  deleteNotificationData: {},
  editNotificationData: {},
  originDestinationData: {},
  syncNotificationData: {},
  addSignatureData: {},
  getSignature: {},
  changeProfilePic: {},
  equipmentTypeData: {},
  dbLimitData: {},
  editSignatureData: {},
  deleteSignature: {},
  listSignatureData: {},
  allListSignature: {},
  checkSignatureName: {},
  rfpAlertUpdateData: {},
  rfpAlertStatusData: {}
};
