import "antd/dist/antd.css";
import { put, takeLatest } from "redux-saga/effects";
import {
  //role with pagination
  LIST_USERS_ROLES,
  SUCCESS_LIST_USERS_ROLES,
  ERROR_LIST_USERS_ROLES,
  listUsersRolesResponse,
  //all role
  LIST_USERS_ROLES_ALL,
  SUCCESS_LIST_USERS_ROLES_ALL,
  ERROR_LIST_USERS_ROLES_ALL,
  listUsersRolesAllResponse,
  //add role
  ADD_USERS_ROLE,
  SUCCESS_ADD_USERS_ROLE,
  ERROR_ADD_USERS_ROLE,
  addUsersRoleResponse,
  //Edit role
  EDIT_USERS_ROLE,
  SUCCESS_EDIT_USERS_ROLE,
  ERROR_EDIT_USERS_ROLE,
  editUsersRoleResponse,
  //Delete role
  DELETE_USERS_ROLE,
  SUCCESS_DELETE_USERS_ROLE,
  ERROR_DELETE_USERS_ROLE,
  deleteUsersRoleResponse,
  //USERS_ROLE_ACCESS_MANAGEMENT_LIST
  USERS_ROLE_ACCESS_MANAGEMENT_LIST,
  SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_LIST,
  ERROR_USERS_ROLE_ACCESS_MANAGEMENT_LIST,
  usersRoleAccessManagementListResponse,
  //USERS_ROLE_ACCESS_MANAGEMENT_VERIFY
  USERS_ROLE_ACCESS_MANAGEMENT_VERIFY,
  SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY,
  ERROR_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY,
  usersRoleAccessManagementVerifyResponse,
  //USERS_ROLE_ACCESS_MANAGEMENT_LIST
  USERS_ROLE_ACCESS_MANAGEMENT_BATCH,
  SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_BATCH,
  ERROR_USERS_ROLE_ACCESS_MANAGEMENT_BATCH,
  usersRoleAccessManagementBatchResponse,
  //USERS_ROLE_ACCESS_MANAGEMENT_LIST
  // USERS_ROLE_ACCESS_MANAGEMENT_SINGLE,
  // SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE,
  // ERROR_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE,
  // usersRoleAccessManagementSingleResponse

} from "./manageAccess.action";
import {
  usersRolesListAll,
  usersRolesList,
  usersRoleAdd,
  usersRoleEdit,
  usersRoleDelete,
  //usersRoleGet,
  usersRoleAccessManagementBatch,
 // usersRoleAccessManagementSingle,
  usersRoleAccessManagementList,
  usersRoleAccessManagementVerify
} from "../../../api/sdk/manageAccess";
import _ from "lodash";

function* usersRolesListRequest(usersRoles) {
  //console.log(usersRoles);
  let roleList = yield usersRolesList(usersRoles);
  if (roleList.success && _.has(roleList, "data.data")) {
    yield put(
      listUsersRolesResponse(SUCCESS_LIST_USERS_ROLES, roleList.data)
    );
  } else {
    yield put(
      listUsersRolesResponse(ERROR_LIST_USERS_ROLES, roleList.data)
    );
  }
}
export function* usersRolesListWatcher() {
  yield takeLatest(LIST_USERS_ROLES, usersRolesListRequest);
}

function* usersRolesListAllRequest(usersRoles) {
  let roleList = yield usersRolesListAll(usersRoles);
  if (roleList.success && _.has(roleList, "data.data")) {
    yield put(
      listUsersRolesAllResponse(SUCCESS_LIST_USERS_ROLES_ALL, roleList.data)
    );
  } else {
    yield put(
      listUsersRolesAllResponse(ERROR_LIST_USERS_ROLES_ALL, roleList.data)
    );
  }
}
export function* usersRolesListAllWatcher() {
  yield takeLatest(LIST_USERS_ROLES_ALL, usersRolesListAllRequest);
}

function* usersRolesAddRequest(usersRoles) {
  let roleList = yield usersRoleAdd(usersRoles);
  if (roleList.success && _.has(roleList, "data.data")) {
    yield put(
      addUsersRoleResponse(SUCCESS_ADD_USERS_ROLE, roleList.data)
    );
  } else {
    yield put(
      addUsersRoleResponse(ERROR_ADD_USERS_ROLE, roleList.data)
    );
  }
}
export function* usersRolesAddlWatcher() {
  yield takeLatest(ADD_USERS_ROLE, usersRolesAddRequest);
}

function* usersRolesEditRequest(usersRoles) {
  let roleList = yield usersRoleEdit(usersRoles);
  if (roleList.success && _.has(roleList, "data.data")) {
    yield put(
      editUsersRoleResponse(SUCCESS_EDIT_USERS_ROLE, roleList.data)
    );
  } else {
    yield put(
      editUsersRoleResponse(ERROR_EDIT_USERS_ROLE, roleList.data)
    );
  }
}
export function* usersRolesEditWatcher() {
  yield takeLatest(EDIT_USERS_ROLE, usersRolesEditRequest);
}

function* usersRolesDeleteRequest(usersRoles) {
  let roleList = yield usersRoleDelete(usersRoles);
  if (roleList.success && _.has(roleList, "data.data")) {
    yield put(
      deleteUsersRoleResponse(SUCCESS_DELETE_USERS_ROLE, roleList.data)
    );
  } else {
    yield put(
      deleteUsersRoleResponse(ERROR_DELETE_USERS_ROLE, roleList.data)
    );
  }
}
export function* usersRolesDeletelWatcher() {
  yield takeLatest(DELETE_USERS_ROLE, usersRolesDeleteRequest);
}

function* usersRoleAccessManagementListRequest(usersRoles) {
  let roleList = yield usersRoleAccessManagementList(usersRoles);
  if (roleList.success && _.has(roleList, "data.data")) {
    yield put(
      usersRoleAccessManagementListResponse(SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_LIST, roleList.data)
    );
  } else {
    yield put(
      usersRoleAccessManagementListResponse(ERROR_USERS_ROLE_ACCESS_MANAGEMENT_LIST, roleList.data)
    );
  }
}
export function* usersRoleAccessManagementListWatcher() {
  yield takeLatest(USERS_ROLE_ACCESS_MANAGEMENT_LIST, usersRoleAccessManagementListRequest);
}

function* usersRoleAccessManagementBatchRequest(usersRoles) {
  let roleList = yield usersRoleAccessManagementBatch(usersRoles);
  if (roleList.success && _.has(roleList, "data.data")) {
    yield put(
      usersRoleAccessManagementBatchResponse(SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_BATCH, roleList.data)
    );
  } else {
    yield put(
      usersRoleAccessManagementBatchResponse(ERROR_USERS_ROLE_ACCESS_MANAGEMENT_BATCH, roleList.data)
    );
  }
}
export function* usersRoleAccessManagementBatchWatcher() {
  yield takeLatest(USERS_ROLE_ACCESS_MANAGEMENT_BATCH, usersRoleAccessManagementBatchRequest);
}


function* usersRoleAccessManagementVerifyRequest(usersRoles) {
  let roleList = yield usersRoleAccessManagementVerify(usersRoles);
  if (roleList.success && _.has(roleList, "data.data")) {
    yield put(
      usersRoleAccessManagementVerifyResponse(SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY, roleList.data)
    );
  } else {
    yield put(
      usersRoleAccessManagementVerifyResponse(ERROR_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY, roleList.data)
    );
  }
}
export function* usersRoleAccessManagementVerifyWatcher() {
  yield takeLatest(USERS_ROLE_ACCESS_MANAGEMENT_VERIFY, usersRoleAccessManagementVerifyRequest);
}
