// Registration
export const REGISTRATION = "REGISTRATION";
export const registration = (data) => ({ type: REGISTRATION, data });
export const SUCCESS_REGISTRATION = "SUCCESS_REGISTRATION";
export const ERROR_REGISTRATION = "ERROR_REGISTRATION";
export const registrationResponse = (type, data) => ({ type, data });

// User Login
export const LOGIN = "LOGIN";
export const login = (data) => ({ type: LOGIN, data });
export const SUCCESS_LOGIN = "SUCCESS_LOGIN";
export const ERROR_LOGIN = "ERROR_LOGIN";
export const loginResponse = (type, data) => ({ type, data });

// User Logout
export const USER_LOGOUT = "USER_LOGOUT";
export const userLogout = (data) => ({ type: USER_LOGOUT, data });
export const SUCCESS_USER_LOGOUT = "SUCCESS_USER_LOGOUT";
export const ERROR_USER_LOGOUT = "ERROR_USER_LOGOUT";
export const userLogoutResponse = (type, data) => ({ type, data });

//forgot password
export const FORGOTPASSWORD = "FORGOTPASSWORD";
export const forgotPassword = (data) => ({ type: FORGOTPASSWORD, data });
export const SUCCESS_FORGOTPASSWORD = "SUCCESS_FORGOTPASSWORD";
export const ERROR_FORGOTRESETPASSWORD = "ERROR_FORGOTRESETPASSWORD";
export const forgotPasswordResponse = (type, data) => ({ type, data });

//reset password
export const RESETPASSWORD = "RESETPASSWORD";
export const resetPassword = (data) => ({ type: RESETPASSWORD, data });
export const SUCCESS_RESETPASSWORD = "SUCCESS_RESETPASSWORD";
export const ERROR_RESETPASSWORD = "ERROR_RESETPASSWORD";
export const resetPasswordResponse = (type, data) => ({ type, data });

// User verify email
export const VERIFYRMAIL = "VERIFYRMAIL";
export const verifyEmail = (data) => ({ type: VERIFYRMAIL, data });
export const SUCCESS_VERIFYRMAIL = "SUCCESS_VERIFYRMAIL";
export const ERROR_VERIFYRMAIL = "ERROR_VERIFYRMAIL";
export const verifyEmailResponse = (type, data) => ({ type, data });

// Adding Signature
export const ADDSIGNATURE = "ADDSIGNATURE";
export const addSignature = (data) => ({ type: ADDSIGNATURE, data });
export const SUCCESS_ADDSIGNATURE = "SUCCESS_ADDSIGNATURE";
export const ERROR_ADDSIGNATURE = "ERROR_ADDSIGNATURE";
export const addSignatureResponse = (type, data) => ({ type, data });

//manage-staff-access
export const MANAGESTAFFACCESS = "MANAGESTAFFACCESS";
export const manageStaffAccess = (data) => ({ type: MANAGESTAFFACCESS, data });
export const SUCCESS_MANAGESTAFFACCESS = "SUCCESS_MANAGESTAFFACCESS";
export const ERROR_MANAGESTAFFACCESS = "ERROR_MANAGESTAFFACCESS";
export const manageStaffAccessResponse = (type, data) => ({ type, data });

//emailVerification
export const EMAILVERIFICATION = "EMAILVERIFICATION";
export const emailVerification = (data) => ({ type: EMAILVERIFICATION, data });
export const SUCCESS_EMAILVERIFICATION = "SUCCESS_EMAILVERIFICATION";
export const ERROR_EMAILVERIFICATION = "ERROR_EMAILVERIFICATION";
export const emailVerificationResponse = (type, data) => ({ type, data });

//linkexpire
export const LINKEXPIREATION = "LINKEXPIREATION";
export const linkexpire = (data) => ({ type: LINKEXPIREATION, data });
export const SUCCESS_LINKEXPIREATION = "SUCCESS_LINKEXPIREATION";
export const ERROR_LINKEXPIREATION = "ERROR_LINKEXPIREATION";
export const linkexpireResponse = (type, data) => ({ type, data });

// User verify
export const USERVERIFYRMAIL = "USERVERIFYRMAIL";
export const verifyUser = (data) => ({ type: USERVERIFYRMAIL, data });
export const SUCCESS_USERVERIFYRMAIL = "SUCCESS_USERVERIFYRMAIL";
export const ERROR_USERVERIFYRMAIL = "ERROR_USERVERIFYRMAIL";
export const verifyUserResponse = (type, data) => ({ type, data });

//otpVerification
export const OTPVERIFICATION = "OTPVERIFICATION";
export const otpVerification = (data) => ({ type: OTPVERIFICATION, data });
export const SUCCESS_OTPVERIFICATION = "SUCCESS_OTPVERIFICATION";
export const ERROR_OTPVERIFICATION = "ERROR_OTPVERIFICATION";
export const otpVerificationResponse = (type, data) => ({ type, data });

//user otp resend
export const OTPRESEND = "OTPRESEND";
export const otpResend = (data) => ({ type: OTPRESEND, data });
export const SUCCESS_OTPRESEND = "SUCCESS_OTPRESEND";
export const ERROR_OTPRESEND = "ERROR_OTPRESEND";
export const otpResendResponse = (type, data) => ({ type, data });
