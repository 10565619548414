import { message } from "antd";
import "antd/dist/antd.css";
import { put, takeLatest } from "redux-saga/effects";
import {
  registrationResponse,
  REGISTRATION,
  SUCCESS_REGISTRATION,
  ERROR_REGISTRATION,
  loginResponse,
  LOGIN,
  ERROR_LOGIN,
  SUCCESS_LOGIN,
  SUCCESS_FORGOTPASSWORD,
  ERROR_FORGOTRESETPASSWORD,
  FORGOTPASSWORD,
  forgotPasswordResponse,
  resetPasswordResponse,
  RESETPASSWORD,
  SUCCESS_RESETPASSWORD,
  ERROR_RESETPASSWORD,
  verifyEmailResponse,
  VERIFYRMAIL,
  SUCCESS_VERIFYRMAIL,
  ERROR_VERIFYRMAIL,
  SUCCESS_USER_LOGOUT,
  ERROR_USER_LOGOUT,
  USER_LOGOUT,
  userLogoutResponse,
  EMAILVERIFICATION,
  emailVerificationResponse,
  SUCCESS_EMAILVERIFICATION,
  ERROR_EMAILVERIFICATION,
  SUCCESS_LINKEXPIREATION,
  linkexpireResponse,
  ERROR_LINKEXPIREATION,
  LINKEXPIREATION,
  verifyUserResponse,
  USERVERIFYRMAIL,
  SUCCESS_USERVERIFYRMAIL,
  ERROR_USERVERIFYRMAIL,
  OTPVERIFICATION,
  otpVerificationResponse,
  SUCCESS_OTPVERIFICATION,
  ERROR_OTPVERIFICATION,
  OTPRESEND,
  otpResendResponse,
  SUCCESS_OTPRESEND,
  ERROR_OTPRESEND
} from "./auth.action";
import {
  emailVerificationApi,
  linkexpireApi,
  registrationApi,
} from "../../../api/sdk/auth";
import {
  loginApi,
  forgotPasswordApi,
  resetPasswordApi
} from "../../../api/sdk/auth";
import { verifyEmailApi } from "../../../api/sdk/auth";
import { verifyUserApi } from "../../../api/sdk/auth";
import { otpVerificationApi } from "../../../api/sdk/auth";
import { otpResendApi } from "../../../api/sdk/auth";
import { logoutApi } from "../../../api/sdk/auth";
import _ from "lodash";

// Registration
function* registrationRequest(data) {
  let getData = yield registrationApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(registrationResponse(SUCCESS_REGISTRATION, getData.data));
  } else {
    yield put(registrationResponse(ERROR_REGISTRATION, getData.data));
  }
}
export function* registrationWatcher() {
  yield takeLatest(REGISTRATION, registrationRequest);
}

function* loginRequest(userData) {
  let loginData = yield loginApi(userData);
  if (loginData.success && _.has(loginData, "data")) {
    yield put(loginResponse(SUCCESS_LOGIN, loginData.data));
    message.success(loginData.data.message);
  } else {
    yield put(loginResponse(ERROR_LOGIN, loginData.data));
    //message.error(loginData.data.message)
  }
}
export function* loginWatcher() {
  yield takeLatest(LOGIN, loginRequest);
}

function* forgotPasswordRequest(data) {
  let getData = yield forgotPasswordApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(forgotPasswordResponse(SUCCESS_FORGOTPASSWORD, getData.data));
    message.success(getData.data.message);
  } else {
    yield put(forgotPasswordResponse(ERROR_FORGOTRESETPASSWORD, getData.data));
    //message.error(getData.data.message)
  }
}
export function* forgotPasswordWatcher() {
  yield takeLatest(FORGOTPASSWORD, forgotPasswordRequest);
}

function* resetPasswordRequest(data) {
  let getData = yield resetPasswordApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(resetPasswordResponse(SUCCESS_RESETPASSWORD, getData.data));
    message.success(getData.data.message);
  } else {
    yield put(resetPasswordResponse(ERROR_RESETPASSWORD, getData.data));
    //message.error(getData.data.message)
  }
}
export function* resetPasswordWatcher() {
  yield takeLatest(RESETPASSWORD, resetPasswordRequest);
}
function* verifyEmailRequest(userData) {
  let getData = yield verifyEmailApi(userData);
  if (getData.success && _.has(getData, "data")) {
    yield put(verifyEmailResponse(SUCCESS_VERIFYRMAIL, getData.data));
    message.success(getData.data.message);
  } else {
    //message.error(getData.data.message)
    yield put(verifyEmailResponse(ERROR_VERIFYRMAIL, getData.data));
  }
}
export function* verifyEmailWatcher() {
  yield takeLatest(VERIFYRMAIL, verifyEmailRequest);
}
// user Logout
function* logoutRequest(data) {
  let logoutData = yield logoutApi(data);
  if (logoutData.success) {
    // message.success(logoutData.data.message);
    yield put(userLogoutResponse(SUCCESS_USER_LOGOUT, logoutData.data));
  } else {
    yield put(userLogoutResponse(ERROR_USER_LOGOUT, logoutData.data));
  }
}
export function* logoutWatcher() {
  yield takeLatest(USER_LOGOUT, logoutRequest);
}

function* emailVerificationRequest(data) {
  let getData = yield emailVerificationApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      emailVerificationResponse(SUCCESS_EMAILVERIFICATION, getData.data)
    );
    message.success(getData.data.message);
  } else {
    yield put(emailVerificationResponse(ERROR_EMAILVERIFICATION, getData.data));
    //message.error(getData.data.message)
  }
}
export function* emailVerificationWatcher() {
  yield takeLatest(EMAILVERIFICATION, emailVerificationRequest);
}

function* linkexpireRequest(data) {
  let getData = yield linkexpireApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(linkexpireResponse(SUCCESS_LINKEXPIREATION, getData.data));
    message.success(getData.data.message);
  } else {
    yield put(linkexpireResponse(ERROR_LINKEXPIREATION, getData.data));
    //message.error(getData.data.message)
  }
}
export function* linkexpireWatcher() {
  yield takeLatest(LINKEXPIREATION, linkexpireRequest);
}

function* verifyUserRequest(userData) {
  let getData = yield verifyUserApi(userData);
  if (getData.success && _.has(getData, "data")) {
    yield put(verifyUserResponse(SUCCESS_USERVERIFYRMAIL, getData.data));
    message.success(getData.data.message);
  } else {
    //message.error(getData.data.message)
    yield put(verifyUserResponse(ERROR_USERVERIFYRMAIL, getData.data));
  }
}
export function* verifyUserWatcher() {
  yield takeLatest(USERVERIFYRMAIL, verifyUserRequest);
}

function* otpVerificationRequest(data) {
  let getData = yield otpVerificationApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      otpVerificationResponse(SUCCESS_OTPVERIFICATION, getData.data)
    );
    message.success(getData.data.message);
  } else {
    yield put(otpVerificationResponse(ERROR_OTPVERIFICATION, getData.data));
    //message.error(getData.data.message)
  }
}
export function* otpVerificationWatcher() {
  yield takeLatest(OTPVERIFICATION, otpVerificationRequest);
}

function* otpResendRequest(data) {
  let getData = yield otpResendApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      otpResendResponse(SUCCESS_OTPRESEND, getData.data)
    );
    message.success(getData.data.message);
  } else {
    yield put(otpResendResponse(ERROR_OTPRESEND, getData.data));
    //message.error(getData.data.message)
  }
}
export function* otppResendWatcher() {
  yield takeLatest(OTPRESEND, otpResendRequest);
}