import React, { useState, useEffect } from "react";
import { preventCopyPaste } from "../../../common/commonPasswordValidation";
import {
  _,
  constants,
  fieldValidator,
  listManageStaff,
  Modal,
  message,
  useDispatch,
  useSelector,
  usePrevious,
} from "./index";
import { handleResetPasswordSubmit } from "./validator";
import {
  VisibilityOutlinedIcon,
  VisibilityOffOutlinedIcon,
} from "../../../common/commonMaterialIcons";

export const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const [viewPassword, setViewPassword] = useState(false);
  const [viewconfirmPassword, setViewconfirmPassword] = useState(false);

  //Reset password Data
  const resetPasswordManageStaffData = useSelector(
    (state) => state.managestaff.resetPasswordManageStaffData
  );
  const prevResetPasswordManageStaffData = usePrevious({
    resetPasswordManageStaffData,
  });

  const [resetPassword, setResetPassword] = useState({
    password: "",
    passwordCls: "",
    passwordErr: "",
    confirmPassword: "",
    confirmPasswordCls: "",
    confirmPasswordErr: "",
    correctInput: constants.RIGHT_INPUT,
    wrongInput: constants.WRONG_INPUT,
  });
  // On Enter Sign In
  const keyPressDownEvent1 = (e) => {
    if (e.key === "Enter") {
      handleResetPasswordSubmit(
        resetPassword,
        setResetPassword,
        props,
        dispatch
      );
    }
  };

  const toggleResetPasswordVisiblity = () => {
    setViewPassword(viewPassword ? false : true);
  };
  const toggleResetconfirmPasswordVisiblity = () => {
    setViewconfirmPassword(viewconfirmPassword ? false : true);
  };

  // Check Validation Function
  const checkValidation = (field, value, type, maxLength, minLength) => {
    return fieldValidator(
      field,
      value,
      type,
      maxLength,
      minLength,
      resetPassword.password
    );
  };
  // Set The Login Input Values
  const setInputValue = (e, type, maxLength, minLength) => {
    let value = e.target.value;
    if (type === "password") {
      value = e.target.value.replace(/\s+/g, "");
    }
    let error = checkValidation(
      e.target.name,
      value,
      type,
      maxLength,
      minLength
    );
    if (e.target.name === "password" && resetPassword.confirmPassword.length > 0 && value === resetPassword.confirmPassword) {
      resetPassword.confirmPasswordErr = ""
    }
    setResetPassword({
      ...resetPassword,
      [e.target.name]: value,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
  };

  //After reset password
  useEffect(() => {
    if (
      prevResetPasswordManageStaffData &&
      prevResetPasswordManageStaffData.resetPasswordManageStaffData !==
      resetPasswordManageStaffData
    ) {
      if (
        resetPasswordManageStaffData &&
        _.has(resetPasswordManageStaffData, "data") &&
        resetPasswordManageStaffData.success === true
      ) {
        props.loader(false);
        props.setResetPasswordModalShow(false);
        dispatch(listManageStaff({ page: props.page, search: props.search }));
      }
      if (
        resetPasswordManageStaffData &&
        _.has(resetPasswordManageStaffData, "message") &&
        resetPasswordManageStaffData.success === false
      ) {
        props.loader(false);
        message.error(resetPasswordManageStaffData.message);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPasswordManageStaffData, prevResetPasswordManageStaffData]);

  //Modal intially set data is empty values
  useEffect(() => {
    if (props.isResetPasswordModalShow) {
      setResetPassword({
        ...resetPassword,
        passwordCls: "",
        passwordErr: "",
        password: "",
        confirmPassword: "",
        confirmPasswordErr: "",
        confirmPasswordCls: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isResetPasswordModalShow]);

  return (
    <Modal
      title="Reset Password"
      open={props.isResetPasswordModalShow}
      onOk={() => props.setResetPasswordModalShow(false)}
      onCancel={() => props.setResetPasswordModalShow(false)}
      maskClosable={false}
      keyboard={false}
      footer={null}
    >
      <div className="modal-body p-0">
        <form className="app_form">
          <div className="row">
            <div className="form-group">
              <label>
                Password<span className="text-danger">*</span>
              </label>
              <div className={"input-group post" + resetPassword.passwordCls}>
                <input
                  onKeyDown={(e) => keyPressDownEvent1(e)}
                  onCopy={(e) => preventCopyPaste(e)}
                  onPaste={(e) => preventCopyPaste(e)}
                  type={viewPassword ? "text" : "password"}
                  placeholder="Enter Password"
                  name="password"
                  className="loginpassword"
                  value={resetPassword.password}
                  onChange={(e) => setInputValue(e, "password", 50, 8)}
                  maxLength="50"
                />
                <button
                  className={
                    viewPassword
                      ? "btn-viewPass btn-pass"
                      : "btn-hidePass btn-pass"
                  }
                  onClick={toggleResetPasswordVisiblity}
                  type="button"
                >
                  {/* <span className="material-symbols-outlined visibility">visibility</span>
                                    <span className="material-symbols-outlined visibility_off">visibility_off</span> */}
                  {viewPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </button>
              </div>
              {resetPassword.passwordErr ? (
                <span className="text-danger mt-1">

                  {resetPassword.passwordErr}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <label>
                Confirm Password<span className="text-danger">*</span>
              </label>
              <div
                className={
                  "input-group post" + resetPassword.confirmPasswordCls
                }
              >
                <input
                  onKeyDown={(e) => keyPressDownEvent1(e)}
                  onCopy={(e) => preventCopyPaste(e)}
                  onPaste={(e) => preventCopyPaste(e)}
                  type={viewconfirmPassword ? "text" : "password"}
                  placeholder="Enter Confirm Password"
                  className="loginpassword"
                  name="confirmPassword"
                  value={resetPassword.confirmPassword}
                  onChange={(e) => setInputValue(e, "password", 50, 8)}
                  maxLength="50"
                />
                <button
                  className={
                    viewconfirmPassword
                      ? "btn-viewPass btn-pass"
                      : "btn-hidePass btn-pass"
                  }
                  onClick={toggleResetconfirmPasswordVisiblity}
                  type="button"
                >
                  {/* <span className="material-symbols-outlined visibility">visibility</span>
                                    <span className="material-symbols-outlined visibility_off">visibility_off</span> */}
                  {viewconfirmPassword ? (
                    <VisibilityOutlinedIcon />
                  ) : (
                    <VisibilityOffOutlinedIcon />
                  )}
                </button>
              </div>
              {resetPassword.confirmPasswordErr ? (
                <span className="text-danger mt-1">

                  {resetPassword.confirmPasswordErr}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group">
              <div className="btn-group d-flex w-100 position-relative">
                <button
                  type="button"
                  onClick={() => props.setResetPasswordModalShow(false)}
                  className="btn-default modal-action modal-close btn-default btn-open"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn-default btn-primary"
                  onClick={() =>
                    handleResetPasswordSubmit(
                      resetPassword,
                      setResetPassword,
                      props,
                      dispatch
                    )
                  }
                >
                  <span>Submit</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};
