export const DEFAULT_STATE = {
  manageStaffData: {},
  staffUpdateDetailsData: {},
  sendUserCredentialsData: {},
  resetPasswordManageStaffData: {},
  deleteUserData: {},
  newUserManageStaffData: {},
  checkUserEmailData: {},
  staffsetpermissionData: {},
  staffgetpermissionData: {},
  staffpermissionmoduleData: {},
  manageStaffGetPermissionData:{},
  staffRestoreData:{},
  staffRfpAlertUpdateData:{},
  editUserManageStaffData: {},
};
