import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  _,
  constants,
  fieldValidator,
  listManageStaff,
  Modal,
  message,
  PhoneInput,
  useDispatch,
  useSelector,
  usePrevious,
  Select,
  Empty
} from "./index";
import {
  handleRestoreUser
} from "./manageStaffAccessEvent/manageStaffAccessTableEvent";
import { HandleUpdateSubmit } from "./validator";

export const EditUserProfile = (props) => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [editUserProfile, setEditUserProfile] = useState({
    username: "",
    usernameCls: "",
    usernameErr: "",
    mobile: "",
    mobileCls: "",
    mobileErr: "",
    userRole: "",
    userRoleCls: "",
    userRoleErr: "",
    correctInput: constants.RIGHT_INPUT,
    wrongInput: constants.WRONG_INPUT,
  });
  const [propsUserData,setPropsUserData]= useState({id:'',email:'',name:'',mobile:'',role_id:''});
  //Add New User Data editUserManageStaff
  const editUserManageStaffData = useSelector(
    (state) => state.managestaff.editUserManageStaffData
  );
  const prevEditUserManageStaffData = usePrevious({ editUserManageStaffData });
  // On Enter Sign In
  const keyPressDownEvent = (e) => {
    if (e.key === "Enter") {
      HandleUpdateSubmit(editUserProfile, setEditUserProfile, props, dispatch,propsUserData);
    }
  };
  // Check Validation Function
  const checkValidation = (field, value, type, maxLength, minLength) => {
    return fieldValidator(
      field,
      value,
      type,
      maxLength,
      minLength,
      ''
    );
  };
  // Set The Login Input Values
  const setInputValue = (e, type, maxLength, minLength) => {
    let value = e.target.value;
    let error = checkValidation(
      e.target.name,
      value,
      type,
      maxLength,
      minLength
    );
    setEditUserProfile({
      ...editUserProfile,
      [e.target.name]: value,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
  };

  const handleinputphonenumber = (mobile) => {
    let error = checkValidation("mobile", mobile, "required", 15, 10);
    setEditUserProfile({
      ...editUserProfile,
      mobile: mobile,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
  };
  const selectHandleRoleChange = (userRole) => {
    //console.log(userRole)
    let error = userRole===''?'Select User Role': '';
    
    setEditUserProfile({
      ...editUserProfile,
      userRole: userRole,
      userRoleErr:error, 
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
  };
  
  useEffect(() => {
    if (props.isEditProfileModalShow) {
      //props.setSearch("");
      setEditUserProfile({
        ...editUserProfile,
        username: "",
        usernameErr: "",
        usernameCls: "",
        mobile: "",
        mobileCls: "",
        mobileErr: "",
        userRole: "",
        userRoleCls: "",
        userRoleErr: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isEditProfileModalShow]);

  //After add edit User
  useEffect(() => {
    if (
      prevEditUserManageStaffData &&
      prevEditUserManageStaffData.editUserManageStaffData !==
      editUserManageStaffData
    ) {
      if (
        editUserManageStaffData &&
        _.has(editUserManageStaffData, "data") &&
        editUserManageStaffData.success === true
      ) {
        //props.loader(false);
        props.setEditProfileModalShow(false);
        dispatch(listManageStaff({ page: 1, search: props.search }));
        //props.setSearch("");
      }
      if( editUserManageStaffData &&
        _.has(editUserManageStaffData, "message") &&
        editUserManageStaffData.success === false &&  (editUserManageStaffData.open_confirm===1 ||  editUserManageStaffData.open_confirm==='1')){
          props.loader(false);
          props.setEditProfileModalShow(false);
          message.error(editUserManageStaffData.message)
          handleRestoreUser(editUserManageStaffData.data.user_id, editUserManageStaffData.data.email, props.loader,dispatch,editUserManageStaffData.data.password,editUserManageStaffData.data.user_role);
        }
      if (
        editUserManageStaffData &&
        _.has(editUserManageStaffData, "message") &&
        editUserManageStaffData.success === false 
      ) {
        props.loader(false);
        // message.error(editUserManageStaffData.message.props.children[0].props.children[1]);
        message.error(editUserManageStaffData.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUserManageStaffData, prevEditUserManageStaffData]);

  // const keyPressDownEventEmail = (e) => {
  //     if (e.key === 'Enter') {
  //         const userEmailData = {
  //             email: state.email
  //         }
  //         dispatch(checkUserEmail(userEmailData));
  //     }
  // }
  // useEffect(() => {
  //     if (prevUserEmailData && prevUserEmailData.userEmailData !== userEmailData) {
  //         if (userEmailData && _.has(userEmailData, 'data') && userEmailData.success === true) {

  //         }
  //         if (userEmailData && _.has(userEmailData, 'message') && userEmailData.success === false) {
  //             setLoader(false)
  //             setEmailMessage(userEmailData.message)
  //             // message.error(userEmailData.message)
  //             if(userEmailData && _.has(userEmailData, 'message') && userEmailData.success === false && userEmailData.statusCode === 401){
  //             }else{
  //             message.error(userEmailData.message);
  //             }
  //         }
  //     }
  // }, [userEmailData, prevUserEmailData]);
  // stop the screen scroll while loader going on
  const staffRestoreData = useSelector(
    (state) => state.managestaff.staffRestoreData
  );
  const prevStaffRestoreData = usePrevious({ staffRestoreData });
   //After  User restore
   useEffect(() => {
    if (
      prevStaffRestoreData &&
      prevStaffRestoreData.staffRestoreData !==
      staffRestoreData
    ) {
      if (
        staffRestoreData &&
        _.has(staffRestoreData, "data") &&
        staffRestoreData.success === true
      ) {
        //props.loader(false);
        message.success(staffRestoreData.message)
        dispatch(listManageStaff({ page: 1, search: props.search }));
      }
      if (
        staffRestoreData &&
        _.has(staffRestoreData, "message") &&
        staffRestoreData.success === false 
      ) {
        props.loader(false);
        message.error(staffRestoreData.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffRestoreData, prevStaffRestoreData]);
  useLayoutEffect(() => {
    if (props.loader) {
      //   document.getElementById("root").style.overflow = "hidden";
      //   document.getElementById("root").style.height = "100%";
    }
    if (!props.loader) {
      document.getElementById("root").style.overflow = "auto";
      document.getElementById("root").style.height = "auto";
    }
  }, [props.loader]);
  useEffect(()=>{
    const userData=props.userData;
    const mobile=userData.mobile?userData.mobile.toString():'';
    setPropsUserData({id:userData.id,email:userData.email,name:userData.name,mobile:mobile,role_id:userData.role_id});
    setEditUserProfile({...editUserProfile,
        username:userData.name,
        mobile:mobile, 
        userRole:userData.role_id,
        usernameErr: "",
        usernameCls: "",
        mobileCls: "",
        mobileErr: "",
        userRoleCls: "",
        userRoleErr: "",
    
    });
  },[props.userData]);
  return (
    <Modal
      title="Edit User Profile"
      maskClosable={false}
      open={props.isEditProfileModalShow}
      onOk={() => props.setEditProfileModalShow(false)}
      onCancel={() => props.setEditProfileModalShow(false)}
      footer={null}
      keyboard={false}
    >
      <div className="modal-body p-0">
        <div className="row align-items-center">
          <div className="form_inn">
            <form className="app_form">
            <div className="row">
                <div className="form-group">
                  <label>
                    Email
                  </label>
                  <div className={"input-group post"}>
                    <input
                    disabled={true}
                      autoComplete="off"
                      type="text"
                      placeholder="Enter Email"
                      name="email"
                      value={propsUserData.email || ""}
                    />
                  </div>
                 
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label>
                    User Name<span className="text-danger">*</span>
                  </label>
                  <div className={"input-group post" + editUserProfile.emailCls}>
                    <input
                      onKeyDown={(e) => keyPressDownEvent(e)}
                      type="text"
                      placeholder="Enter Username"
                      name="username"
                      value={editUserProfile.username || ""}
                      onChange={(e) =>
                        setInputValue(e, "symbolWithOneAlphanumeric", 20, 3)
                      }
                      autoComplete="off"
                    />
                  </div>
                  {editUserProfile.usernameErr ? (
                    <span className="text-danger mt-1">
                      {editUserProfile.usernameErr}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label>
                    Mobile<span className="text-danger">*</span>
                  </label>

                  <div className="input_group mobileEle position-relative">
                    <PhoneInput
                      country={"us"}
                      placeholder="(702) 123-4567"
                      value={editUserProfile.mobile || ""}
                      onChange={(mobile) => handleinputphonenumber(mobile)}
                      inputExtraProps={{
                        prefix: "+",
                        autoFocus: true,
                      }}
                      onKeyDown={(e) => keyPressDownEvent(e)}
                      name="mobile"
                    />
                    {editUserProfile.mobileErr ? (
                      <span className="text-danger mt-1">
                        {editUserProfile.mobileErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              
              {props.userdata.user_role_id === 1 || props.userdata.user_role_id === '1'?
              <div className="row">
                <div className="form-group">
                  <label>User Role<span className="text-danger">*</span></label>
                  <div className="input-group app_Select">
                    <Select
                      name="userRole"
                      value={
                        editUserProfile.userRole
                          ? editUserProfile.userRole
                          :propsUserData.role_id
                      }
                      placeholder="Click To Choose User Role"
                      onChange={(e, options) => selectHandleRoleChange(e, options)}
                    >
                        <Option
                              key={'urs'+1}
                              value={''}
                              disabled={true}
                            >
                             Click To Choose User Role
                            </Option>
                      {props.listUsersRolesAllList && props.listUsersRolesAllList.length > 0 ? (
                        _.map(props.listUsersRolesAllList, (role, i) => {
                          return (
                            <Option
                              key={i}
                              value={role.id ? role.id : ""}
                              allvalue={role}
                            >
                              {role.name}
                            </Option>
                          );
                        })
                      ) : (
                        <Option className="py-5 my-3" value="disabled" disabled>
                          <Empty />
                        </Option>
                      )}
                    </Select>
                    {editUserProfile.userRoleErr ? (
                      <span className="text-danger mt-1">
                        {editUserProfile.userRoleErr}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
               : ("")
              }
              <div className="row">
                <div className="form-group">
                  <div className="btn-group d-flex w-100 position-relative">
                    <button
                      type="button"
                      onClick={() => {
                        props.setEditProfileModalShow(false);
                        //props.setSearch("");
                      }}
                      className="btn-default modal-action modal-close btn-default btn-open"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        HandleUpdateSubmit(editUserProfile, setEditUserProfile, props, dispatch,propsUserData)
                      }
                      className="btn-default btn-primary"
                    >
                      <span>Update User</span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
