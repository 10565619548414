import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import {
  csvimportequipmenttypeApi,
  csvimportinvalidcarrierApi,
  getDestinationSearchListcarrierlanesApi,
  getOriginSearchListcarrierlanesApi,
  importinvalidcarrierorigindestinationApi,
  importvalidlanesApi,
  insertvalidlanesdataApi,
  mcdotlistgetApi,
  importReplacementEmailApi,
  insertEquipmentTypeListApi,
  insertDataSourceApi,
} from "../../../api/sdk/insertcarrierdata";
import {
  csvimportinsertcarrierApi,
  insertcarrierdataApi,
} from "../../../api/sdk/insertcarrierdata";
import {
  csvimportinsertcarrierListResponse,
  CSV_IMPORT_INSERT_CARRIER,
  ERROR_CSV_IMPORT_INSERT_CARRIER,
  ERROR_INSERT_CARRIER_DATA,
  insertcarrierdataListResponse,
  INSERT_CARRIER_DATA,
  SUCCESS_CSV_IMPORT_INSERT_CARRIER,
  SUCCESS_INSERT_CARRIER_DATA,
  csvinvalidemailcarrerdataListResponse,
  ERROR_CSV_IMPORT_INVALID_DATA_CARRIER,
  SUCCESS_CSV_IMPORT_INVALID_DATA_CARRIER,
  CSV_IMPORT_INVALID_DATA_CARRIER,
  csvimportequipmenttypedataListResponse,
  SUCCESS_CSV_IMPORT_EQUIPMENT_TYPE,
  ERROR_CSV_IMPORT_EQUIPMENT_TYPE,
  CSV_IMPORT_EQUIPMENT_TYPE,
  mcdotlistgetdataListResponse,
  SUCCESS_GET_MC_DOT_LIST,
  ERROR_GET_MC_DOT_LIST,
  GET_MC_DOT_LIST,
  importinvalidcarrierorigindestinationdataListResponse,
  SUCCESS_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
  ERROR_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
  IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
  importvalidlanesdataListResponse,
  SUCCESS_IMPORT_VALID_LANES,
  ERROR_IMPORT_VALID_LANES,
  IMPORT_VALID_LANES,
  insertvalidlanesdataListResponse,
  insertReplacementEmailDataResponse,
  SUCCESS_IMPORT_REPLACEMENT_EMAIL_DATA,
  ERROR_IMPORT_REPLACEMENT_EMAIL_DATA,
  IMPORT_REPLACEMENT_EMAIL_DATA,
  SUCCESS_IMPORT_VALID_LANES_DATA,
  ERROR_IMPORT_VALID_LANES_DATA,
  IMPORT_VALID_LANES_DATA,
  originSearchListResponse,
  destinationSearchListResponse,
  SUCCESS_ORIGINS_LIST_CARRIER_LANES,
  ERROR_ORIGINS_LIST_CARRIER_LANES,
  ORIGINS_LIST_CARRIER_LANES,
  SUCCESS_DESTINATION_LIST_CARRIER_LANES,
  ERROR_DESTINATION_LIST_CARRIER_LANES,
  DESTINATION_LIST_CARRIER_LANES,
  insertEquipmentTypeListResponse,
  SUCCESS_INSERT_EQUIPMENT_TYPE_LIST,
  ERROR_INSERT_EQUIPMENT_TYPE_LIST,
  INSERT_EQUIPMENT_TYPE_LIST,
  insertDataSourceListResponse,
  SUCCESS_INSERT_DATASOURCE_LIST,
  ERROR_INSERT_DATASOURCE_LIST,
  INSERT_DATASOURCE_LIST,
  EquipmentListInsertCarrierDataResponse,
  SUCCESS_EQUIPMENT_LIST_INSERT_CARRIER_DATA,
  ERROR_EQUIPMENT_LIST_INSERT_CARRIER_DATA,
  EQUIPMENT_LIST_INSERT_CARRIER_DATA,
} from "./insertcarrierdata.action";
import { advanceSearchEquipmentApi } from "../../../api/sdk/dashboard";

function* insertcarrierdataRequest(data) {
  let getData = yield insertcarrierdataApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      insertcarrierdataListResponse(SUCCESS_INSERT_CARRIER_DATA, getData.data)
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      insertcarrierdataListResponse(ERROR_INSERT_CARRIER_DATA, getData.data)
    );
    // message.error(getData.data.message)
  }
}
export function* insertcarrierdataWatcher() {
  yield takeLatest(INSERT_CARRIER_DATA, insertcarrierdataRequest);
}

function* csvimportinsertcarrierRequest(data) {
  let getData = yield csvimportinsertcarrierApi(data);

  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      csvimportinsertcarrierListResponse(
        SUCCESS_CSV_IMPORT_INSERT_CARRIER,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      csvimportinsertcarrierListResponse(
        ERROR_CSV_IMPORT_INSERT_CARRIER,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* csvimportinsertcarrierWatcher() {
  yield takeLatest(CSV_IMPORT_INSERT_CARRIER, csvimportinsertcarrierRequest);
}

// INVALID EMAIL DISPATCH EMAIL START HERE

function* csvimportinvalidemailRequest(data) {
  let getData = yield csvimportinvalidcarrierApi(data);

  if (getData.data.success && _.has(getData.data, "data.data")) {
    yield put(
      csvinvalidemailcarrerdataListResponse(
        SUCCESS_CSV_IMPORT_INVALID_DATA_CARRIER,
        getData.data
      )
    );
    //message.success(getData.data.message)
  } else {
    yield put(
      csvinvalidemailcarrerdataListResponse(
        ERROR_CSV_IMPORT_INVALID_DATA_CARRIER,
        getData.data
      )
    );
    //message.error(getData.data.message)
  }
}
export function* csvimportinvalidcarrierWatcher() {
  yield takeLatest(
    CSV_IMPORT_INVALID_DATA_CARRIER,
    csvimportinvalidemailRequest
  );
}

// INVALID EMAIL DISPATCH EMAIL END HERE

// CSV_IMPORT_EQUIPMENT_TYPE START HERE

function* csvimportequipmenttypeRequest(data) {
  let getData = yield csvimportequipmenttypeApi(data);
  if (getData.data.success && _.has(getData.data, "data.data")) {
    yield put(
      csvimportequipmenttypedataListResponse(
        SUCCESS_CSV_IMPORT_EQUIPMENT_TYPE,
        getData.data
      )
    );
    //message.success(getData.data.message)
  } else {
    yield put(
      csvimportequipmenttypedataListResponse(
        ERROR_CSV_IMPORT_EQUIPMENT_TYPE,
        getData.data
      )
    );
    //message.error(getData.data.message)
  }
}
export function* csvimportequipmenttypeWatcher() {
  yield takeLatest(CSV_IMPORT_EQUIPMENT_TYPE, csvimportequipmenttypeRequest);
}

// CSV_IMPORT_EQUIPMENT_TYPE END HERE

// GET_MC_DOT_LIST START HERE

function* mcdotlistgetRequest(data) {
  let getData = yield mcdotlistgetApi(data);
  if (getData.data.success && _.has(getData.data, "data.data")) {
    yield put(
      mcdotlistgetdataListResponse(SUCCESS_GET_MC_DOT_LIST, getData.data)
    );
    //message.success(getData.data.message)
  } else {
    yield put(
      mcdotlistgetdataListResponse(ERROR_GET_MC_DOT_LIST, getData.data)
    );
    //message.error(getData.data.message)
  }
}
export function* mcdotlistgetWatcher() {
  yield takeLatest(GET_MC_DOT_LIST, mcdotlistgetRequest);
}

// GET_MC_DOT_LIST END HERE

// IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION START HERE

function* importinvalidcarrierorigindestinationRequest(data) {
  let getData = yield importinvalidcarrierorigindestinationApi(data);
  if (getData.data.success && _.has(getData.data, "data.data")) {
    yield put(
      importinvalidcarrierorigindestinationdataListResponse(
        SUCCESS_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
        getData.data
      )
    );
    //message.success(getData.data.message)
  } else {
    yield put(
      importinvalidcarrierorigindestinationdataListResponse(
        ERROR_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
        getData.data
      )
    );
    //message.error(getData.data.message)
  }
}
export function* importinvalidcarrierorigindestinationWatcher() {
  yield takeLatest(
    IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
    importinvalidcarrierorigindestinationRequest
  );
}

// IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION END HERE

// IMPORT_VALID_LANES START HERE

function* importvalidlanesRequest(data) {
  let getData = yield importvalidlanesApi(data);
  if (getData.data.success && _.has(getData.data, "data.data")) {
    yield put(
      importvalidlanesdataListResponse(SUCCESS_IMPORT_VALID_LANES, getData.data)
    );
    //message.success(getData.data.message)
  } else {
    yield put(
      importvalidlanesdataListResponse(ERROR_IMPORT_VALID_LANES, getData.data)
    );
    //message.error(getData.data.message)
  }
}
export function* importvalidlanesWatcher() {
  yield takeLatest(IMPORT_VALID_LANES, importvalidlanesRequest);
}

// IMPORT_VALID_LANES END HERE

// IMPORT_VALID_LANES_DATA START HERE

function* insertvalidlanesdataRequest(data) {
  let getData = yield insertvalidlanesdataApi(data);
  if (getData.data.success && _.has(getData.data, "data.data")) {
    yield put(
      insertvalidlanesdataListResponse(
        SUCCESS_IMPORT_VALID_LANES_DATA,
        getData.data
      )
    );
    //message.success(getData.data.message)
  } else {
    yield put(
      insertvalidlanesdataListResponse(
        ERROR_IMPORT_VALID_LANES_DATA,
        getData.data
      )
    );
    //message.error(getData.data.message)
  }
}
export function* insertvalidlanesdataWatcher() {
  yield takeLatest(IMPORT_VALID_LANES_DATA, insertvalidlanesdataRequest);
}

// IMPORT_VALID_LANES_DATA END HERE

// INSERT REPLACEMENT EMAIL START HERE
function* insertReplacementEmailRequest(data) {
  let getData = yield importReplacementEmailApi(data);
  if (getData.data.success && _.has(getData.data, "data.data")) {
    yield put(
      insertReplacementEmailDataResponse(
        SUCCESS_IMPORT_REPLACEMENT_EMAIL_DATA,
        getData.data
      )
    );
  } else {
    yield put(
      insertReplacementEmailDataResponse(
        ERROR_IMPORT_REPLACEMENT_EMAIL_DATA,
        getData.data
      )
    );
  }
}
export function* insertReplacementEmaildataWatcher() {
  yield takeLatest(
    IMPORT_REPLACEMENT_EMAIL_DATA,
    insertReplacementEmailRequest
  );
}
// INSERT REPLACEMENT EMAIL END HERE

//get origin list by searching keyword
function* originSearchList(data) {
  let originSearchListData = yield getOriginSearchListcarrierlanesApi(data);
  if (
    originSearchListData.success &&
    _.has(originSearchListData, "data.data")
  ) {
    yield put(
      originSearchListResponse(
        SUCCESS_ORIGINS_LIST_CARRIER_LANES,
        originSearchListData.data
      )
    );
  } else {
    yield put(
      originSearchListData(
        ERROR_ORIGINS_LIST_CARRIER_LANES,
        originSearchListData.data
      )
    );
  }
}
export function* originSearchListcarrierlanesWatcher() {
  yield takeLatest(ORIGINS_LIST_CARRIER_LANES, originSearchList);
}

//get destination list by searching keyword
function* destinationSearchList(data) {
  let destinationSearchListData = yield getDestinationSearchListcarrierlanesApi(
    data
  );
  if (
    destinationSearchListData.success &&
    _.has(destinationSearchListData, "data.data")
  ) {
    yield put(
      destinationSearchListResponse(
        SUCCESS_DESTINATION_LIST_CARRIER_LANES,
        destinationSearchListData.data
      )
    );
  } else {
    yield put(
      destinationSearchListData(
        ERROR_DESTINATION_LIST_CARRIER_LANES,
        destinationSearchListData.data
      )
    );
  }
}
export function* destinationSearchListcarrierlanesWatcher() {
  yield takeLatest(DESTINATION_LIST_CARRIER_LANES, destinationSearchList);
}

function* insertEquipmentTypeList(data) {
  let insertEquipmentList = yield insertEquipmentTypeListApi(data)
  if (insertEquipmentList.success && _.has(insertEquipmentList, "data.data")) {
    yield put(insertEquipmentTypeListResponse(
      SUCCESS_INSERT_EQUIPMENT_TYPE_LIST,
      insertEquipmentList.data
    ))
  } else {
    yield put(insertEquipmentTypeListResponse(
      ERROR_INSERT_EQUIPMENT_TYPE_LIST,
      insertEquipmentList.data
    ))
  }
}
export function* insertEquipmentTypeListWatcher() {
  yield takeLatest(
    INSERT_EQUIPMENT_TYPE_LIST,
    insertEquipmentTypeList
  )
}
// data source 
function* insertDataSourceList(data) {
  let insertDataSourceListData = yield (insertDataSourceApi(data))
  if (insertDataSourceListData.success && _.has(insertDataSourceListData, "data.data")) {
    yield put(insertDataSourceListResponse(
      SUCCESS_INSERT_DATASOURCE_LIST, insertDataSourceListData.data
    ))
  } else {
    yield put(insertDataSourceListResponse(
      ERROR_INSERT_DATASOURCE_LIST, insertDataSourceListData.data
    ))
  }
}
export function* insertDataSourceListWatcher() {
  yield takeLatest(INSERT_DATASOURCE_LIST, insertDataSourceList)
}


//  advance search equipment list
function* EquipmentListInsertCarrierData(data) {
  let EquipmentListInsertCarrierDataResult = yield advanceSearchEquipmentApi(data);
  if (EquipmentListInsertCarrierDataResult.success &&
    _.has(EquipmentListInsertCarrierDataResult, "data.data")) {
    yield put(EquipmentListInsertCarrierDataResponse(
      SUCCESS_EQUIPMENT_LIST_INSERT_CARRIER_DATA,
      EquipmentListInsertCarrierDataResult.data
    ))
  } else {
    yield put(EquipmentListInsertCarrierDataResponse(
      ERROR_EQUIPMENT_LIST_INSERT_CARRIER_DATA,
      EquipmentListInsertCarrierDataResult.data
    ))
  }
}

export function* EquipmentListInsertCarrierDataWatcher() {
  yield takeLatest(
    EQUIPMENT_LIST_INSERT_CARRIER_DATA,
    EquipmentListInsertCarrierData
  )
}