import { message } from "antd";
// common password validations
export const checkPassword = (passwordValue, errorWrongInput) => {
  let success = "";
  let error = errorWrongInput;
  let errormsg = "",
    isError = false,
    errorCls = success;
  if (passwordValue.trim().length > 50) {
    errormsg = "Maximum 50 characters are allowed.";
    errorCls = error;
    isError = true;
  }
  if (passwordValue.trim().length < 8) {
    errormsg = "Minimum 8 characters are required.";
    errorCls = error;
    isError = true;
  }
  if (/[a-z]/.test(passwordValue.trim()) === false) {
    errormsg = "A lowercase letter is required.";
    errorCls = error;
    isError = true;
  }
  if (/[A-Z]/.test(passwordValue.trim()) === false) {
    errormsg = "A uppercase letter is required.";
    errorCls = error;
    isError = true;
  }
  if (
    /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/.test(
      passwordValue.trim()
    ) === false
  ) {
    errormsg = "A special character is required.";
    errorCls = error;
    isError = true;
  }
  if (/^\S*$/.test(passwordValue) === false) {
    errormsg = "Password must not contain whitespaces.";
    errorCls = error;
    isError = true;
  }
  if (/[0-9]/.test(passwordValue.trim()) === false) {
    errormsg = "A digit is required.";
    errorCls = error;
    isError = true;
  }
  return { errormsg, errorCls, isError };
};

// function to prevent copy pasting and cut in the password fields
export const preventCopyPaste = (e) => {
  e.preventDefault();
  message.error("Copy, paste in password is not allowed.");
};
