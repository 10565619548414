import {
  DASHBOARD_CARRIER_GRAPH,
  CARRIER_RATE_GRAPH,
  EQUIPMENT_TYPE_GRAPH,
  CARRIER_COUNT_GRAPH,
} from "../routing/route";

import { request } from "../request/axios.request";

export async function dashboardCarrierGraphApi(data) {
  let dashboardCarrierGraphData = data.data;
  return request({
    url: DASHBOARD_CARRIER_GRAPH,
    params: dashboardCarrierGraphData,
    method: "get",
  });
}
export async function carrierRateGraphApi(data) {
  let graph1Data = data.data;
  return request({
    url: CARRIER_RATE_GRAPH,
    params: graph1Data,
    method: "get",
  });
}
export async function equipmentTypeGraphApi(data) {
  let graph2Data = data.data;
  return request({
    url: EQUIPMENT_TYPE_GRAPH,
    params: graph2Data,
    method: "get",
  });
}
export async function carrierCountGraphApi(data) {
  let graph3Data = data.data;
  return request({
    url: CARRIER_COUNT_GRAPH,
    params: graph3Data,
    method: "get",
  });
}
