import {
  CUSTOM_NOTIFICATION_LIST,
  //  LATEST_NOTIFICATION_LIST
} from "../routing/route";

import { request } from "../request/axios.request";

export async function customNotificationListApi(data) {
  const customNotificationData = data.data;
  return request({
    url: CUSTOM_NOTIFICATION_LIST,
    params: customNotificationData,
    method: "get",
  });
}

// export async function latestNotificationListApi() {
//    return request({ url: LATEST_NOTIFICATION_LIST , method: 'get'})
// }
