import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Menu, Dropdown, message, Empty } from "antd";
import {
  DASHBOARD,
  HELP,
  MANAGEMAILS,
  MANAGESTAFFACCESS,
  SETTINGS,
  LOGIN,
  NOTIFICATIONS,
  USERDETAILS,
  CARRIERDETAIL,
  METRICS,
  NEWCARRIERDATA,
  POSTLOADDASHBOARD,
  CARRIERPORTAL,
  RFPUPLOAD,
  RFPREPORT,
  MANAGESUPPORTEMAILS,
  MANAGEUNSUBSCRIBEEMAILS
} from "../../../../app/routing/routeContants";
import brandlogo from "../../../assets/images/brand-logo.png";
import { usePrevious } from "../../../common/custom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import appicon from "../../../assets/images/app_icon.png";
import { Tokens, User } from "../../../storage";
import { userLogout } from "../../../redux/auth/auth.action";
import loader1 from "../../../assets/images/loader1.svg";
import _ from "lodash";
import avatarfile from "../../../assets/images/avatar/user_placehoder_avatar@2x.png";
import avatar from "../../../assets/images/avatar/user_placehoder_avatar@2x.png";
// import { latestNotificationList } from "../../../redux/notifications/notification.actions"
import { RELOADCARRIER } from "../../../routing/routeContants";
import { staffgetpermission } from "../../screen/managaStaffAccess";
import { logoutTabclose } from "../../../redux/help/help.actions"
import moment from "moment";
//To set the message configration like how many times message should display
import {
  EditOutlinedIcon,
  NotificationsOutlinedIcon,
  PowerSettingsNewOutlinedIcon,
} from "../../../common/commonMaterialIcons";
import { clearUserDetails } from "../../../storage/user";
// import { useIdleTimer } from "react-idle-timer";
import { errorNotification } from "../../../common/notification-alert";
import {
  HelpCenterOutlinedIcon,
  SettingsOutlinedIcon,
  SyncIcon,
  FolderCopyOutlinedIcon,
  WysiwygOutlinedIcon,
  MailOutlinedIcon,
  PersonOutlineIcon,
  GridViewOutlinedIcon,
  LocalShippingOutlinedIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  UploadOutlinedIcon,
  SummarizeOutlinedIcon,
  AccountTreeOutlinedIcon,
  CandlestickChartOutlinedIcon,
} from "../../../common/commonMaterialIcons";
message.config({
  maxCount: 1,
});
export {
  HelpCenterOutlinedIcon,
  SettingsOutlinedIcon,
  SyncIcon,
  FolderCopyOutlinedIcon,
  WysiwygOutlinedIcon,
  MailOutlinedIcon,
  PersonOutlineIcon,
  GridViewOutlinedIcon,
  LocalShippingOutlinedIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  UploadOutlinedIcon,
  SummarizeOutlinedIcon,
  AccountTreeOutlinedIcon,
  CandlestickChartOutlinedIcon,
  brandlogo,
  DASHBOARD,
  HELP,
  Link,
  MANAGEMAILS,
  MANAGESTAFFACCESS,
  SETTINGS,
  USERDETAILS,
  useHistory,
  useDispatch,
  useSelector,
  usePrevious,
  User,
  Menu,
  Dropdown,
  message,
  Empty,
  NOTIFICATIONS,
  PerfectScrollbar,
  Tokens,
  userLogout,
  LOGIN,
  loader1,
  _,
  appicon,
  avatar,
  //  latestNotificationList,
  CARRIERDETAIL,
  METRICS,
  RELOADCARRIER,
  staffgetpermission,
  NEWCARRIERDATA,
  avatarfile,
  POSTLOADDASHBOARD,
  CARRIERPORTAL,
  EditOutlinedIcon,
  NotificationsOutlinedIcon,
  PowerSettingsNewOutlinedIcon,
  clearUserDetails,
  //useIdleTimer,
  RFPUPLOAD,
  RFPREPORT,
  MANAGESUPPORTEMAILS,
  errorNotification, 
  logoutTabclose,
  moment,
  MANAGEUNSUBSCRIBEEMAILS
};
