import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import {
  dashboardfiltertrackingApi,
  downloadMetricsDataExcelApi,
  metricsTypeGraphApi,
  // trackloginuserApi,
  userListApi,
  downloadSearchFilterDataExcelApi
} from "../../../api/sdk/metrics";
import {
  dashboardfiltertrackingResponse,
  DASHBOARD_FILTER_TRACKING,
  downloadMetricsDataExcelResponse,
  DOWNLOAD_METRICS_DATA_EXCEL,
  ERROR_DASHBOARD_FILTER_TRACKING,
  ERROR_DOWNLOAD_METRICS_DATA_EXCEL,
  // ERROR_TRACK_LOGIN_USER,
  ERROR_USER_SEARCH_LIST,
  SUCCESS_DASHBOARD_FILTER_TRACKING,
  SUCCESS_DOWNLOAD_METRICS_DATA_EXCEL,
  // SUCCESS_TRACK_LOGIN_USER,
  SUCCESS_USER_SEARCH_LIST,
  ERROR_METRICS_USER_GRAPH,
  SUCCESS_METRICS_USER_GRAPH,
  METRICS_USER_GRAPH,
  // trackloginuserResponse,
  // TRACK_LOGIN_USER,
  userSearchListResponse,
  metricsTypeGraphResponse,
  USER_SEARCH_LIST,
  metricesUserSearchListResponse,
  SUCCESS_METRICS_USER_SEARCH_LIST,
  ERROR_METRICS_USER_SEARCH_LIST,
  METRICS_USER_SEARCH_LIST,
  downloadSearchFilterDataExcelResponse,
  SUCCESS_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL, 
  ERROR_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL,
  DOWNLOAD_SEARCH_FILTER_DATA_EXCEL
} from "./metrics.action";

// function* trackloginuserRequest(data) {
//   let getData = yield trackloginuserApi(data);
//   if (getData.success && _.has(getData, 'data.data')) {
//     yield put(trackloginuserResponse(SUCCESS_TRACK_LOGIN_USER, getData.data));
//     // message.success(getData.data.message)
//   } else {
//     yield put(trackloginuserResponse(ERROR_TRACK_LOGIN_USER, getData.data));
//     // message.error(getData.data.message)
//   }
// }
// export function* trackloginuserWatcher() {
//   yield takeLatest(TRACK_LOGIN_USER, trackloginuserRequest);
// }

function* dashboardfiltertrackingRequest(data) {
  let getData = yield dashboardfiltertrackingApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      dashboardfiltertrackingResponse(
        SUCCESS_DASHBOARD_FILTER_TRACKING,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      dashboardfiltertrackingResponse(
        ERROR_DASHBOARD_FILTER_TRACKING,
        getData.data
      )
    );
    // message.error(getData.data.message)
  }
}
export function* dashboardfiltertrackingWatcher() {
  yield takeLatest(DASHBOARD_FILTER_TRACKING, dashboardfiltertrackingRequest);
}

function* userListRequest(data) {
  let getData = yield userListApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(userSearchListResponse(SUCCESS_USER_SEARCH_LIST, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(userSearchListResponse(ERROR_USER_SEARCH_LIST, getData.data));
    // message.error(getData.data.message)
  }
}
export function* userListWatcher() {
  yield takeLatest(USER_SEARCH_LIST, userListRequest);
}

//download metrics data in excel
function* downloadMetricsExcel(data) {
  let downloadMetricsExcelData = yield downloadMetricsDataExcelApi(data);
  if (
    downloadMetricsExcelData.success &&
    _.has(downloadMetricsExcelData, "data.data")
  ) {
    yield put(
      downloadMetricsDataExcelResponse(
        SUCCESS_DOWNLOAD_METRICS_DATA_EXCEL,
        downloadMetricsExcelData.data
      )
    );
  } else {
    yield put(
      downloadMetricsDataExcelResponse(
        ERROR_DOWNLOAD_METRICS_DATA_EXCEL,
        downloadMetricsExcelData.data
      )
    );
  }
}

export function* downloadMetricsDataWatcher() {
  yield takeLatest(DOWNLOAD_METRICS_DATA_EXCEL, downloadMetricsExcel);
}

// Metrics User Graph
function* metricsTypeGraphRequest(data) {
  let getData = yield metricsTypeGraphApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      metricsTypeGraphResponse(SUCCESS_METRICS_USER_GRAPH, getData.data)
    );
    // message.success(getData.data.message)
  } else {
    yield put(metricsTypeGraphResponse(ERROR_METRICS_USER_GRAPH, getData.data));
    //message.error(getData.data.message)
  }
}
export function* metricsTypeGraphWatcher() {
  yield takeLatest(METRICS_USER_GRAPH, metricsTypeGraphRequest);
}


function* metricesUserListRequest(data) {
  let getData = yield userListApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(metricesUserSearchListResponse(SUCCESS_METRICS_USER_SEARCH_LIST, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(metricesUserSearchListResponse(ERROR_METRICS_USER_SEARCH_LIST, getData.data));
    // message.error(getData.data.message)
  }
}
export function* metricesUserListWatcher() {
  yield takeLatest(METRICS_USER_SEARCH_LIST, metricesUserListRequest);
}

function*  downloadSearchFilterDataExcelRequest(data) {
  let getData = yield downloadSearchFilterDataExcelApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(downloadSearchFilterDataExcelResponse(SUCCESS_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(downloadSearchFilterDataExcelResponse(ERROR_DOWNLOAD_SEARCH_FILTER_DATA_EXCEL, getData.data));
    // message.error(getData.data.message)
  }
}
export function* downloadSearchFilterDataExcelWatcher() {
  yield takeLatest(DOWNLOAD_SEARCH_FILTER_DATA_EXCEL, downloadSearchFilterDataExcelRequest);
}