export const DEFAULT_STATE = {
  copyhortCodeData: {},
  addNewTemplateData: {},
  templateListData: [],
  editEmailTemplateData: {},
  deleteEmailTemplateData: [],
  sendEmailData: {},
  checkTemplateNameData: {},
  myEmailsListData: {},
  emailStatsData: {},
  emailsFromSentFile: {},
  mySupportEmailsListData: {},
  myDownloadEmailsListData:{},
  myUnsubscribeEmailsListData: {},
  deleteUnsubscribeEmailsData: [],
};
