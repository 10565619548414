import {
    SUCCESS_EQUIPMENT_TYPE_LIST,
    ERROR_EQUIPMENT_TYPE_LIST,
    SUCCESS_ADD_LOAD_DESTINATION_LIST,
    ERROR_ADD_LOAD_DESTINATION_LIST,
    SUCCESS_ADD_LOAD_ORIGIN_LIST,
    ERROR_ADD_LOAD_ORIGIN_LIST,
    SUCCESS_SEARCH_BY_ORDER_ID,
    ERROR_SEARCH_BY_ORDER_ID,
    SUCCESS_ADD_LOAD_STOP_LIST,
    ERROR_ADD_LOAD_STOP_LIST,
    SUCCESS_SAVE_LOAD_DETAILS,
    ERROR_SAVE_LOAD_DETAILS,
    SUCCESS_ALL_LOAD_LIST,
    ERROR_ALL_LOAD_LIST,
    SUCCESS_SINGLE_LOAD_DETAILS,
    ERROR_SINGLE_LOAD_DETAILS,
    SUCCESS_DELETE_LOAD,
    ERROR_DELETE_LOAD,
    SUCCESS_UPDATE_LOAD,
    ERROR_UPDATE_LOAD
} from "./postLoad.actions"
import { DEFAULT_STATE } from "./postLoad.state"

export const postLoadReducer = (
    state = DEFAULT_STATE,
    action = {
        type: {},
        data: {}
    }
) => {
    switch (action.type) {
        case SUCCESS_EQUIPMENT_TYPE_LIST:
            const equipmentTypeList = action.data;
            return { ...state, equipmentTypeList }
        case ERROR_EQUIPMENT_TYPE_LIST:
            const errorEquipmentTypeList = action.data
            return { ...state, equipmentTypeList: errorEquipmentTypeList }

        case SUCCESS_ADD_LOAD_DESTINATION_LIST:
            const addLoadDestinationListData = action.data;
            return { ...state, addLoadDestinationListData }
        case ERROR_ADD_LOAD_DESTINATION_LIST:
            const errorAddLoadDestinationListData = action.data;
            return { ...state, addLoadDestinationListData: errorAddLoadDestinationListData }

        case SUCCESS_ADD_LOAD_ORIGIN_LIST:
            const addLoadoriginListData = action.data
            return { ...state, addLoadoriginListData }
        case ERROR_ADD_LOAD_ORIGIN_LIST:
            const errorAddLoadoriginListData = action.data
            return { ...state, addLoadoriginListData: errorAddLoadoriginListData }

        case SUCCESS_SEARCH_BY_ORDER_ID:
            const searchByorderIdData = action.data
            return { ...state, searchByorderIdData }
        case ERROR_SEARCH_BY_ORDER_ID:
            const errorSearchByorderIdData = action.data
            return { ...state, searchByorderIdData: errorSearchByorderIdData }

        case SUCCESS_ADD_LOAD_STOP_LIST:
            const addLoadStopListData = action.data
            return { ...state, addLoadStopListData }
        case ERROR_ADD_LOAD_STOP_LIST:
            const errorAddLoadStopListData = action.data
            return { ...state, addLoadStopListData: errorAddLoadStopListData }

        case SUCCESS_SAVE_LOAD_DETAILS:
            let saveLoadDetailsData = action.data
            return { ...state, saveLoadDetailsData }
        case ERROR_SAVE_LOAD_DETAILS:
            let errorSaveLoadDetailsData = action.data
            return { ...state, saveLoadDetailsData: errorSaveLoadDetailsData }

        case SUCCESS_ALL_LOAD_LIST:
            let allLoadListData = action.data
            return { ...state, allLoadListData }
        case ERROR_ALL_LOAD_LIST:
            let errorAllLoadListData = action.data
            return { ...state, allLoadListData: errorAllLoadListData }

        case SUCCESS_SINGLE_LOAD_DETAILS:
            let singleLoadDetailsData = action.data
            return { ...state, singleLoadDetailsData }
        case ERROR_SINGLE_LOAD_DETAILS:
            let errorSingleLoadDetailsData = action.data
            return { ...state, singleLoadDetailsData: errorSingleLoadDetailsData }
        case SUCCESS_DELETE_LOAD:
            let deleteLoadData = action.data
            return { ...state, deleteLoadData }
        case ERROR_DELETE_LOAD:
            let errorDeleteLoadData = action.data
            return { ...state, deleteLoadData: errorDeleteLoadData }

        case SUCCESS_UPDATE_LOAD:
            let updateLoadData = action.data
            return { ...state, updateLoadData }
        case ERROR_UPDATE_LOAD:
            let errrorUpdateLoadData = action.data
            return { ...state, updateLoadData: errrorUpdateLoadData }

        default:
            return state;
    }
}