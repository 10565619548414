// GET_ORIGIN_LIST
export const CARRIER_PORTAL_ORIGIN_SEARCH_LIST = "CARRIER_PORTAL_ORIGIN_SEARCH_LIST";
export const carrierPortalOriginSearchList = (data) => ({ type: CARRIER_PORTAL_ORIGIN_SEARCH_LIST, data });
export const SUCCESS_CARRIER_PORTAL_ORIGIN_SEARCH_LIST = "SUCCESS_CARRIER_PORTAL_ORIGIN_SEARCH_LIST";
export const ERROR_CARRIER_PORTAL_ORIGIN_SEARCH_LIST = "ERROR_CARRIER_PORTAL_ORIGIN_SEARCH_LIST";
export const carrierPortalOriginSearchListResponse = (type, data) => ({ type, data });

// GET_DEST_LIST
export const CARRIER_PORTAL_DESTINATION_SEARCH_LIST = "CARRIER_PORTAL_DESTINATION_SEARCH_LIST";
export const carrierPortalDestinationSearchList = (data) => ({
  type: CARRIER_PORTAL_DESTINATION_SEARCH_LIST,
  data,
});
export const SUCCESS_CARRIER_PORTAL_DESTINATION_SEARCH_LIST =
  "SUCCESS_CARRIER_PORTAL_DESTINATION_SEARCH_LIST";
export const ERROR_CARRIER_PORTAL_DESTINATION_SEARCH_LIST = "ERROR_CARRIER_PORTAL_DESTINATION_SEARCH_LIST";
export const carrierPortalDestinationSearchListResponse = (type, data) => ({ type, data });

// GET EQUIPMENT TYPE LIST
export const EQUIPMENT_TYPE_DISPLAY_LIST = "EQUIPMENT_TYPE_DISPLAY_LIST"
export const eqipmentTypeDisplayList = (data) => ({
type :EQUIPMENT_TYPE_DISPLAY_LIST,
data : data
})
export const SUCCESS_EQUIPMENT_TYPE_DISPLAY_LIST = "SUCCESS_EQUIPMENT_TYPE_DISPLAY_LIST"
export const ERROR_EQUIPMENT_TYPE_DISPLAY_LIST = "ERROR_EQUIPMENT_TYPE_DISPLAY_LIST"
export const eqipmentTypeDisplayListResponse = (type, data) => ({type, data})

// search result
export const CARRIER_PORTAL_SEARCH_RESULT = "CARRIER_PORTAL_SEARCH_RESULT"
export const carrierPortalSearchResultList = (data) => ({ type : CARRIER_PORTAL_SEARCH_RESULT , data : data})
export const SUCCESS_CARRIER_PORTAL_SEARCH_RESULT = "SUCCESS_CARRIER_PORTAL_SEARCH_RESULT" 
export const ERROR_CARRIER_PORTAL_SEARCH_RESULT = "ERROR_CARRIER_PORTAL_SEARCH_RESULT"
export const carrierPortalSearchResultListResponse = (type, data) => ({type, data})