
// import { data } from "jquery";
import {
  ERROR_CARRIER_PORTAL_DESTINATION_SEARCH_LIST,
  SUCCESS_CARRIER_PORTAL_DESTINATION_SEARCH_LIST,
  SUCCESS_CARRIER_PORTAL_ORIGIN_SEARCH_LIST,
  ERROR_CARRIER_PORTAL_ORIGIN_SEARCH_LIST,
  SUCCESS_EQUIPMENT_TYPE_DISPLAY_LIST,
  ERROR_EQUIPMENT_TYPE_DISPLAY_LIST,
  SUCCESS_CARRIER_PORTAL_SEARCH_RESULT,
  ERROR_CARRIER_PORTAL_SEARCH_RESULT
} from "./carrierPortal.action";
import { DEFAULT_STATE } from "./carrierPortal.state";

export const CarrierPortalReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {

    case SUCCESS_CARRIER_PORTAL_ORIGIN_SEARCH_LIST:
      const carrierPortaloriginSearchList = action.data;
      return { ...state, carrierPortaloriginSearchList };
    case ERROR_CARRIER_PORTAL_ORIGIN_SEARCH_LIST:
      const errorcarrierPortalOriginSearchList = action.data;
      return { ...state, carrierPortaloriginSearchList: errorcarrierPortalOriginSearchList };

    case SUCCESS_CARRIER_PORTAL_DESTINATION_SEARCH_LIST:
      const carrierPortaldestinationSearchList = action.data;
      return { ...state, carrierPortaldestinationSearchList };
    case ERROR_CARRIER_PORTAL_DESTINATION_SEARCH_LIST:
      const errorcarrierPortalDestinationSearchList = action.data;
      return { ...state, carrierPortaldestinationSearchList: errorcarrierPortalDestinationSearchList };

    case SUCCESS_EQUIPMENT_TYPE_DISPLAY_LIST:
      const equipmentTypeListDisplayList = action.data;
      return { ...state, equipmentTypeListDisplayList }
    case ERROR_EQUIPMENT_TYPE_DISPLAY_LIST:
      const errorEquipmentTypeListDisplayList = action.data;
      return { ...state, equipmentTypeListDisplayList: errorEquipmentTypeListDisplayList }

    case SUCCESS_CARRIER_PORTAL_SEARCH_RESULT:
      const carrierPortalSearchResult = action.data
      return { ...state, carrierPortalSearchResult }
    case ERROR_CARRIER_PORTAL_SEARCH_RESULT:
      const errorCarrierPortalSearchResult = action.data
      return { ...state, carrierPortalSearchResult: errorCarrierPortalSearchResult }

    default:
      return state;
  }
};
