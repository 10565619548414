export const LOGIN = "/auth/login";
export const REGISTER = "/auth/register";
export const DASHBOARD = "/dashboard";
export const MANAGESTAFFACCESS = "/manage-staff-access";
export const FORGOTPASSWORD = "/auth/forgot-password";
export const RESETPASSWORD = "/auth/reset-password";
export const VERIFY_EMAIL = "/verify-email";
export const EMAIL_VERIFICATION = "/auth/Email-Verification";
export const ABOUT = "/about";
export const SETTINGS = "/settings";
export const MANAGEMAILS = "/manage-emails";
export const MANAGEMAILS2 = "/manage-emails-design";
export const NOTIFICATIONS = "/notifications";
export const HELP = "/help";
export const USERDETAILS = "/carrier-details/";
export const CARRIERDETAIL = USERDETAILS + ":id";
export const STAFFPERMISSION = "/staff-permission/";
export const STAFFPERMISSIONID = STAFFPERMISSION + ":id";
export const METRICS = "/metrics";
export const RELOADCARRIER = "/reload-carrier-report";
export const NEWCARRIERDATA = "/insert-carrier-data";
export const MARKETDATA = "/marketdata";
export const POSTLOADDASHBOARD = "/posting-load";
export const CARRIERPORTAL = "/carrier-portal";
export const RFPUPLOAD = "/RFP-upload";
export const RFPREPORT = "/RFP-report";
export const MANAGESUPPORTEMAILS = "/manage-support-emails";
export const MOBILE_VERIFICATION = "/auth/otp-verification";
export const MANAGEUNSUBSCRIBEEMAILS = "/manage-unsubscribe-emails";




