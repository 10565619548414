import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";

import {
    carrierPortalDestinationListApi,
    carrierPortalOriginListApi,
    equipmentTypeListApi,
    carrierPortalSearchApi
} from "../../../api/sdk/carrierPortal";
import {
    CARRIER_PORTAL_DESTINATION_SEARCH_LIST,
    carrierPortalDestinationSearchListResponse,
    SUCCESS_CARRIER_PORTAL_DESTINATION_SEARCH_LIST,
    ERROR_CARRIER_PORTAL_DESTINATION_SEARCH_LIST,
    CARRIER_PORTAL_ORIGIN_SEARCH_LIST,
    carrierPortalOriginSearchListResponse,
    SUCCESS_CARRIER_PORTAL_ORIGIN_SEARCH_LIST,
    ERROR_CARRIER_PORTAL_ORIGIN_SEARCH_LIST,
    EQUIPMENT_TYPE_DISPLAY_LIST,
    eqipmentTypeDisplayListResponse,
    ERROR_EQUIPMENT_TYPE_DISPLAY_LIST,
    SUCCESS_EQUIPMENT_TYPE_DISPLAY_LIST,
    CARRIER_PORTAL_SEARCH_RESULT,
    carrierPortalSearchResultListResponse,
    SUCCESS_CARRIER_PORTAL_SEARCH_RESULT,
    ERROR_CARRIER_PORTAL_SEARCH_RESULT
} from "./carrierPortal.action";

//get origin list by searching keyword
function* carrierPortaloriginSearchList(data) {
    let carrierPortaloriginSearchListData = yield carrierPortalOriginListApi(data);
    if (
        carrierPortaloriginSearchListData.success &&
        _.has(carrierPortaloriginSearchListData, "data.data")
    ) {
        yield put(
            carrierPortalOriginSearchListResponse(SUCCESS_CARRIER_PORTAL_ORIGIN_SEARCH_LIST,carrierPortaloriginSearchListData.data)
        );
    } else {
        yield put(
            carrierPortaloriginSearchListData(ERROR_CARRIER_PORTAL_ORIGIN_SEARCH_LIST, carrierPortaloriginSearchListData.data)
        );
    }
}
export function* carrierPortaloriginSearchListWatcher() {
    yield takeLatest(CARRIER_PORTAL_ORIGIN_SEARCH_LIST, carrierPortaloriginSearchList);
}

//get destination list by searching keyword
function* carrierPortaldestinationSearchList(data) {
    let carrierPortaldestinationSearchListData = yield carrierPortalDestinationListApi(data);
    if (
        carrierPortaldestinationSearchListData.success &&
        _.has(carrierPortaldestinationSearchListData, "data.data")
    ) {
        yield put(
            carrierPortalDestinationSearchListResponse(SUCCESS_CARRIER_PORTAL_DESTINATION_SEARCH_LIST,carrierPortaldestinationSearchListData.data)
        );
    } else {
        yield put(
            carrierPortaldestinationSearchListData(ERROR_CARRIER_PORTAL_DESTINATION_SEARCH_LIST,carrierPortaldestinationSearchListData.data)
        );
    }
}
export function* carrierPortaldestinationSearchListWatcher() {
    yield takeLatest(CARRIER_PORTAL_DESTINATION_SEARCH_LIST, carrierPortaldestinationSearchList);
}

// get the equipment list 
function* carrierPortalEquipmentTypeList(data) {
    let carrierPortalEquipmentTypeListData = yield equipmentTypeListApi(data);
    if (
        carrierPortalEquipmentTypeListData.success &&
        _.has(carrierPortalEquipmentTypeListData, "data.data")
    ) {
        yield put(
            eqipmentTypeDisplayListResponse(SUCCESS_EQUIPMENT_TYPE_DISPLAY_LIST,carrierPortalEquipmentTypeListData.data)
        );
    } else {
        yield put(
            eqipmentTypeDisplayListResponse( ERROR_EQUIPMENT_TYPE_DISPLAY_LIST, carrierPortalEquipmentTypeListData.data)
        )
    }
}
export function* carrierPortalEquipmentTypeListWatcher() {
    yield takeLatest(EQUIPMENT_TYPE_DISPLAY_LIST, carrierPortalEquipmentTypeList)
}
//search result

function* carrierPortalSearchResult(data) {
    let carrierPortalSearchResultData = yield carrierPortalSearchApi(data)
    if (carrierPortalSearchResultData &&
        _.has(carrierPortalSearchResultData, "data.data")
    ){
        yield put(
            carrierPortalSearchResultListResponse(SUCCESS_CARRIER_PORTAL_SEARCH_RESULT, carrierPortalSearchResultData.data)
        )
    }else {
        carrierPortalSearchResultListResponse(ERROR_CARRIER_PORTAL_SEARCH_RESULT, carrierPortalSearchResultData.data)
    }
}

export function* carrierPortalSearchResultWatcher() {
yield takeLatest(CARRIER_PORTAL_SEARCH_RESULT, carrierPortalSearchResult)

}