import { message } from "antd";
import "antd/dist/antd.css";
import { put, takeLatest } from "redux-saga/effects";
import {
  COPY_SHORTCODE,
  SUCCESS_COPY_SHORTCODE,
  ERROR_COPY_SHORTCODE,
  copyShortCodeResponse,
  ADD_NEW_TEMPLATE,
  SUCCESS_ADD_NEW_TEMPLATE,
  ERROR_ADD_NEW_TEMPLATE,
  addNewTemplateResponse,
  templateListResponse,
  SUCCESS_TEMPLATE_LIST,
  ERROR_TEMPLATE_LIST,
  TEMPLATE_LIST,
  EDIT_EMAIL_TEMPLATE,
  SUCCESS_EDIT_EMAIL_TEMPLATE,
  ERROR_EDIT_EMAIL_TEMPLATE,
  editEmailTemplateResponse,
  DELETE_EMAIL_TEMPLATE,
  SUCCESS_DELETE_EMAIL_TEMPLATE,
  ERROR_DELETE_EMAIL_TEMPLATE,
  deleteEmailTemplateResponse,
  sendEmailResponse,
  SUCCESS_SEND_EMAIL,
  ERROR_SEND_EMAIL,
  SEND_EMAIL,
  checkTemplateNameResponse,
  SUCCESS_CHECK_TEMPLATE_NAME,
  ERROR_CHECK_TEMPLATE_NAME,
  CHECK_TEMPLATE_NAME,
  MY_EMAILS_LIST,
  SUCCESS_MY_EMAILS_LIST,
  ERROR_MY_EMAILS_LIST,
  myEmailsListResponse,
  EMAIL_STATS,
  SUCCESS_EMAIL_STATS,
  ERROR_EMAIL_STATS,
  emailStatsResponse,
  SUCCESS_FILE_SEND,
  ERROR_FILE_SEND,
  sendSelectedFileResponse,
  SEND_SELECTED_FILE,
  MY_SUPPORT_EMAILS_LIST,
  SUCCESS_MY_SUPPORT_EMAILS_LIST,
  ERROR_MY_SUPPORT_EMAILS_LIST,
  mySupportEmailsListResponse,
  MY_DOWNLOAD_EMAILS_LIST,
  SUCCESS_MY_DOWNLOAD_EMAILS_LIST,
  ERROR_MY_DOWNLOAD_EMAILS_LIST,
  myDownloadEmailsListResponse,
  MY_UNSUBSCRIBE_EMAILS_LIST,
  SUCCESS_MY_UNSUBSCRIBE_EMAILS_LIST,
  ERROR_MY_UNSUBSCRIBE_EMAILS_LIST,
  myUnsubscribeEmailsListResponse,
  DELETE_UNSUBSCRIBE_EMAILS,
  SUCCESS_DELETE_UNSUBSCRIBE_EMAILS,
  ERROR_DELETE_UNSUBSCRIBE_EMAILS,
  deleteUnsubscribeEmailsResponse
} from "./manageEmails.action";
import {
  copyShortCodeApi,
  addNewTemplateApi,
  templateListApi,
  editEmailTemplateApi,
  deleteEmailTemplateApi,
  sendEmailApi,
  checkTemplateNameApi,
  myEmailsListApi,
  emailStatsApi,
  selectedfileApi,
  mySupportEmailsListApi,
  myDownloadEmailsListApi,
  myUnsubscribeEmailsListApi,
  deleteUnsubscribeEmailsApi
} from "../../../api/sdk/manageEmails";
import _ from "lodash";

// COPY SHORTCODE
function* copyShortCodeRequest() {
  let getData = yield copyShortCodeApi();
  if (getData.success && _.has(getData, "data.data")) {
    yield put(copyShortCodeResponse(SUCCESS_COPY_SHORTCODE, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(copyShortCodeResponse(ERROR_COPY_SHORTCODE, getData.data));
    //message.error(getData.data.message)
  }
}
export function* copyShortCodeWatcher() {
  yield takeLatest(COPY_SHORTCODE, copyShortCodeRequest);
}

//Add New Template
function* addNewTemplateRequest(data) {
  let getData = yield addNewTemplateApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(addNewTemplateResponse(SUCCESS_ADD_NEW_TEMPLATE, getData.data));
    message.success(getData.data.message);
  } else {
    yield put(addNewTemplateResponse(ERROR_ADD_NEW_TEMPLATE, getData.data));
    //message.error(getData.data.message)
  }
}
export function* addNewTemplateWatcher() {
  yield takeLatest(ADD_NEW_TEMPLATE, addNewTemplateRequest);
}

//Template List
function* templateListRequest(data) {
  let getData = yield templateListApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(templateListResponse(SUCCESS_TEMPLATE_LIST, getData.data));
  } else {
    yield put(templateListResponse(ERROR_TEMPLATE_LIST, getData.data));
    //message.error(getData.data.message)
  }
}
export function* templateListWatcher() {
  yield takeLatest(TEMPLATE_LIST, templateListRequest);
}

// edit-email-template
function* editEmailTemplateRequest(data) {
  let getData = yield editEmailTemplateApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      editEmailTemplateResponse(SUCCESS_EDIT_EMAIL_TEMPLATE, getData.data)
    );
    message.success(getData.data.message);
  } else {
    yield put(
      editEmailTemplateResponse(ERROR_EDIT_EMAIL_TEMPLATE, getData.data)
    );
    //message.error(getData.data.message)
  }
}
export function* editEmailTemplateWatcher() {
  yield takeLatest(EDIT_EMAIL_TEMPLATE, editEmailTemplateRequest);
}

// delete-email-template
function* deleteEmailTemplateRequest(data) {
  let getData = yield deleteEmailTemplateApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      deleteEmailTemplateResponse(SUCCESS_DELETE_EMAIL_TEMPLATE, getData.data)
    );
    message.success(getData.data.message);
  } else {
    yield put(
      deleteEmailTemplateResponse(ERROR_DELETE_EMAIL_TEMPLATE, getData.data)
    );
    //message.error(getData.data.message)
  }
}
export function* deleteEmailTemplateWatcher() {
  yield takeLatest(DELETE_EMAIL_TEMPLATE, deleteEmailTemplateRequest);
}

// select file
function* sendSelectedFile(data) {
  let getData = yield selectedfileApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(sendSelectedFileResponse(SUCCESS_FILE_SEND, getData.data));
  } else {
    yield put(sendSelectedFileResponse(ERROR_FILE_SEND, getData.data));
  }
}
export function* sendSelectedFileWatcher() {
  yield takeLatest(SEND_SELECTED_FILE, sendSelectedFile);
}

// send email
function* sendEmailRequest(data) {
  let getData = yield sendEmailApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(sendEmailResponse(SUCCESS_SEND_EMAIL, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(sendEmailResponse(ERROR_SEND_EMAIL, getData.data));
    // message.error(getData.data.message)
  }
}
export function* sendEMailWatcher() {
  yield takeLatest(SEND_EMAIL, sendEmailRequest);
}

// check template name
function* checkTemplateNameRequest(data) {
  let getData = yield checkTemplateNameApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      checkTemplateNameResponse(SUCCESS_CHECK_TEMPLATE_NAME, getData.data)
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      checkTemplateNameResponse(ERROR_CHECK_TEMPLATE_NAME, getData.data)
    );
    // message.error(getData.data.message)
  }
}
export function* checkTemplateNameWatcher() {
  yield takeLatest(CHECK_TEMPLATE_NAME, checkTemplateNameRequest);
}

// my emails list
function* myEmailsListRequest(data) {
  let getData = yield myEmailsListApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(myEmailsListResponse(SUCCESS_MY_EMAILS_LIST, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(myEmailsListResponse(ERROR_MY_EMAILS_LIST, getData.data));
    // message.error(getData.data.message)
  }
}
export function* myEmailsListWatcher() {
  yield takeLatest(MY_EMAILS_LIST, myEmailsListRequest);
}
// my support emails list
function* mySupportEmailsListRequest(data) {
  let getData = yield mySupportEmailsListApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(mySupportEmailsListResponse(SUCCESS_MY_SUPPORT_EMAILS_LIST, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(mySupportEmailsListResponse(ERROR_MY_SUPPORT_EMAILS_LIST, getData.data));
    // message.error(getData.data.message)
  }
}
export function* mySupportEmailsListWatcher() {
  yield takeLatest(MY_SUPPORT_EMAILS_LIST, mySupportEmailsListRequest);
}
// email stats
function* emailStatsRequest() {
  let getData = yield emailStatsApi();
  if (getData.success && _.has(getData, "data.data")) {
    yield put(emailStatsResponse(SUCCESS_EMAIL_STATS, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(emailStatsResponse(ERROR_EMAIL_STATS, getData.data));
    // message.error(getData.data.message)
  }
}
export function* emailStatsWatcher() {
  yield takeLatest(EMAIL_STATS, emailStatsRequest);
}


// my download emails list
function* myDownloadEmailsListRequest(data) {
  let getData = yield myDownloadEmailsListApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(myDownloadEmailsListResponse(SUCCESS_MY_DOWNLOAD_EMAILS_LIST, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(myDownloadEmailsListResponse(ERROR_MY_DOWNLOAD_EMAILS_LIST, getData.data));
    // message.error(getData.data.message)
  }
}
export function* myDownloadEmailsListWatcher() {
  yield takeLatest(MY_DOWNLOAD_EMAILS_LIST, myDownloadEmailsListRequest);
}

// my Unsubscribe emails list
function* myUnsubscribeEmailsListRequest(data) {
  let getData = yield myUnsubscribeEmailsListApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(myUnsubscribeEmailsListResponse(SUCCESS_MY_UNSUBSCRIBE_EMAILS_LIST, getData.data));
    // message.success(getData.data.message)
  } else {
    yield put(myUnsubscribeEmailsListResponse(ERROR_MY_UNSUBSCRIBE_EMAILS_LIST, getData.data));
    // message.error(getData.data.message)
  }
}
export function* myUnsubscribeEmailsListWatcher() {
  yield takeLatest(MY_UNSUBSCRIBE_EMAILS_LIST, myUnsubscribeEmailsListRequest);
}

// delete Unsubscribe emails
function* deleteUnsubscribeEmailsRequest(data) {
  let getData = yield deleteUnsubscribeEmailsApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      deleteUnsubscribeEmailsResponse(SUCCESS_DELETE_UNSUBSCRIBE_EMAILS, getData.data)
    );
    message.success(getData.data.message);
  } else {
    yield put(
      deleteUnsubscribeEmailsResponse(ERROR_DELETE_UNSUBSCRIBE_EMAILS, getData.data)
    );
    //message.error(getData.data.message)
  }
}
export function* deleteUnsubscribeEmailsWatcher() {
  yield takeLatest(DELETE_UNSUBSCRIBE_EMAILS, deleteUnsubscribeEmailsRequest);
}