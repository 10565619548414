//Carrier Rate Graph
export const CARRIER_RATE_GRAPH = "CARRIER_RATE_GRAPH";
export const CarrierRateGraph = (data) => ({ type: CARRIER_RATE_GRAPH, data });
export const SUCCESS_CARRIER_RATE_GRAPH = "SUCCESS_CARRIER_RATE_GRAPH";
export const ERROR_CARRIER_RATE_GRAPH = "ERROR_CARRIER_RATE_GRAPH";
export const carrierRateGraphResponse = (type, data) => ({ type, data });

//Dashboard Carriers Graph
export const EQUIPMENT_TYPE_GRAPH = "EQUIPMENT_TYPE_GRAPH";
export const equipmentTypeGraph = (data) => ({
  type: EQUIPMENT_TYPE_GRAPH,
  data,
});
export const SUCCESS_EQUIPMENT_TYPE_GRAPH = "SUCCESS_EQUIPMENT_TYPE_GRAPH";
export const ERROR_EQUIPMENT_TYPE_GRAPH = "ERROR_EQUIPMENT_TYPE_GRAPH";
export const equipmentTypeGraphResponse = (type, data) => ({ type, data });

//Dashboard Count Graph
export const CARRIER_COUNT_GRAPH = "CARRIER_COUNT_GRAPH";
export const carrierCountGraph = (data) => ({
  type: CARRIER_COUNT_GRAPH,
  data,
});
export const SUCCESS_CARRIER_COUNT_GRAPH = "SUCCESS_CARRIER_COUNT_GRAPH";
export const ERROR_CARRIER_COUNT_GRAPH = "ERROR_CARRIER_COUNT_GRAPH";
export const carrierCountGraphResponse = (type, data) => ({ type, data });

//Dashboard Carrier Graph
export const DASHBOARD_CARRIER_GRAPH = "DASHBOARD_CARRIER_GRAPH";
export const dashboardCarrierGraph = (data) => ({
  type: DASHBOARD_CARRIER_GRAPH,
  data,
});
export const SUCCESS_DASHBOARD_CARRIER_GRAPH =
  "SUCCESS_DASHBOARD_CARRIER_GRAPH";
export const ERROR_DASHBOARD_CARRIER_GRAPH = "ERROR_DASHBOARD_CARRIER_GRAPH";
export const dashboardCarrierGraphResponse = (type, data) => ({ type, data });
