import {
 SUCCESS_LIST_USERS_ROLES,
 ERROR_LIST_USERS_ROLES,
 SUCCESS_LIST_USERS_ROLES_ALL,
 ERROR_LIST_USERS_ROLES_ALL,
 SUCCESS_ADD_USERS_ROLE,
 ERROR_ADD_USERS_ROLE,
 SUCCESS_EDIT_USERS_ROLE,
 ERROR_EDIT_USERS_ROLE,
 SUCCESS_DELETE_USERS_ROLE,
 ERROR_DELETE_USERS_ROLE,
 SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE,
 ERROR_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE,
 SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_BATCH,
 ERROR_USERS_ROLE_ACCESS_MANAGEMENT_BATCH,
 SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_LIST,
 ERROR_USERS_ROLE_ACCESS_MANAGEMENT_LIST,
 SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY,
 ERROR_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY
} from "./manageAccess.action";
import { DEFAULT_STATE } from "./manageAccess.state";

export const manageAccessReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_LIST_USERS_ROLES:
       const listUsersRolesData = action.data;
        return { ...state, listUsersRolesData };
    case ERROR_LIST_USERS_ROLES:
      const errorListUsersRolesData = action.data;
      return { ...state, listUsersRolesData: errorListUsersRolesData };

    case SUCCESS_LIST_USERS_ROLES_ALL:
       const listUsersRolesAllData = action.data;
        return { ...state, listUsersRolesAllData };
    case ERROR_LIST_USERS_ROLES_ALL:
      const errorListUsersRolesAllData = action.data;
      return { ...state, listUsersRolesAllData: errorListUsersRolesAllData };

    case SUCCESS_ADD_USERS_ROLE:
        const addUsersRoleData = action.data;
         return { ...state, addUsersRoleData };
    case ERROR_ADD_USERS_ROLE:
       const errorAddUsersRoleData = action.data;
       return { ...state, addUsersRoleData: errorAddUsersRoleData };

    case  SUCCESS_EDIT_USERS_ROLE:
        const editUsersRoleData = action.data;
         return { ...state, editUsersRoleData };
    case ERROR_EDIT_USERS_ROLE:
       const errorEditUsersRoleData = action.data;
       return { ...state, editUsersRoleData: errorEditUsersRoleData };

   case SUCCESS_DELETE_USERS_ROLE:
        const deleteUsersRoleData = action.data;
         return { ...state, deleteUsersRoleData };
    case ERROR_DELETE_USERS_ROLE:
       const errorDeleteUsersRoleData = action.data;
       return { ...state, deleteUsersRoleData: errorDeleteUsersRoleData };

    case SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE:
        const usersRoleAccessManagementSingleData = action.data;
         return { ...state, usersRoleAccessManagementSingleData };
    case ERROR_USERS_ROLE_ACCESS_MANAGEMENT_SINGLE:
       const errorUsersRoleAccessManagementSingleData = action.data;
       return { ...state, usersRoleAccessManagementSingleData:errorUsersRoleAccessManagementSingleData };

    case SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_BATCH:
        const usersRoleAccessManagementBatchData = action.data;
         return { ...state, usersRoleAccessManagementBatchData };
    case ERROR_USERS_ROLE_ACCESS_MANAGEMENT_BATCH:
       const errorUsersRoleAccessManagementBatchData = action.data;
       return { ...state, usersRoleAccessManagementSingleData:errorUsersRoleAccessManagementBatchData };
    
    case SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_LIST:
        const usersRoleAccessManagementListData = action.data;
         return { ...state, usersRoleAccessManagementListData };
    case ERROR_USERS_ROLE_ACCESS_MANAGEMENT_LIST:
       const errorUsersRoleAccessManagementListData = action.data;
       return { ...state, usersRoleAccessManagementSingleData:errorUsersRoleAccessManagementListData };
    
    case SUCCESS_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY:
        const usersRoleAccessManagementVerifyData = action.data;
         return { ...state, usersRoleAccessManagementVerifyData };
    case ERROR_USERS_ROLE_ACCESS_MANAGEMENT_VERIFY:
       const errorUsersRoleAccessManagementVerifyData = action.data;
       return { ...state, usersRoleAccessManagementVerifyData:errorUsersRoleAccessManagementVerifyData };
    
    

       
    default:
      return state;
  }
};
