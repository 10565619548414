import { request } from "../request/axios.request";
import {
  CSV_IMPORT_INSERT_CARRIER,
  INSERT_CARRIER_DATA,
  CSV_IMPORT_INVALID_EMAIL_CARRIER,
  CSV_IMPORT_EQUIPMENT_TYPE,
  GET_MC_DOT_LIST,
  IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
  IMPORT_VALID_LANES,
  IMPORT_VALID_LANES_DATA,
  ORIGINS_LIST_CARRIER_LANES,
  DESTINATION_LIST_CARRIER_LANES,
  IMPORT_REPLACEMENT_EMAIL,
  INSERT_EQUIPMENT_LIST,
  INSERT_DATASOURCE,
} from "../routing/route";
import { Tokens } from "../../app/storage/index";

export async function insertcarrierdataApi(data) {
  let insertcarrierdatadata = data.data;
  return request({
    url: INSERT_CARRIER_DATA,
    method: "post",
    data: insertcarrierdatadata,
  });
}
export async function csvimportinsertcarrierApi(data) {
  let csvimportinsertcarrierdata = data.data;
  let formData = new FormData();
  formData.append("file", csvimportinsertcarrierdata.file);

  return request({
    url: CSV_IMPORT_INSERT_CARRIER,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
        }`,
    },
  });
}

// INVALID EMAIL DISPATCH EMAIL START HERE

export async function csvimportinvalidcarrierApi(data) {
  let csvimportinvalidcarrierdata = data.data;
  let formData = new FormData();
  formData.append("file", csvimportinvalidcarrierdata.file);
  formData.append("type", csvimportinvalidcarrierdata.type);

  return request({
    url: CSV_IMPORT_INVALID_EMAIL_CARRIER,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
        }`,
    },
  });
}

// INVALID EMAIL DISPATCH EMAIL END HERE

// CSV_IMPORT_EQUIPMENT_TYPE START HERE

export async function csvimportequipmenttypeApi(data) {
  let csvimportequipmenttypedata = data.data;
  let formData = new FormData();
  formData.append("file", csvimportequipmenttypedata.file);

  return request({
    url: CSV_IMPORT_EQUIPMENT_TYPE,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
        }`,
    },
  });
}

// CSV_IMPORT_EQUIPMENT_TYPE END HERE

// GET_MC_DOT_LIST START HERE

export async function mcdotlistgetApi(data) {
  let csvimportinvalidcarrierdata = data.data;

  return request({
    url: GET_MC_DOT_LIST,
    method: "get",
    params: csvimportinvalidcarrierdata,
  });
}

// GET_MC_DOT_LIST END HERE

// IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION START HERE

export async function importinvalidcarrierorigindestinationApi(data) {
  let importinvalidcarrierorigindestinationdata = data.data;
  let formData = new FormData();
  formData.append("file", importinvalidcarrierorigindestinationdata.file);

  return request({
    url: IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
        }`,
    },
  });
}

// IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION END HERE

// IMPORT_VALID_LANES START HERE
export async function importvalidlanesApi(data) {
  let importinvalidcarrierorigindestinationdata = data.data;
  let formData = new FormData();
  formData.append("file", importinvalidcarrierorigindestinationdata.file);

  return request({
    url: IMPORT_VALID_LANES,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
        }`,
    },
  });
}
// IMPORT_VALID_LANES END HERE

// IMPORT_VALID_LANES_DATA Start HERE

export async function insertvalidlanesdataApi(data) {
  let insertvalidlanesdata = data.data;
  return request({
    url: IMPORT_VALID_LANES_DATA,
    method: "post",
    data: insertvalidlanesdata,
  });
}
// IMPORT_VALID_LANES_DATA END HERE

// IMPORT REPLACEMENT EMAIL START HERE
export async function importReplacementEmailApi(data) {
  let importReplacementEmailData = data.data;
  let formData = new FormData();
  formData.append("file", importReplacementEmailData.file);

  return request({
    url: IMPORT_REPLACEMENT_EMAIL,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
        }`,
    },
  });
}

// IMPORT REPLACEMENT EMAIL ENDS HERE

//ORIGIN_SEARCH_LIST
export async function getOriginSearchListcarrierlanesApi(data) {
  let getOriginSearchListData = data.data;
  return request({
    url: ORIGINS_LIST_CARRIER_LANES,
    method: "get",
    params: getOriginSearchListData,
  });
}

//DESTINATION_SEARCH_LIST
export async function getDestinationSearchListcarrierlanesApi(data) {
  let getDestinationSearchListData = data.data;
  return request({
    url: DESTINATION_LIST_CARRIER_LANES,
    method: "get",
    params: getDestinationSearchListData,
  });
}

// equipment type 
export async function insertEquipmentTypeListApi(data) {
  let insertEquipmentTypeData = data.data
  return request({
    url: INSERT_EQUIPMENT_LIST,
    method: "get",
    params: insertEquipmentTypeData
  })
}
//DATA SOURCE 
export async function insertDataSourceApi(data) {
  let insertDataSourceData = data.data
  return request({
    url: INSERT_DATASOURCE,
    method: "get",
    params: insertDataSourceData
  })
}

