export const DEFAULT_STATE = {
    submitCsvRfpData: {},
    equipmentTypeData: {},
    saveManualRfpData: {},
    analyzerResultTableData: {},
    analyzerExportData: {},
    pendingCalculationData: {},
    saveRfpControlData: {},
    savedRfpListData: {},
    deleteRfpData: {},
    applyRfpData: {},
    refreshRfpCalculationData:{},
    updatedRfpListData: {},
    rfpDownLoadAlertData:{},
    saveRangeRfpControlData: {},
}
