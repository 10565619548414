import { request } from "../request/axios.request";
import {
    EQUIPMENT_TYPE_LIST_FOR_POST_LOAD,
    ADD_LOAD_ORIGINS_LIST,
    ADD_LOAD_DESTINATION_LIST,
    SEARCH_BY_ORDER_ID,
    ADD_POST_STOP_LIST,
    SAVE_LOAD_DETAILS,
    ALL_LOAD_LIST,
    SINGLE_LOAD_DETAILS,
    DELETE_LOAD,
    UPDATE_LOAD
} from "../routing/route"

// to get the equipment-type list
export async function postLoadEquipmentTypeListApi(data) {
    let postLoadEquipmentTypeList = data.data;
    return request({
        url: EQUIPMENT_TYPE_LIST_FOR_POST_LOAD,
        method: "get",
        params: postLoadEquipmentTypeList
    })
}
//to get the origins list
export async function addLoadOriginListApi(data) {
    let addLoadOriginList = data.data
    return request({
        url: ADD_LOAD_ORIGINS_LIST,
        method: "get",
        params: addLoadOriginList
    })
}

// to get the destination list
export async function addLoadDestinationListApi(data) {
    let addLoadDestinationList = data.data
    return request({
        url: ADD_LOAD_DESTINATION_LIST,
        method: "get",
        params: addLoadDestinationList
    })
}

// SEARCH BY ORDER ID
export async function searchByOrderIdApi(data) {
    let searchByOrderIdList = data.data
    return request({
        url: SEARCH_BY_ORDER_ID,
        method: "get",
        params: searchByOrderIdList
    })
}

// TO GET THE STOP LIST
export async function addLoadStopListApi(data) {
    let addLoadStopList = data.data
    return request({
        url: ADD_POST_STOP_LIST,
        method: "get",
        params: addLoadStopList
    })

}
// SAVE LOAD
export async function saveLoadDetailsApi(data) {
    let saveLoadDetailData = data.data
    return request({
        url: SAVE_LOAD_DETAILS,
        method: "post",
        data: saveLoadDetailData
    })
}
// GET ALL LOADS
export async function allLoadListApi(data) {
    let allLoadListData = data.data
    return request({
        url: ALL_LOAD_LIST,
        method: "get",
        data: allLoadListData
    })
}
// single load details
export async function singleLoadDetailsApi(data) {
    let singleLoadDetailsData = data.data
    return request({
        url: SINGLE_LOAD_DETAILS,
        method: "get",
        params: singleLoadDetailsData
    })
}
//DELETE LOAD 
export async function deleteLoadApi(data) {
    let deleteLoaddata = data.data
    return request({
        url: DELETE_LOAD,
        method: "post",
        params: deleteLoaddata
    })
}
// UPdate Load
export async function updateLoadApi(data) {
    let updateLoadData = data.data
    return request({
        url: UPDATE_LOAD,
        method: "post",
        data: updateLoadData
    })
}