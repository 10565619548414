import { put, takeLatest } from "redux-saga/effects";
import _ from "lodash";
import {
    EQUIPMENT_TYPE_LIST,
    SUCCESS_EQUIPMENT_TYPE_LIST,
    ERROR_EQUIPMENT_TYPE_LIST,
    equipmentTypeListResponse,
    ADD_LOAD_DESTINATION_LIST,
    SUCCESS_ADD_LOAD_DESTINATION_LIST,
    ERROR_ADD_LOAD_DESTINATION_LIST,
    addLoadDestinationListResponse,
    ADD_LOAD_ORIGIN_LIST,
    SUCCESS_ADD_LOAD_ORIGIN_LIST,
    ERROR_ADD_LOAD_ORIGIN_LIST,
    addLoadOriginListResponse,
    SEARCH_BY_ORDER_ID,
    SUCCESS_SEARCH_BY_ORDER_ID,
    ERROR_SEARCH_BY_ORDER_ID,
    searchByOrderIdListResponse,
    ADD_LOAD_STOP_LIST,
    SUCCESS_ADD_LOAD_STOP_LIST,
    ERROR_ADD_LOAD_STOP_LIST,
    addLoadStopListResponse,
    SUCCESS_SAVE_LOAD_DETAILS,
    ERROR_SAVE_LOAD_DETAILS,
    saveLoadDetailsListResponse,
    SAVE_LOAD_DETAILS,
    allLoadListResponse,
    SUCCESS_ALL_LOAD_LIST,
    ERROR_ALL_LOAD_LIST,
    ALL_LOAD_LIST,
    singleLoadDetailsResponse,
    SUCCESS_SINGLE_LOAD_DETAILS,
    ERROR_SINGLE_LOAD_DETAILS,
    SINGLE_LOAD_DETAILS,
    deleteLoadResponse,
    SUCCESS_DELETE_LOAD,
    ERROR_DELETE_LOAD,
    DELETE_LOAD,
    updateLoadResponse,
    SUCCESS_UPDATE_LOAD,
    ERROR_UPDATE_LOAD,
    UPDATE_LOAD,

} from "./postLoad.actions"
import {
    postLoadEquipmentTypeListApi,
    addLoadDestinationListApi,
    addLoadOriginListApi,
    searchByOrderIdApi,
    addLoadStopListApi,
    saveLoadDetailsApi,
    allLoadListApi,
    singleLoadDetailsApi,
    deleteLoadApi,
    updateLoadApi
} from "../../../api/sdk/postLoad"

function* equipmentTypeList(data) {
    let equipmentTypeListData = yield postLoadEquipmentTypeListApi(data);
    if (equipmentTypeListData.success && _.has(equipmentTypeListData, "data.data")) {
        yield put(
            equipmentTypeListResponse(
                SUCCESS_EQUIPMENT_TYPE_LIST,
                equipmentTypeListData.data
            )
        );
    } else {
        yield put(
            equipmentTypeListResponse(
                ERROR_EQUIPMENT_TYPE_LIST,
                equipmentTypeListData.data
            )
        );
    }
}
export function* equipmentTypeListWatcher() {
    yield takeLatest(EQUIPMENT_TYPE_LIST, equipmentTypeList)
}

// to get the destination list
function* addLoadDestinationList(data) {
    let addLoadDestinationListData = yield addLoadDestinationListApi(data);
    if (addLoadDestinationListData.success && _.has(addLoadDestinationListData, "data.data")) {
        yield put(
            addLoadDestinationListResponse(SUCCESS_ADD_LOAD_DESTINATION_LIST, addLoadDestinationListData)
        );

    } else {
        yield put(
            addLoadDestinationListResponse(ERROR_ADD_LOAD_DESTINATION_LIST, addLoadDestinationListData)
        )
    }
}
export function* addLoadDestinationListWatcher() {
    yield takeLatest(ADD_LOAD_DESTINATION_LIST, addLoadDestinationList)
}
//to get the orign list
function* addLoadOriginList(data) {
    let addLoadOriginListData = yield addLoadOriginListApi(data);
    if (addLoadOriginListData.success && _.has(addLoadOriginListData, "data.data")) {
        yield put(
            addLoadOriginListResponse(
                SUCCESS_ADD_LOAD_ORIGIN_LIST, addLoadOriginListData
            )
        );
    } else {
        yield put(
            addLoadOriginListResponse(
                ERROR_ADD_LOAD_ORIGIN_LIST, _.has(addLoadOriginListData, "data.data")
            )
        )
    }
}
export function* addLoadOriginListWatcher() {
    yield takeLatest(ADD_LOAD_ORIGIN_LIST, addLoadOriginList)
}

// search by load
function* searchByOrderId(data) {
    let searchByOrderIdData = yield searchByOrderIdApi(data)
    if (searchByOrderIdData.success && _.has(searchByOrderIdData, "data.data")) {
        yield put(
            searchByOrderIdListResponse(SUCCESS_SEARCH_BY_ORDER_ID, searchByOrderIdData)
        )
    } else {
        yield put(
            searchByOrderIdListResponse(ERROR_SEARCH_BY_ORDER_ID, searchByOrderIdData)
        )
    }
}
export function* searchByOrderIdWatcher() {
    yield takeLatest(SEARCH_BY_ORDER_ID, searchByOrderId)
}

// to get the stop list 
function* addLoadStopList(data) {
    let addLoadStopListData = yield (addLoadStopListApi(data));
    if (addLoadStopListData.success && _.has(addLoadStopListData, "data.data")) {
        yield put(
            addLoadStopListResponse(SUCCESS_ADD_LOAD_STOP_LIST, addLoadStopListData)
        )
    } else {
        yield put(
            addLoadStopListResponse(ERROR_ADD_LOAD_STOP_LIST, addLoadStopListData)
        )
    }
}
export function* addLoadStopListWatcher() {
    yield takeLatest(ADD_LOAD_STOP_LIST, addLoadStopList)
}
//save load  details
function* saveLoadDetails(data) {
    let saveLoadDetailsData = yield (saveLoadDetailsApi(data));
    if (saveLoadDetailsData.success && _.has(saveLoadDetailsData, "data.data")) {
        yield put(saveLoadDetailsListResponse(SUCCESS_SAVE_LOAD_DETAILS, saveLoadDetailsData.data))
    } else {
        yield put(saveLoadDetailsListResponse(ERROR_SAVE_LOAD_DETAILS, saveLoadDetailsData.data))
    }
}
export function* saveLoadDetailsWatcher() {
    yield takeLatest(SAVE_LOAD_DETAILS, saveLoadDetails)
}

// get all load list data
function* allLoadList(data) {
    let allLoadListData = yield (allLoadListApi(data))
    if (allLoadListData.success && _.has(allLoadListData, "data.data")) {
        yield put(allLoadListResponse(SUCCESS_ALL_LOAD_LIST, allLoadListData.data))
    } else {
        yield put(allLoadListResponse(ERROR_ALL_LOAD_LIST, allLoadListData.data))
    }
}
export function* allLoadListWatcher() {
    yield takeLatest(ALL_LOAD_LIST, allLoadList)
}
// single load details 
function* singleLoadDetails(data) {
    let singleLoadDetailsData = yield (singleLoadDetailsApi(data))
    if (singleLoadDetailsData && _.has(singleLoadDetailsData, "data.data")) {
        yield put(singleLoadDetailsResponse(SUCCESS_SINGLE_LOAD_DETAILS, singleLoadDetailsData.data)
        )
    } else {
        yield put(singleLoadDetailsResponse(ERROR_SINGLE_LOAD_DETAILS, singleLoadDetailsData.data))
    }
}
export function* singleLoadDetailsWatcher() {
    yield takeLatest(SINGLE_LOAD_DETAILS, singleLoadDetails)
}
// delete load 
function* deleteLoadDetails(data) {
    let deleteLoadDetailsData = yield (deleteLoadApi(data))
    if (deleteLoadDetailsData && _.has(deleteLoadDetailsData, "data.data")) {
        yield put(deleteLoadResponse(SUCCESS_DELETE_LOAD, deleteLoadDetailsData.data))
    } else {
        yield put(deleteLoadResponse(ERROR_DELETE_LOAD, deleteLoadDetailsData.data))
    }
}
export function* deleteLoadDetailsWatcher() {
    yield takeLatest(DELETE_LOAD, deleteLoadDetails)
}

// UPDATE LOAD 
function* updateLoadDetails(data) {
    let updateLoadDetails = yield updateLoadApi(data)
    if (updateLoadDetails && _.has(updateLoadDetails, "data")) {
        yield put(updateLoadResponse(SUCCESS_UPDATE_LOAD, updateLoadDetails.data))
    }
    else {
        yield put(updateLoadResponse(ERROR_UPDATE_LOAD, updateLoadDetails.data))
    }

}
export function* updateLoadDetailsWatcher(data) {
    yield takeLatest(UPDATE_LOAD, updateLoadDetails)
}