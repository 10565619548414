import {
  SUCCESS_DASHBOARD_CARRIER_GRAPH,
  ERROR_DASHBOARD_CARRIER_GRAPH,
  SUCCESS_CARRIER_RATE_GRAPH,
  ERROR_CARRIER_RATE_GRAPH,
  SUCCESS_EQUIPMENT_TYPE_GRAPH,
  ERROR_EQUIPMENT_TYPE_GRAPH,
  SUCCESS_CARRIER_COUNT_GRAPH,
  ERROR_CARRIER_COUNT_GRAPH,
} from "./graphs.action";
import { DEFAULT_STATE } from "./graphs.state";

export const graphsReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_DASHBOARD_CARRIER_GRAPH:
      const dashboardCarrierGraphData = action.data;
      return { ...state, dashboardCarrierGraphData };
    case ERROR_DASHBOARD_CARRIER_GRAPH:
      const errorDashboardCarrierGraphData = action.data;
      return {
        ...state,
        dashboardCarrierGraphData: errorDashboardCarrierGraphData,
      };
    case SUCCESS_CARRIER_RATE_GRAPH:
      const carrierRateGraphData = action.data;
      return { ...state, carrierRateGraphData };
    case ERROR_CARRIER_RATE_GRAPH:
      const errorCarrierRateGraphData = action.data;
      return { ...state, carrierRateGraphData: errorCarrierRateGraphData };
    case SUCCESS_EQUIPMENT_TYPE_GRAPH:
      const equipmentTypeGraphData = action.data;
      return { ...state, equipmentTypeGraphData };
    case ERROR_EQUIPMENT_TYPE_GRAPH:
      const errorEquipmentTypeGraphData = action.data;
      return { ...state, equipmentTypeGraphData: errorEquipmentTypeGraphData };
    case SUCCESS_CARRIER_COUNT_GRAPH:
      const carrierCountGraphData = action.data;
      return { ...state, carrierCountGraphData };
    case ERROR_CARRIER_COUNT_GRAPH:
      const errorCarrierCountGraphData = action.data;
      return { ...state, carrierCountGraphData: errorCarrierCountGraphData };

    default:
      return state;
  }
};
