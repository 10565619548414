//  get the equipment type list
export const EQUIPMENT_TYPE_LIST = "EQUIPMENT_TYPE_LIST";
export const equipmentTypeList = (data) => ({ type: EQUIPMENT_TYPE_LIST, data });
export const SUCCESS_EQUIPMENT_TYPE_LIST = "SUCCESS_EQUIPMENT_TYPE_LIST";
export const ERROR_EQUIPMENT_TYPE_LIST = "ERROR_EQUIPMENT_TYPE_LIST";
export const equipmentTypeListResponse = (type, data) => ({ type, data })

// get the origins list
export const ADD_LOAD_ORIGIN_LIST = "ADD_LOAD_ORIGIN_LIST";
export const addLoadOriginList = (data) => ({ type: ADD_LOAD_ORIGIN_LIST, data })
export const SUCCESS_ADD_LOAD_ORIGIN_LIST = "SUCCESS_ADD_LOAD_ORIGIN_LIST";
export const ERROR_ADD_LOAD_ORIGIN_LIST = "ERROR_ADD_LOAD_ORIGIN_LIST";
export const addLoadOriginListResponse = (type, data) => ({ type, data })

// to get the destination list
export const ADD_LOAD_DESTINATION_LIST = "ADD_LOAD_DESTINATION_LIST";
export const addLoadDestinationList = (data) => ({ type: ADD_LOAD_DESTINATION_LIST, data });
export const SUCCESS_ADD_LOAD_DESTINATION_LIST = "SUCCESS_ADD_LOAD_DESTINATION_LIST";
export const ERROR_ADD_LOAD_DESTINATION_LIST = "ERROR_ADD_LOAD_DESTINATION_LIST";
export const addLoadDestinationListResponse = (type, data) => ({ type, data })

//search by order id
export const SEARCH_BY_ORDER_ID = "SEARCH_BY_ORDER_ID"
export const searchByOrderIdList = (data) => ({ type: SEARCH_BY_ORDER_ID, data });
export const SUCCESS_SEARCH_BY_ORDER_ID = "SUCCESS_SEARCH_BY_ORDER_ID";
export const ERROR_SEARCH_BY_ORDER_ID = "ERROR_SEARCH_BY_ORDER_ID";
export const searchByOrderIdListResponse = (type, data) => ({ type, data })

// to get the list for stop
export const ADD_LOAD_STOP_LIST = "ADD_LOAD_STOP_LIST"
export const addLoadStopList = (data) => ({ type: ADD_LOAD_STOP_LIST, data })
export const SUCCESS_ADD_LOAD_STOP_LIST = "SUCCESS_ADD_LOAD_STOP_LIST"
export const ERROR_ADD_LOAD_STOP_LIST = "ERROR_ADD_LOAD_STOP_LIST"
export const addLoadStopListResponse = (type, data) => ({ type, data })

// save load details 
export const SAVE_LOAD_DETAILS = "SAVE_LOAD_DETAILS";
export const saveLoadDetailsList = (data) => ({ type: SAVE_LOAD_DETAILS, data });
export const SUCCESS_SAVE_LOAD_DETAILS = "SUCCESS_SAVE_LOAD_DETAILS";
export const ERROR_SAVE_LOAD_DETAILS = "ERROR_SAVE_LOAD_DETAILS";
export const saveLoadDetailsListResponse = (type, data) => ({ type, data })

// all load list 
export const ALL_LOAD_LIST = "ALL_LOAD_LIST";
export const allLoadList = (data) => ({ type: ALL_LOAD_LIST, data });
export const SUCCESS_ALL_LOAD_LIST = "SUCCESS_ALL_LOAD_LIST";
export const ERROR_ALL_LOAD_LIST = "ERROR_ALL_LOAD_LIST";
export const allLoadListResponse = (type, data) => ({ type, data });

// single load details
export const SINGLE_LOAD_DETAILS = "SINGLE_LOAD_DETAILS";
export const singleLoadDetails = (data) => ({ type: SINGLE_LOAD_DETAILS, data })
export const SUCCESS_SINGLE_LOAD_DETAILS = "SUCCESS_SINGLE_LOAD_DETAILS";
export const ERROR_SINGLE_LOAD_DETAILS = "ERROR_SINGLE_LOAD_DETAILS";
export const singleLoadDetailsResponse = (type, data) => ({ type, data })

// delete load
export const DELETE_LOAD = "DELETE_LOAD";
export const deleteLoad = (data) => ({ type: DELETE_LOAD, data })
export const SUCCESS_DELETE_LOAD = "SUCCESS_DELETE_LOAD";
export const ERROR_DELETE_LOAD = "ERROR_DELETE_LOAD"
export const deleteLoadResponse = (type, data) => ({ type, data })

// update load 
export const UPDATE_LOAD = "UPDATE_LOAD";
export const updateLoad = (data) => ({ type: UPDATE_LOAD, data,  })
export const SUCCESS_UPDATE_LOAD = "SUCCESS_UPDATE_LOAD";
export const ERROR_UPDATE_LOAD = "ERROR_UPDATE_LOAD";
export const updateLoadResponse = (type, data) => ({ type, data })