import { put, takeLatest } from "redux-saga/effects";

import {
  CUSTOM_NOTIFICATION_LIST,
  SUCCESS_CUSTOM_NOTIFICATION_LIST,
  ERROR_CUSTOM_NOTIFICATION_LIST,
  customNotificationListResponse,
  // LATEST_NOTIFICATION_LIST, SUCCESS_LATEST_NOTIFICATION_LIST, ERROR_LATEST_NOTIFICATION_LIST, latestNotificationListResponse
} from "./notification.actions";

import _ from "lodash";
import {
  customNotificationListApi,
  // latestNotificationListApi
} from "../../../api/sdk/notification";

function* customNotificationListRequest(data) {
  let getData = yield customNotificationListApi(data);
  if (getData.success && _.has(getData, "data.data")) {
    yield put(
      customNotificationListResponse(
        SUCCESS_CUSTOM_NOTIFICATION_LIST,
        getData.data
      )
    );
    // message.success(getData.data.message)
  } else {
    yield put(
      customNotificationListResponse(
        ERROR_CUSTOM_NOTIFICATION_LIST,
        getData.data
      )
    );
    //message.error(getData.data.message)
  }
}
export function* customNotificationListWatcher() {
  yield takeLatest(CUSTOM_NOTIFICATION_LIST, customNotificationListRequest);
}

// function* latestNotificationListRequest(data) {
//     let getData = yield latestNotificationListApi(data);
//     if (getData.success && _.has(getData, 'data.data')) {
//         yield put(latestNotificationListResponse(SUCCESS_LATEST_NOTIFICATION_LIST, getData.data));
//         // message.success(getData.data.message)
//     } else {
//         yield put(latestNotificationListResponse(ERROR_LATEST_NOTIFICATION_LIST, getData.data));
//         //message.error(getData.data.message)
//     }
// }
// export function* latestNotificationListWatcher() {
//     yield takeLatest(LATEST_NOTIFICATION_LIST, latestNotificationListRequest);
// }
