import { request } from "../request/axios.request";
import {
  Manage_Staff_New_User_Store,
  RESET_PASSWORD_MANAGE_STAFF,
  CHECK_USER_EMAIL,
  STAFF_GET_PERMISSION,
  STAFF_SET_PERMISSION,
  Manage_Staff_Edit_User_Store
} from "../routing/route";

//add new user
export async function newUserManageStaffApi(data) {
  let userData = data.data;
  return request({
    url: Manage_Staff_New_User_Store,
    method: "post",
    data: userData,
  });
}
// //reset password
// export async function resetPassworManageStaffdApi(data) {
//     let resetPassData = data.data;
//     return request({ url: RESET_PASSWORD_MANAGE_STAFF, method: 'post', data: resetPassData })
// }
//reset password
export async function resetPassworManageStaffdApi(data) {
  let passData = data.data;
  return request({
    url: RESET_PASSWORD_MANAGE_STAFF,
    method: "post",
    data: passData,
  });
}

export async function checkUserEmailApi(data) {
  let checkUserEmailData = data.data;
  return request({
    url: CHECK_USER_EMAIL,
    params: checkUserEmailData,
    method: "get",
  });
}

export async function staffsetpermissionApi(data) {
  let staffsetpermissiondata = data.data;
  return request({
    url: STAFF_SET_PERMISSION,
    method: "post",
    data: staffsetpermissiondata,
  });
}

export async function staffgetpermissionApi(data) {
  let staffgetpermissiondata = data.data;
  return request({
    url: STAFF_GET_PERMISSION,
    method: "get",
    params: staffgetpermissiondata,
  });
}

//edit user profile
export async function editUserManageStaffApi(data) {
  let userData = data.data;
  return request({
    url: Manage_Staff_Edit_User_Store,
    method: "post",
    data: userData,
  });
}

// export async function staffpermissionApi(data) {
//     let staffpermissiondata = data.data;
//     return request({ url: STAFF_PERMISSION, method: 'get', data : staffpermissiondata })
// }
