import {
  COPY_SHORTCODE,
  ADD_NEW_TEMPLATE,
  TEMPLATE_LIST,
  EDIT_EMAIL_TEMPLATE,
  DELETE_EMAIL_TEMPLATE,
  SEND_EMAIL,
  CHECK_TEMPLATE_NAME,
  MY_EMAILS_LIST,
  EMAIL_STATS,
  SEND_SELECTED_FILE,
  MY_SUPPORT_EMAILS_LIST,
  MY_DOWNLOAD_EMAILS_LIST,
  MY_UNSUBSCRIBE_EMAILS_LIST,
  DELETE_UNSUBSCRIBE_EMAILS
} from "../routing/route";

import { Tokens } from "../../app/storage/index"
import { request } from "../request/axios.request";
import _ from "lodash";

export async function copyShortCodeApi() {
  return request({ url: COPY_SHORTCODE, method: "get" });
}

export async function addNewTemplateApi(data) {
  let addNewTemplateData = data.data;
  return request({
    url: ADD_NEW_TEMPLATE,
    method: "post",
    data: addNewTemplateData,
  });
}

export async function templateListApi(data) {
  let templateListData = data.data;
  return request({
    url: TEMPLATE_LIST,
    method: "get",
    params: templateListData,
  });
}

export async function editEmailTemplateApi(data) {
  let editEmailTemplateData = data.data;
  return request({
    url: EDIT_EMAIL_TEMPLATE,
    method: "post",
    data: editEmailTemplateData,
  });
}

export async function deleteEmailTemplateApi(data) {
  let deleteEmailTemplateData = data.data;
  return request({
    url: DELETE_EMAIL_TEMPLATE,
    params: deleteEmailTemplateData,
    method: "delete",
  });
}
export async function sendEmailApi(data) {
  let emailData = data.data;
  let formData = new FormData();
  let attachments=[];
  if(emailData.attachments !== undefined){
    attachments=emailData.attachments;
  }
  formData.append("attachment_length",attachments.length)
  _.each(attachments,function(file, key){
    formData.append("attachment_"+key, file)
  })
  _.each(emailData,function(data, key){
    if(key!=='attachments'){
      formData.append(key, data)
    }
  })

  // export async function sendEmailApi(data) {
  // let sendEmailData = data.data;
  // return request({ url: SEND_EMAIL, method: "post", data: sendEmailData })
  // }

  return request({
      url: SEND_EMAIL,
      method: "post",
      data: formData,
      headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${Tokens.getToken() ? Tokens.getToken() : Tokens.getVerifyToken()
              }`,
      }
  })
}

export async function checkTemplateNameApi(data) {
  let checkTemplateNameData = data.data;
  return request({
    url: CHECK_TEMPLATE_NAME,
    params: checkTemplateNameData,
    method: "get",
  });
}
export async function myEmailsListApi(data) {
  let myEmailsData = data.data;
  return request({ url: MY_EMAILS_LIST, params: myEmailsData, method: "get" });
}
export async function mySupportEmailsListApi(data) {
  let mySupportEmailsData = data.data;
  return request({ url: MY_SUPPORT_EMAILS_LIST, params: mySupportEmailsData, method: "get" });
}
export async function emailStatsApi() {
  return request({ url: EMAIL_STATS, method: "get" });
}
// selected file
export async function selectedfileApi(data) {
  let selectedFile = data.data;
  return request({
    url: SEND_SELECTED_FILE,
    method: "post",
    data: selectedFile,
  });
}

export async function myDownloadEmailsListApi(data) {
  let myDownloadEmailsData = data.data;
  return request({ url: MY_DOWNLOAD_EMAILS_LIST, params: myDownloadEmailsData, method: "get" });
}

export async function myUnsubscribeEmailsListApi(data) {
  let myUnsubscribeEmailsData = data.data;
  return request({ url: MY_UNSUBSCRIBE_EMAILS_LIST, params: myUnsubscribeEmailsData, method: "get" });
}

export async function deleteUnsubscribeEmailsApi(data) {
  let deleteUnsubscribeEmailsData = data.data;
  return request({
    url: DELETE_UNSUBSCRIBE_EMAILS,
    params: deleteUnsubscribeEmailsData,
    method: "delete",
  });
}
