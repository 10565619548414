import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import AllRoutes from './app/routing/route'
import history from './app/routing/history'
import { Provider } from 'react-redux';
import { store } from './app/store/store.factory';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './app/assets/css/theme-vendors.min.css';
import './app/assets/css/theme-materialize.min.css';
import './app/assets/css/theme-style.min.css';
import './app/assets/css/bootstrap-select.min.css';
import 'antd/dist/antd.css';
import './app/assets/scss/app-dash-style.scss';
import './app/assets/scss/main.scss';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="">
      <Provider store={store}>
        <Router history={history}>
          <AllRoutes />
        </Router>
      </Provider>
    </Suspense>
  </React.StrictMode>,
 
    document.getElementById('root')

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
