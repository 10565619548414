import {
  SUCCESS_REGISTRATION,
  ERROR_REGISTRATION,
  ERROR_LOGIN,
  SUCCESS_LOGIN,
  SUCCESS_MANAGESTAFFACCESS,
  ERROR_MANAGESTAFFACCESS,
  SUCCESS_VERIFYRMAIL,
  ERROR_VERIFYRMAIL,
  SUCCESS_FORGOTPASSWORD,
  ERROR_FORGOTRESETPASSWORD,
  SUCCESS_RESETPASSWORD,
  ERROR_RESETPASSWORD,
  SUCCESS_USER_LOGOUT,
  ERROR_USER_LOGOUT,
  SUCCESS_EMAILVERIFICATION,
  ERROR_EMAILVERIFICATION,
  SUCCESS_LINKEXPIREATION,
  ERROR_LINKEXPIREATION,
  SUCCESS_USERVERIFYRMAIL,
  ERROR_USERVERIFYRMAIL,
  SUCCESS_OTPVERIFICATION,
  ERROR_OTPVERIFICATION,
  SUCCESS_OTPRESEND,
  ERROR_OTPRESEND
} from "./auth.action";
import { DEFAULT_STATE } from "./auth.state";

export const authReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_REGISTRATION:
      const registerData = action.data;
      return { ...state, registerData };
    case ERROR_REGISTRATION:
      const errorRegisterData = action.data;
      return { ...state, registerData: errorRegisterData };

    case SUCCESS_LOGIN:
      const loginData = action.data;
      return { ...state, loginData };
    case ERROR_LOGIN:
      const errorLoginData = action.data;
      return { ...state, loginData: errorLoginData };

    case SUCCESS_USER_LOGOUT:
      const logoutData = action.data;
      return { ...state, loginData: {}, logoutData };
    case ERROR_USER_LOGOUT:
      const errorLogoutData = action.data;
      return { ...state, loginData: {}, logoutData: errorLogoutData };

    case SUCCESS_FORGOTPASSWORD:
      const forgotPasswordData = action.data;
      return { ...state, forgotPasswordData };
    case ERROR_FORGOTRESETPASSWORD:
      const errorforgotPasswordData = action.data;
      return { ...state, forgotPasswordData: errorforgotPasswordData };
    case SUCCESS_RESETPASSWORD:
      const resetPasswordData = action.data;
      return { ...state, resetPasswordData };
    case ERROR_RESETPASSWORD:
      const errorResetPasswordData = action.data;
      return { ...state, resetPasswordData: errorResetPasswordData };
    case SUCCESS_VERIFYRMAIL:
      const verifyEmailData = action.data;
      return { ...state, verifyEmailData };
    case ERROR_VERIFYRMAIL:
      const errorVerifyEmailData = action.data;
      return { ...state, verifyEmailData: errorVerifyEmailData };
    case SUCCESS_MANAGESTAFFACCESS:
      const manageStaffAccessData = action.data;
      return { ...state, manageStaffAccessData };
    case ERROR_MANAGESTAFFACCESS:
      const errorManageStaffAccessData = action.data;
      return { ...state, manageStaffAccessData: errorManageStaffAccessData };
    case SUCCESS_EMAILVERIFICATION:
      const emailVerificationData = action.data;
      return { ...state, emailVerificationData };
    case ERROR_EMAILVERIFICATION:
      const errorEmailVerificationData = action.data;
      return { ...state, emailVerificationData: errorEmailVerificationData };

    case SUCCESS_LINKEXPIREATION:
      const linkexpireData = action.data;
      return { ...state, linkexpireData };
    case ERROR_LINKEXPIREATION:
      const errorlinkexpireData = action.data;
      return { ...state, linkexpireData: errorlinkexpireData };
      case SUCCESS_USERVERIFYRMAIL:
        const verifyUserData = action.data;
        return { ...state, verifyUserData };
      case ERROR_USERVERIFYRMAIL:
        const errorVerifyUserData = action.data;
        return { ...state, verifyUserData: errorVerifyUserData };

        case SUCCESS_OTPVERIFICATION:
      const otpVerificationData = action.data;
      return { ...state, otpVerificationData };
    case ERROR_OTPVERIFICATION:
      const errorOTPVerificationData = action.data;
      return { ...state, otpVerificationData: errorOTPVerificationData };

      case SUCCESS_OTPRESEND:
      const otpResendData = action.data;
      return { ...state, otpResendData };
    case ERROR_OTPRESEND:
      const errorOTPResendData = action.data;
      return { ...state, otpResendData: errorOTPResendData };
    default:
      return state;
  }
};
