import {
  SUCCESS_LIST_MANAGE_STAFF,
  ERROR_LIST_MANAGE_STAFF,
  SUCCESS_STAFF_UPDATE_DETAILS,
  ERROR_STAFF_UPDATE_DETAILS,
  ERROR_SEND_USER_CREDENTIALS,
  DELETE_USER,
  ERROR_DELETE_USER,
  SUCCESS_DELETE_USER,
  SUCCESS_NEW_USER_MANAGESTAFF,
  ERROR_NEW_USER_MANAGESTAFF,
  ERROR_RESET_PASSWORD_MANAGESTAFF,
  SUCCESS_RESET_PASSWORD_MANAGESTAFF,
  SUCCESS_SEND_USER_CREDENTIALS,
  SUCCESS_CHECK_USER_EMAIL,
  ERROR_CHECK_USER_EMAIL,
  SUCCESS_STAFF_SET_PERMISSION,
  ERROR_STAFF_SET_PERMISSION,
  SUCCESS_STAFF_GET_PERMISSION,
  ERROR_STAFF_GET_PERMISSION,
  SUCCESS_STAFF_PERMISSION_MODULE,
  ERROR_STAFF_PERMISSION_MODULE,
  SUCCESS_MANAGE_STAFF_GET_PERMISSION,
  ERROR_MANAGE_STAFF_GET_PERMISSION,
  SUCCESS_STAFF_RESTORE,
  ERROR_STAFF_RESTORE,
  SUCCESS_STAFF_UPDATE_RFP_ALERT,
  ERROR_STAFF_UPDATE_RFP_ALERT,
  SUCCESS_EDIT_USER_MANAGESTAFF,
  ERROR_EDIT_USER_MANAGESTAFF,
} from "./manage.action";
import { DEFAULT_STATE } from "./manage.state";

export const manageStaffReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_LIST_MANAGE_STAFF:
      const manageStaffData = action.data;
      return { ...state, manageStaffData };
    case ERROR_LIST_MANAGE_STAFF:
      const errorManageStaffData = action.data;
      return { ...state, manageStaffData: errorManageStaffData };

    case SUCCESS_STAFF_UPDATE_DETAILS:
      const staffUpdateDetailsData = action.data;
      return { ...state, staffUpdateDetailsData };
    case ERROR_STAFF_UPDATE_DETAILS:
      const errorStaffUpdateDetailsData = action.data;
      return { ...state, staffUpdateDetailsData: errorStaffUpdateDetailsData };

    case SUCCESS_SEND_USER_CREDENTIALS:
      const sendUserCredentialsData = action.data;
      return { ...state, sendUserCredentialsData };
    case ERROR_SEND_USER_CREDENTIALS:
      const errorSendUserCredentialsData = action.data;
      return {
        ...state,
        sendUserCredentialsData: errorSendUserCredentialsData,
      };

    case DELETE_USER:
      const deleteUserData = action.data;
      return { ...state, deleteUserData };
    case SUCCESS_DELETE_USER:
      const successDeleteUserData = action.data;
      return { ...state, deleteUserData: successDeleteUserData };
    case ERROR_DELETE_USER:
      const errorDeleteUserData = action.data;
      return { ...state, deleteUserData: errorDeleteUserData };

    case SUCCESS_NEW_USER_MANAGESTAFF:
      const newUserManageStaffData = action.data;
      return { ...state, newUserManageStaffData };
    case ERROR_NEW_USER_MANAGESTAFF:
      const errorNewUserManageStaffData = action.data;
      return { ...state, newUserManageStaffData: errorNewUserManageStaffData };
    case SUCCESS_RESET_PASSWORD_MANAGESTAFF:
      const resetPasswordManageStaffData = action.data;
      return { ...state, resetPasswordManageStaffData };
    case ERROR_RESET_PASSWORD_MANAGESTAFF:
      const errorResetPasswordManageStaffData = action.data;
      return {
        ...state,
        resetPasswordManageStaffData: errorResetPasswordManageStaffData,
      };
    case SUCCESS_CHECK_USER_EMAIL:
      const checkUserEmailData = action.data;
      return { ...state, checkUserEmailData };
    case ERROR_CHECK_USER_EMAIL:
      const errorCheckUserEmailData = action.data;
      return { ...state, checkUserEmailData: errorCheckUserEmailData };

    case SUCCESS_STAFF_SET_PERMISSION:
      const staffsetpermissionData = action.data;
      return { ...state, staffsetpermissionData };
    case ERROR_STAFF_SET_PERMISSION:
      const errorstaffsetpermissionData = action.data;
      return { ...state, staffsetpermissionData: errorstaffsetpermissionData };

    case SUCCESS_STAFF_GET_PERMISSION:
      const staffgetpermissionData = action.data;
      return { ...state, staffgetpermissionData };
    case ERROR_STAFF_GET_PERMISSION:
      const errorstaffgetpermissionData = action.data;
      return { ...state, staffgetpermissionData: errorstaffgetpermissionData };

    case SUCCESS_STAFF_PERMISSION_MODULE:
      const staffpermissionmoduleData = action.data;
      return { ...state, staffpermissionmoduleData };
    case ERROR_STAFF_PERMISSION_MODULE:
      const errorstaffpermissionmoduleData = action.data;
      return {
        ...state,
        staffpermissionmoduleData: errorstaffpermissionmoduleData,
      };

      case SUCCESS_MANAGE_STAFF_GET_PERMISSION:
        const manageStaffGetPermissionData = action.data;
        return { ...state, manageStaffGetPermissionData };
      case ERROR_MANAGE_STAFF_GET_PERMISSION:
        const errormanageStaffGetPermissionData = action.data;
        return { ...state, manageStaffGetPermissionData: errormanageStaffGetPermissionData };
      
        case SUCCESS_STAFF_RESTORE:
          const staffRestoreData = action.data;
          return { ...state, staffRestoreData };
        case ERROR_STAFF_RESTORE:
          const errorstaffRestoreData = action.data;
          return { ...state, staffRestoreData: errorstaffRestoreData };
        
      case SUCCESS_STAFF_UPDATE_RFP_ALERT:
          const staffRfpAlertUpdateData = action.data;
          return { ...state, staffRfpAlertUpdateData };
      case ERROR_STAFF_UPDATE_RFP_ALERT:
          const errorstaffRfpAlertUpdateData = action.data;
          return { ...state, staffRfpAlertUpdateData: errorstaffRfpAlertUpdateData };

          case SUCCESS_EDIT_USER_MANAGESTAFF:
            const editUserManageStaffData = action.data;
            return { ...state, editUserManageStaffData };
          case ERROR_EDIT_USER_MANAGESTAFF:
            const errorEditUserManageStaffData = action.data;
            return { ...state, editUserManageStaffData: errorEditUserManageStaffData };  
            
    default:
      return state;
  }
};
