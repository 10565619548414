import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import _ from "lodash";
import { Link } from "react-router-dom";
import { message } from "antd";
import "antd/dist/antd.css";
import { fieldValidator, usePrevious } from "../../../common/custom";
import { LOGIN, DASHBOARD, EMAIL_VERIFICATION, FORGOTPASSWORD, POSTLOADDASHBOARD ,MOBILE_VERIFICATION} from "../../../routing/routeContants";
import { forgotPassword, login, resetPassword, verifyEmail, emailVerification,verifyUser,otpVerification,otpResend } from "../../../redux/auth/auth.action";
import { constants } from "../../../common/constants";
import { validateInputs } from "../../../common/Validation";
import loader1 from "../../../assets/images/loader1.svg";
import closeIcon from "../../../assets/images/closeIcon.svg";
import { Tokens, User } from "../../../storage";
//To set the message configration like how many times message should display
message.config({
  maxCount: 1,
});
export {
    _, message, useDispatch, useSelector, useHistory, LOGIN, Link, constants, validateInputs,
    fieldValidator, usePrevious, loader1,closeIcon,
    forgotPassword,
    login, DASHBOARD, Tokens, User, EMAIL_VERIFICATION, FORGOTPASSWORD, POSTLOADDASHBOARD,
    resetPassword,
    verifyEmail, emailVerification,verifyUser,MOBILE_VERIFICATION,otpVerification,otpResend
}
