import { combineReducers } from 'redux';
import { authReducer } from '../redux/auth/auth.reducer';
import { manageStaffReducer } from '../redux/managestaff/manage.reducer';
import { settingsReducer } from '../redux/settings/settings.reducer';
import { manageEmailsReducer } from '../redux/manageEmails/manageEmails.reducer';
import { helpReducer } from '../redux/help/help.reducer';
import { dashboardReducer } from '../redux/dashboard/dashboard.reducer';
import { graphsReducer } from '../redux/dashboardGraphs/graphs.reducer';
import { notificationsReducer } from '../redux/notifications/notification.reducers';
import { metricsReducer } from '../redux/metrics/metrics.reducer';
import { reloadcarrierReducer } from '../redux/reloadcarrier/reloadcarrier.reducer';
import { insertcarrierdataReducer } from '../redux/insertcarrierdata/insertcarrierdata.reducer';
import { CarrierPortalReducer } from '../redux/carrierPortal/carrierPortal.reducer';
import { manageAccessReducer } from '../redux/manageAccess/manageAccess.reducer';

import { postLoadReducer } from '../redux/postLoad/postLoad.reducers';
import { rfpReducer } from '../redux/rfp/rfp.reducer';
export const appReducer = combineReducers({
  auth: authReducer,
  managestaff: manageStaffReducer,
  settings: settingsReducer,
  manageemails: manageEmailsReducer,
  help: helpReducer,
  dashboard: dashboardReducer,
  dashboardGraphs: graphsReducer,
  notifications: notificationsReducer,
  metrics: metricsReducer,
  reloadcarrier: reloadcarrierReducer,
  insertcarrierdata: insertcarrierdataReducer,
  carrierPortal: CarrierPortalReducer,
  postloaddata: postLoadReducer,
  rfp: rfpReducer,
  manageAccess:manageAccessReducer
});
export const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
