export const constants = {
  RIGHT_INPUT: "success",
  WRONG_INPUT: "error",
};

export const selectStyle = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "",
    "&:active": {
      backgroundColor: "#30CDCC",
      color: "#fff",
    },
    backgroundColor: state.isSelected
      ? "#30CDCC"
      : state.isFocused
      ? "#DEEBFF"
      : "",
  }),
  input: (provided) => ({
    ...provided,
    color: "#817F7F",
    height: "100%",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 10,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
    padding: "0 16px",
    height: "35px",
  }),
};
