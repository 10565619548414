import { request } from "../request/axios.request";
import {
      API_USERS_ROLES_ALL_LIST,
      API_USERS_ROLES_LIST,
      API_USER_ROLE_ADD,
      API_USER_ROLE_EDIT,
      API_USER_ROLE_DELETE,
      API_USER_ROLE_GET,
      API_USER_ROLE_ACCESS_MANAGEMENT,
      API_USER_ROLE_ACCESS_MANAGEMENT_SINGLE,
      API_USER_ROLE_ACCESS_MANAGEMENT_LIST,
      API_USER_ROLE_ACCESS_MANAGEMENT_VERIFY
} from "../routing/route";

//USER Role LIST ALL
export async function usersRolesListAll(data) {
  let usersRolesListData = data.data;
  return request({
    url: API_USERS_ROLES_ALL_LIST,
    method: "get",
    data: usersRolesListData,
  });
}

//USER Role LIST 
export async function usersRolesList(data) {
  let usersRolesListData = data.data;
   //console.log(usersRolesListData);
  return request({
    url: API_USERS_ROLES_LIST,
    method: "get",
    params: usersRolesListData,
  });
}

//Add USER Role  
export async function usersRoleAdd(data) {
  let usersRoleAdd = data.data;
  return request({
    url: API_USER_ROLE_ADD,
    method: "post",
    data: usersRoleAdd,
  });
}

//Edit USER Role  
export async function usersRoleEdit(data) {
  let usersRoleEdit = data.data;
  return request({
    url: API_USER_ROLE_EDIT,
    method: "post",
    data: usersRoleEdit,
  });
}

//Delete USER Role  
export async function usersRoleDelete(data) {
  let usersRoleDelete = data.data;
  return request({
    url: API_USER_ROLE_DELETE,
    method: "post",
    data: usersRoleDelete,
  });
}

//Get USER Role  
export async function usersRoleGet(data) {
  let usersRoleGet = data.data;
  return request({
    url: API_USER_ROLE_GET,
    method: "post",
    data: usersRoleGet,
  });
}

//access-management USER Role  batch
export async function usersRoleAccessManagementBatch(data) {
  let usersRoleAccessManagement = data.data;
  //console.log(usersRoleAccessManagement);
  return request({
    url: API_USER_ROLE_ACCESS_MANAGEMENT,
    method: "post",
    data: usersRoleAccessManagement,
  });
}

//access-management USER Role single  
export async function usersRoleAccessManagementSingle(data) {
  let usersRoleAccessManagement = data;
  return request({
    url: API_USER_ROLE_ACCESS_MANAGEMENT_SINGLE,
    method: "post",
    data: usersRoleAccessManagement,
  });
}

//USER Role LIST 
export async function usersRoleAccessManagementList(data) {
  let accessData = data.data;
  return request({
    url: API_USER_ROLE_ACCESS_MANAGEMENT_LIST,
    method: "get",
    params: accessData,
  });
}

//USER Role Verify 
export async function usersRoleAccessManagementVerify(data) {
  let accessData = data.data;
  return request({
    url: API_USER_ROLE_ACCESS_MANAGEMENT_VERIFY,
    method: "post",
    data: accessData,
  });
}
