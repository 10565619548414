import React, {  } from "react";
import {Modal, fieldValidator } from "../index";
export const AddNewRole = ({
  idState,
  isAddModalVisible,
  setIsAddModalVisible,
  addNewRoleState,
  setAddNewRoleState,
  roleName,
  HandleAddRoleSubmit,
  disableField,
  setAddRole,
  setAdvanceSettingModalShow,
  keyPressDownEvent
}) => {
  // Check Validation Function
  const checkValidation = (field, value, type, maxLength, minLength) => {
    return fieldValidator(field, value, type, maxLength, minLength);
  };
  // Set The Login Input Values
  const setInputValue = (e, type, maxLength, minLength) => {
    let error = checkValidation(
      e.target.name,
      e.target.value.trim(),
      type,
      maxLength,
      minLength
    );
    const pattern = /^[a-zA-Z0-9_\- ]+$/;
    if(!pattern.test( e.target.value.trim())||e.target.value.trim()===''){
      error={ 
            errorMsg: "Only the special characters (space), _ (underscore), and - (hyphen) are allowed.",
            fieldCls: "roleNameCls",
            fieldNameErr: "roleNameErr",
            getError: true,
            setClassName: "error"
          }
    }
    setAddNewRoleState({
      ...addNewRoleState,
      [e.target.name]: e.target.value,
      [error.fieldNameErr]: error.errorMsg,
      [error.fieldCls]: error.setClassName,
    });
    // onSearchResult(e.target.value)
  };

  const closeModal = () => {
    setIsAddModalVisible(false)
    setAddRole(false)
    setAdvanceSettingModalShow(true)
  }
  const edit_model_text = !disableField ? ('Edit Role'):('View Role') 
  
  return (
    <Modal
      width={600}
      maskClosable={false}
      keyboard={false}
      title={idState ? edit_model_text : "Add New Role"}
      open={isAddModalVisible}
      onCancel={closeModal}
      destroyOnClose={true}
    >
      <form className="app_form" onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <div className="form-group">
            <label>
              Role Name<span className="text-danger">*</span>
            </label>

            <input
              disabled={disableField}
              className="form-control"
              type="text"
              placeholder="Enter Role Name"
              name="roleName"
              value={addNewRoleState.roleName}
              onChange={(e) => {
                setInputValue(e, "text", 20, 3);
              }}
             onKeyDown={(e) => keyPressDownEvent(e)}
            />
            {addNewRoleState.roleNameErr ? (
              <span className="text-danger mt-1">
                {addNewRoleState.roleNameErr}
              </span>
            ) : (
              ""
            )}
            {roleName ? (
              <span className="text-danger mt-1"> {roleName}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group">
            <div className="btn-group d-flex w-100 position-relative">
              <button
                type="button"
                onClick={closeModal}
                className="btn-default modal-action modal-close btn-default btn-open"
              >
                { !disableField ? ('Cancel'):('close')}
              </button>
              { !disableField ? (
                <button
                  type="button"
                  className="btn-default btn-primary"
                  onClick={HandleAddRoleSubmit}
                >
                  <span>{idState ? "Update" : "Save"}</span>
                </button>
               ):("")
              }
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
