import {
  SUCCESS_CUSTOM_NOTIFICATION_LIST,
  ERROR_CUSTOM_NOTIFICATION_LIST,
  //  SUCCESS_LATEST_NOTIFICATION_LIST, ERROR_LATEST_NOTIFICATION_LIST
} from "./notification.actions";
import { DEFAULT_STATE } from "./notification.state";

export const notificationsReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_CUSTOM_NOTIFICATION_LIST:
      const customNotificationData = action.data;
      return { ...state, customNotificationData };
    case ERROR_CUSTOM_NOTIFICATION_LIST:
      const errorCustomNotificationData = action.data;
      return { ...state, customNotificationData: errorCustomNotificationData };

    // case SUCCESS_LATEST_NOTIFICATION_LIST:
    //     const latestNotificationData = action.data;
    //     return { ...state, latestNotificationData }
    // case ERROR_LATEST_NOTIFICATION_LIST:
    //     const errorLatestNotificationData = action.data;
    //     return { ...state, latestNotificationData: errorLatestNotificationData }

    default:
      return state;
  }
};
