export const DEFAULT_STATE = {
  insertcarrierdataData: {},
  csvimportinsertcarrierData: {},
  csvimportinvalidcarrierData: {},
  csvimportequipmenttypeData: {},
  mcdotlistgetData: {},
  importinvalidcarrierorigindestinationData: {},
  importvalidlanesData: {},
  insertvalidlanesdata: {},
  insertReplacementEmailData: {},
  originSearchListData: {},
  destinationSearchListData: {},
  insertEquipmentData: {},
  insertDataSourceData: {},
  EquipmentDataInsertCarrierData : {}
};
