import {
  SUCCESS_CSV_IMPORT_INVALID_DATA_CARRIER,
  ERROR_CSV_IMPORT_INVALID_DATA_CARRIER,
  ERROR_CSV_IMPORT_INSERT_CARRIER,
  ERROR_INSERT_CARRIER_DATA,
  SUCCESS_CSV_IMPORT_INSERT_CARRIER,
  SUCCESS_INSERT_CARRIER_DATA,
  SUCCESS_CSV_IMPORT_EQUIPMENT_TYPE,
  ERROR_CSV_IMPORT_EQUIPMENT_TYPE,
  SUCCESS_GET_MC_DOT_LIST,
  ERROR_GET_MC_DOT_LIST,
  SUCCESS_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
  ERROR_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
  SUCCESS_IMPORT_VALID_LANES,
  ERROR_IMPORT_VALID_LANES,
  SUCCESS_IMPORT_VALID_LANES_DATA,
  ERROR_IMPORT_VALID_LANES_DATA,
  SUCCESS_ORIGINS_LIST_CARRIER_LANES,
  ERROR_ORIGINS_LIST_CARRIER_LANES,
  SUCCESS_DESTINATION_LIST_CARRIER_LANES,
  ERROR_DESTINATION_LIST_CARRIER_LANES,
  SUCCESS_IMPORT_REPLACEMENT_EMAIL_DATA,
  ERROR_IMPORT_REPLACEMENT_EMAIL_DATA,
  SUCCESS_INSERT_EQUIPMENT_TYPE_LIST,
  ERROR_INSERT_EQUIPMENT_TYPE_LIST,
  SUCCESS_INSERT_DATASOURCE_LIST,
  ERROR_INSERT_DATASOURCE_LIST,
  SUCCESS_EQUIPMENT_LIST_INSERT_CARRIER_DATA,
  ERROR_EQUIPMENT_LIST_INSERT_CARRIER_DATA,
} from "./insertcarrierdata.action";
import { DEFAULT_STATE } from "./insertcarrierdata.state";
export const insertcarrierdataReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_INSERT_CARRIER_DATA:
      const insertcarrierdataData = action.data;
      return { ...state, insertcarrierdataData };
    case ERROR_INSERT_CARRIER_DATA:
      const errorinsertcarrierdataData = action.data;
      return { ...state, insertcarrierdataData: errorinsertcarrierdataData };

    case SUCCESS_CSV_IMPORT_INSERT_CARRIER:
      const csvimportinsertcarrierData = action.data;
      return { ...state, csvimportinsertcarrierData };
    case ERROR_CSV_IMPORT_INSERT_CARRIER:
      const errorcsvimportinsertcarrierData = action.data;
      return {
        ...state,
        csvimportinsertcarrierData: errorcsvimportinsertcarrierData,
      };

    case SUCCESS_CSV_IMPORT_INVALID_DATA_CARRIER:
      const csvimportinvalidcarrierData = action.data;
      return { ...state, csvimportinvalidcarrierData };
    case ERROR_CSV_IMPORT_INVALID_DATA_CARRIER:
      const errorcsvimportinvalidcarrierData = action.data;
      return {
        ...state,
        csvimportinvalidcarrierData: errorcsvimportinvalidcarrierData,
      };

    case SUCCESS_CSV_IMPORT_EQUIPMENT_TYPE:
      const csvimportequipmenttypeData = action.data;
      return { ...state, csvimportequipmenttypeData };
    case ERROR_CSV_IMPORT_EQUIPMENT_TYPE:
      const errorcsvimportequipmenttypeData = action.data;
      return {
        ...state,
        csvimportequipmenttypeData: errorcsvimportequipmenttypeData,
      };

    case SUCCESS_GET_MC_DOT_LIST:
      const mcdotlistgetData = action.data;
      return { ...state, mcdotlistgetData };
    case ERROR_GET_MC_DOT_LIST:
      const errormcdotlistgetData = action.data;
      return { ...state, mcdotlistgetData: errormcdotlistgetData };

    case SUCCESS_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION:
      const importinvalidcarrierorigindestinationData = action.data;
      return { ...state, importinvalidcarrierorigindestinationData };
    case ERROR_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION:
      const errorimportinvalidcarrierorigindestinationData = action.data;
      return {
        ...state,
        importinvalidcarrierorigindestinationData:
          errorimportinvalidcarrierorigindestinationData,
      };

    case SUCCESS_IMPORT_VALID_LANES:
      const importvalidlanesData = action.data;
      return { ...state, importvalidlanesData };
    case ERROR_IMPORT_VALID_LANES:
      const errorimportvalidlanesData = action.data;
      return { ...state, importvalidlanesData: errorimportvalidlanesData };

    case SUCCESS_IMPORT_VALID_LANES_DATA:
      const insertvalidlanesdata = action.data;
      return { ...state, insertvalidlanesdata };
    case ERROR_IMPORT_VALID_LANES_DATA:
      const errorinsertvalidlanesdata = action.data;
      return { ...state, insertvalidlanesdata: errorinsertvalidlanesdata };

    case SUCCESS_IMPORT_REPLACEMENT_EMAIL_DATA:
      const insertReplacementEmailData = action.data;
      return { ...state, insertReplacementEmailData };
    case ERROR_IMPORT_REPLACEMENT_EMAIL_DATA:
      const errorInsertReplacementEmailData = action.data;
      return {
        ...state,
        insertReplacementEmailData: errorInsertReplacementEmailData,
      };

    case SUCCESS_ORIGINS_LIST_CARRIER_LANES:
      const originSearchList = action.data;
      return { ...state, originSearchList };
    case ERROR_ORIGINS_LIST_CARRIER_LANES:
      const errorOriginSearchList = action.data;
      return { ...state, originSearchList: errorOriginSearchList };

    case SUCCESS_DESTINATION_LIST_CARRIER_LANES:
      const destinationSearchList = action.data;
      return { ...state, destinationSearchList };
    case ERROR_DESTINATION_LIST_CARRIER_LANES:
      const errorDestinationSearchList = action.data;
      return { ...state, destinationSearchList: errorDestinationSearchList };

    case SUCCESS_INSERT_EQUIPMENT_TYPE_LIST:
      let insertEquipmentData = action.data
      return { ...state, insertEquipmentData }
    case ERROR_INSERT_EQUIPMENT_TYPE_LIST:
      let errorInsertEquipmentData = action.data
      return { ...state, insertEquipmentData: errorInsertEquipmentData }

    case SUCCESS_INSERT_DATASOURCE_LIST:
      let insertDataSourceData = action.data
      return { ...state, insertDataSourceData }
    case ERROR_INSERT_DATASOURCE_LIST:
      let errorInsertDataSourceData = action.data
      return { ...state, insertDataSourceData: errorInsertDataSourceData }

      case SUCCESS_EQUIPMENT_LIST_INSERT_CARRIER_DATA:
        let EquipmentDataInsertCarrierData = action.data;
        return { ...state, EquipmentDataInsertCarrierData };
      case ERROR_EQUIPMENT_LIST_INSERT_CARRIER_DATA:
        let errorEquipmentDataInsertCarrierData = action.data;
        return {
          ...state,
          EquipmentDataInsertCarrierData: errorEquipmentDataInsertCarrierData,
        };

    default:
      return state;
  }
};
