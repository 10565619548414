export const DEFAULT_STATE = {
  listUsersRolesData:{},
  listUsersRolesAllData:{},
  addUsersRoleData:{},
  editUsersRoleData:{},
  deleteUsersRoleData:{},
  usersRoleAccessManagementVerifyData:{},
  usersRoleAccessManagementListData:{},
  usersRoleAccessManagementBatchData:{},
  usersRoleAccessManagementSingleData:{},
};
