// GET INSERT_CARRIER_DATA
export const INSERT_CARRIER_DATA = "INSERT_CARRIER_DATA";
export const insertcarrierdataList = (data) => ({
  type: INSERT_CARRIER_DATA,
  data,
});
export const SUCCESS_INSERT_CARRIER_DATA = "SUCCESS_INSERT_CARRIER_DATA";
export const ERROR_INSERT_CARRIER_DATA = "ERROR_INSERT_CARRIER_DATA";
export const insertcarrierdataListResponse = (type, data) => ({ type, data });

// GET CSV_IMPORT_INSERT_CARRIER
export const CSV_IMPORT_INSERT_CARRIER = "CSV_IMPORT_INSERT_CARRIER";
export const csvimportinsertcarrierList = (data) => ({
  type: CSV_IMPORT_INSERT_CARRIER,
  data,
});
export const SUCCESS_CSV_IMPORT_INSERT_CARRIER =
  "SUCCESS_CSV_IMPORT_INSERT_CARRIER";
export const ERROR_CSV_IMPORT_INSERT_CARRIER =
  "ERROR_CSV_IMPORT_INSERT_CARRIER";
export const csvimportinsertcarrierListResponse = (type, data) => ({
  type,
  data,
});

// GET CSV_IMPORT_INVALID_DATA_CARRIER
export const CSV_IMPORT_INVALID_DATA_CARRIER =
  "CSV_IMPORT_INVALID_DATA_CARRIER";
export const csvinvalidemailcarrerdata = (data) => ({
  type: CSV_IMPORT_INVALID_DATA_CARRIER,
  data,
});
export const SUCCESS_CSV_IMPORT_INVALID_DATA_CARRIER =
  "SUCCESS_CSV_IMPORT_INVALID_DATA_CARRIER";
export const ERROR_CSV_IMPORT_INVALID_DATA_CARRIER =
  "ERROR_CSV_IMPORT_INVALID_DATA_CARRIER";
export const csvinvalidemailcarrerdataListResponse = (type, data) => ({
  type,
  data,
});

// POST CSV_IMPORT_EQUIPMENT_TYPE
export const CSV_IMPORT_EQUIPMENT_TYPE = "CSV_IMPORT_EQUIPMENT_TYPE";
export const csvimportequipmenttypedata = (data) => ({
  type: CSV_IMPORT_EQUIPMENT_TYPE,
  data,
});
export const SUCCESS_CSV_IMPORT_EQUIPMENT_TYPE =
  "SUCCESS_CSV_IMPORT_EQUIPMENT_TYPE";
export const ERROR_CSV_IMPORT_EQUIPMENT_TYPE =
  "ERROR_CSV_IMPORT_EQUIPMENT_TYPE";
export const csvimportequipmenttypedataListResponse = (type, data) => ({
  type,
  data,
});

// GET GET_MC_DOT_LIST
export const GET_MC_DOT_LIST = "GET_MC_DOT_LIST";
export const mcdotlistgetdata = (data) => ({ type: GET_MC_DOT_LIST, data });
export const SUCCESS_GET_MC_DOT_LIST = "SUCCESS_GET_MC_DOT_LIST";
export const ERROR_GET_MC_DOT_LIST = "ERROR_GET_MC_DOT_LIST";
export const mcdotlistgetdataListResponse = (type, data) => ({ type, data });

// GET IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION
export const IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION =
  "IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION";
export const importinvalidcarrierorigindestinationdata = (data) => ({
  type: IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION,
  data,
});
export const SUCCESS_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION =
  "SUCCESS_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION";
export const ERROR_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION =
  "ERROR_IMPORT_INVALID_CARRIER_ORIGIN_DESTINATION";
export const importinvalidcarrierorigindestinationdataListResponse = (
  type,
  data
) => ({ type, data });

// GET IMPORT_VALID_LANES
export const IMPORT_VALID_LANES = "IMPORT_VALID_LANES";
export const importvalidlanesdata = (data) => ({
  type: IMPORT_VALID_LANES,
  data,
});
export const SUCCESS_IMPORT_VALID_LANES = "SUCCESS_IMPORT_VALID_LANES";
export const ERROR_IMPORT_VALID_LANES = "ERROR_IMPORT_VALID_LANES";
export const importvalidlanesdataListResponse = (type, data) => ({
  type,
  data,
});

// GET IMPORT_VALID_LANES_DATA
export const IMPORT_VALID_LANES_DATA = "IMPORT_VALID_LANES_DATA";
export const insertvalidlanesdata = (data) => ({
  type: IMPORT_VALID_LANES_DATA,
  data,
});
export const SUCCESS_IMPORT_VALID_LANES_DATA =
  "SUCCESS_IMPORT_VALID_LANES_DATA";
export const ERROR_IMPORT_VALID_LANES_DATA = "ERROR_IMPORT_VALID_LANES_DATA";
export const insertvalidlanesdataListResponse = (type, data) => ({
  type,
  data,
});

// POST IMPORT REPLACEMENT EMAILS
export const IMPORT_REPLACEMENT_EMAIL_DATA = "IMPORT_REPLACEMENT_EMAIL_DATA";
export const insertReplacementEmailData = (data) => ({
  type: IMPORT_REPLACEMENT_EMAIL_DATA,
  data,
});
export const SUCCESS_IMPORT_REPLACEMENT_EMAIL_DATA =
  "SUCCESS_IMPORT_REPLACEMENT_EMAIL_DATA";
export const ERROR_IMPORT_REPLACEMENT_EMAIL_DATA =
  "ERROR_IMPORT_REPLACEMENT_EMAIL_DATA";
export const insertReplacementEmailDataResponse = (type, data) => ({
  type,
  data,
});

// GET_ORIGIN_LIST
export const ORIGINS_LIST_CARRIER_LANES = "ORIGINS_LIST_CARRIER_LANES";
export const originSearchListcaarierlanes = (data) => ({
  type: ORIGINS_LIST_CARRIER_LANES,
  data,
});
export const SUCCESS_ORIGINS_LIST_CARRIER_LANES =
  "SUCCESS_ORIGINS_LIST_CARRIER_LANES";
export const ERROR_ORIGINS_LIST_CARRIER_LANES =
  "ERROR_ORIGINS_LIST_CARRIER_LANES";
export const originSearchListResponse = (type, data) => ({ type, data });

// GET_DEST_LIST
export const DESTINATION_LIST_CARRIER_LANES = "DESTINATION_LIST_CARRIER_LANES";
export const destinationSearchListcarrierlanes = (data) => ({
  type: DESTINATION_LIST_CARRIER_LANES,
  data,
});
export const SUCCESS_DESTINATION_LIST_CARRIER_LANES =
  "SUCCESS_DESTINATION_LIST_CARRIER_LANES";
export const ERROR_DESTINATION_LIST_CARRIER_LANES =
  "ERROR_DESTINATION_LIST_CARRIER_LANES";
export const destinationSearchListResponse = (type, data) => ({ type, data });


// equipment type
export const INSERT_EQUIPMENT_TYPE_LIST = "INSERT_EQUIPMENT_TYPE_LIST"
export const insertEquipmentTypeList = (data) => ({ type: INSERT_EQUIPMENT_TYPE_LIST, data })
export const SUCCESS_INSERT_EQUIPMENT_TYPE_LIST = "SUCCESS_INSERT_EQUIPMENT_TYPE_LIST"
export const ERROR_INSERT_EQUIPMENT_TYPE_LIST = "ERROR_INSERT_EQUIPMENT_TYPE_LIST"
export const insertEquipmentTypeListResponse = (type, data) => ({ type, data })

// data source 
export const INSERT_DATASOURCE_LIST = "INSERT_DATASOURCE_LIST"
export const insertDataSourceList = (data) => ({ type: INSERT_DATASOURCE_LIST, data })
export const SUCCESS_INSERT_DATASOURCE_LIST = "SUCCESS_INSERT_DATASOURCE_LIST"
export const ERROR_INSERT_DATASOURCE_LIST = "ERROR_INSERT_DATASOURCE_LIST"
export const insertDataSourceListResponse = (type, data) => ({ type, data })

// insert carrier data  equipment type 
export const EQUIPMENT_LIST_INSERT_CARRIER_DATA = "EQUIPMENT_LIST_INSERT_CARRIER_DATA"
export const EquipmentListInsertCarrierData = (data) => ({ type: EQUIPMENT_LIST_INSERT_CARRIER_DATA, data })
export const SUCCESS_EQUIPMENT_LIST_INSERT_CARRIER_DATA = "SUCCESS_EQUIPMENT_LIST_INSERT_CARRIER_DATA"
export const ERROR_EQUIPMENT_LIST_INSERT_CARRIER_DATA = "ERROR_EQUIPMENT_LIST_INSERT_CARRIER_DATA"
export const EquipmentListInsertCarrierDataResponse = (type, data) => ({ type, data })