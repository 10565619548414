import {
  SUCCESS_COPY_SHORTCODE,
  ERROR_COPY_SHORTCODE,
  SUCCESS_ADD_NEW_TEMPLATE,
  ERROR_ADD_NEW_TEMPLATE,
  SUCCESS_TEMPLATE_LIST,
  ERROR_TEMPLATE_LIST,
  SUCCESS_EDIT_EMAIL_TEMPLATE,
  ERROR_EDIT_EMAIL_TEMPLATE,
  SUCCESS_DELETE_EMAIL_TEMPLATE,
  ERROR_DELETE_EMAIL_TEMPLATE,
  SUCCESS_SEND_EMAIL,
  ERROR_SEND_EMAIL,
  ERROR_CHECK_TEMPLATE_NAME,
  SUCCESS_CHECK_TEMPLATE_NAME,
  SUCCESS_MY_EMAILS_LIST,
  ERROR_MY_EMAILS_LIST,
  SUCCESS_EMAIL_STATS,
  ERROR_EMAIL_STATS,
  SUCCESS_FILE_SEND,
  ERROR_FILE_SEND,
  SUCCESS_MY_SUPPORT_EMAILS_LIST,
  ERROR_MY_SUPPORT_EMAILS_LIST,
  SUCCESS_MY_DOWNLOAD_EMAILS_LIST,
  ERROR_MY_DOWNLOAD_EMAILS_LIST,
  SUCCESS_MY_UNSUBSCRIBE_EMAILS_LIST,
  ERROR_MY_UNSUBSCRIBE_EMAILS_LIST,
  SUCCESS_DELETE_UNSUBSCRIBE_EMAILS,
  ERROR_DELETE_UNSUBSCRIBE_EMAILS
} from "./manageEmails.action";
import { DEFAULT_STATE } from "./manageEmails.state";

export const manageEmailsReducer = (
  state = DEFAULT_STATE,
  action = {
    type: {},
    data: {},
  }
) => {
  switch (action.type) {
    case SUCCESS_COPY_SHORTCODE:
      const copyhortCodeData = action.data;
      return { ...state, copyhortCodeData };
    case ERROR_COPY_SHORTCODE:
      const errorCopyShortCodeData = action.data;
      return { ...state, copyhortCodeData: errorCopyShortCodeData };
    case SUCCESS_ADD_NEW_TEMPLATE:
      const addNewTemplateData = action.data;
      return { ...state, addNewTemplateData };
    case ERROR_ADD_NEW_TEMPLATE:
      const erroraddNewTemplateData = action.data;
      return { ...state, addNewTemplateData: erroraddNewTemplateData };
    case SUCCESS_TEMPLATE_LIST:
      const templateListData = action.data;
      return { ...state, templateListData };
    case ERROR_TEMPLATE_LIST:
      const errorTemplateListDataData = action.data;
      return { ...state, templateListData: errorTemplateListDataData };
    case SUCCESS_EDIT_EMAIL_TEMPLATE:
      const editEmailTemplateData = action.data;
      return { ...state, editEmailTemplateData };
    case ERROR_EDIT_EMAIL_TEMPLATE:
      const errorEditEmailTemplateData = action.data;
      return { ...state, editEmailTemplateData: errorEditEmailTemplateData };
    case SUCCESS_DELETE_EMAIL_TEMPLATE:
      const deleteEmailTemplateData = action.data;
      return { ...state, deleteEmailTemplateData };
    case ERROR_DELETE_EMAIL_TEMPLATE:
      const errorDeleteEmailTemplateData = action.data;
      return {
        ...state,
        deleteEmailTemplateData: errorDeleteEmailTemplateData,
      };
    case SUCCESS_SEND_EMAIL:
      const sendEmailData = action.data;
      return { ...state, sendEmailData };
    case ERROR_SEND_EMAIL:
      const errorSendEmailData = action.data;
      return { ...state, sendEmailData: errorSendEmailData };
    case SUCCESS_CHECK_TEMPLATE_NAME:
      const checkTemplateNameData = action.data;
      return { ...state, checkTemplateNameData };
    case ERROR_CHECK_TEMPLATE_NAME:
      const errorCheckTemplateNameData = action.data;
      return { ...state, checkTemplateNameData: errorCheckTemplateNameData };
    case SUCCESS_MY_EMAILS_LIST:
      const myEmailsListData = action.data;
      return { ...state, myEmailsListData };
    case ERROR_MY_EMAILS_LIST:
      const errorMyEmailsListData = action.data;
      return { ...state, myEmailsListData: errorMyEmailsListData };
      case SUCCESS_MY_SUPPORT_EMAILS_LIST:
        const mySupportEmailsListData = action.data;
        return { ...state, mySupportEmailsListData };
      case ERROR_MY_SUPPORT_EMAILS_LIST:
        const errorMySupportEmailsListData = action.data;
        return { ...state, mySupportEmailsListData: errorMySupportEmailsListData };
    case SUCCESS_EMAIL_STATS:
      const emailStatsData = action.data;
      return { ...state, emailStatsData };
    case ERROR_EMAIL_STATS:
      const errorEmailStatsData = action.data;
      return { ...state, emailStatsData: errorEmailStatsData };
    case SUCCESS_FILE_SEND:
      const emailsFromSentFile = action.data;
      return { ...state, emailsFromSentFile };
    case ERROR_FILE_SEND:
      let erroremailsFromSentFile = action.data;
      return { ...state, emailsFromSentFile: erroremailsFromSentFile };
      case SUCCESS_MY_DOWNLOAD_EMAILS_LIST:
        const myDownloadEmailsListData = action.data;
        return { ...state, myDownloadEmailsListData };
      case ERROR_MY_DOWNLOAD_EMAILS_LIST:
        const errorMyDownloadEmailsListData = action.data;
        return { ...state, myDownloadEmailsListData: errorMyDownloadEmailsListData };
        case SUCCESS_MY_UNSUBSCRIBE_EMAILS_LIST:
        const myUnsubscribeEmailsListData = action.data;
        return { ...state, myUnsubscribeEmailsListData };
      case ERROR_MY_UNSUBSCRIBE_EMAILS_LIST:
        const errorMyUnsubscribeEmailsListData = action.data;
        return { ...state, myUnsubscribeEmailsListData: errorMyUnsubscribeEmailsListData };
        case SUCCESS_DELETE_UNSUBSCRIBE_EMAILS:
      const deleteUnsubscribeEmailsData = action.data;
      return { ...state, deleteUnsubscribeEmailsData };
    case ERROR_DELETE_UNSUBSCRIBE_EMAILS:
      const errorDeleteUnsubscribeEmailsData = action.data;
      return {
        ...state,
        deleteUnsubscribeEmailsData: errorDeleteUnsubscribeEmailsData,
      };
    default:
      return state;
  }
};
