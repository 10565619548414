import { request } from '../request/axios.request';
import {
    CARRIER_PORTAL_DESTINATION_LIST,
    CARRIER_PORTAL_ORIGIN_LIST,
    EQUIPMENT_TYPE_LIST,
    CARRIER_PORTAL_SEARCH
} from '../routing/route';

export async function carrierPortalOriginListApi(data) {
    let carrierPortalOriginListdata = data.data;
    return request({ url: CARRIER_PORTAL_ORIGIN_LIST, method: 'get', params: carrierPortalOriginListdata })
}

export async function carrierPortalDestinationListApi(data) {
    let carrierPortalDestinationListdata = data.data;
    return request({ url: CARRIER_PORTAL_DESTINATION_LIST, method: 'get', params: carrierPortalDestinationListdata })
}

export async function equipmentTypeListApi(data) {
    let equipmentTypeListApiData = data.data
    return request({ url: EQUIPMENT_TYPE_LIST, method: 'get', params: equipmentTypeListApiData })
}

export async function carrierPortalSearchApi(data) {
    let carrierPortalSearchApiData = data.data
    return request({ url:CARRIER_PORTAL_SEARCH, method : 'post', data : carrierPortalSearchApiData })
}