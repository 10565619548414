import React, { useState,useEffect } from "react";
import ManageRoleListTable from "./manageStaffAccessComponents/manageRoleListTable";
import ManageAccessTable from "./manageStaffAccessComponents/manageAccessTable";
import {
  Modal,
} from "./index";

export const AdvanceSetting = (props) => {
  const [updateListAccessManagement, setUpdateListAccessManagement] = useState(false);
  
  return (
    <Modal
      title="Advance Settings"
      width={1200}
      maskClosable={false}
      open={props.AdvanceSettingModalShow}
      onOk={() => props.setAdvanceSettingModalShow(false)}
      onCancel={() => props.setAdvanceSettingModalShow(false)}
      footer={null}
      keyboard={false}
    >
      <div className="modal-body p-0">
        <div className="row align-items-center">
          <ManageRoleListTable setLoader={props.setLoader}  
          setAdvanceSettingModalShow={props.setAdvanceSettingModalShow}
          listUsersRolesAll={props.listUsersRolesAll}
          setUpdateListAccessManagement={setUpdateListAccessManagement}
          />
        </div>
         <div className="row align-items-center mt-2">
           <ManageAccessTable setLoader={props.setLoader} 
           listUsersRolesAllList={props.listUsersRolesAllList }
           setAdvanceSettingModalShow={props.setAdvanceSettingModalShow}
           updateListAccessManagement={updateListAccessManagement}
           setUpdateListAccessManagement={setUpdateListAccessManagement}
           setManageStaffList= {props.setManageStaffList}
            setTotalRecords={props.setTotalRecords}
            setTotalPage={props.setTotalPage}
            setlimit={props.setlimit}
            setPage={props.setPage}
            setSearch={props.setSearch}
           />
        </div>
      </div>
    </Modal>
  );
};
