export const DEFAULT_STATE = {
    equipmentTypeList: {},
    addLoadDestinationListData: {},
    addLoadoriginListData: {},
    searchByorderIdData: {},
    addLoadStopListData: {},
    saveLoadDetailsData: {},
    allLoadListData: {},
    singleLoadDetailsData: {},
    deleteLoadData: {},
    updateLoadData: {}
}